.search-tooltip > a {
  width: 35px;
  height: 35px;
  text-align: center;
  background: #e62645;
  border-radius: 50%;
  margin: 12.5px 13px 12.5px -5px;
}

@font-face {
  font-family: Lato;
  src: url("lato-thin.bd35c6dd.woff2") format("woff2"), url("lato-thin.3fc450e5.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("lato-thinitalic.0f5caa10.woff2") format("woff2"), url("lato-thinitalic.5f58cf05.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("lato-light.6034286e.woff2") format("woff2"), url("lato-light.9bb4c03e.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("lato-lightitalic.b80adfa1.woff2") format("woff2"), url("lato-lightitalic.058ccb51.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("lato-regular.ac8d34ab.woff2") format("woff2"), url("lato-regular.21dd1035.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("lato-regularitalic.b80adfa1.woff2") format("woff2"), url("lato-regularitalic.058ccb51.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("lato-bold.e6b18013.woff2") format("woff2"), url("lato-bold.a38072ec.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("lato-bolditalic.b2690da7.woff2") format("woff2"), url("lato-bolditalic.5b7cdda6.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("lato-black.0ba1da96.woff2") format("woff2"), url("lato-black.b641a007.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("lato-blackitalic.29f6d332.woff2") format("woff2"), url("lato-blackitalic.732cec6e.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

h1 {
  font-weight: 700;
}

.login-container .g-recaptcha, .form-login .g-recaptcha {
  margin-bottom: 10px !important;
}

.required-captcha.checkbox {
  visibility: visible;
  opacity: 0;
  width: 1px;
  height: 1px;
  display: block;
  position: absolute;
  overflow: hidden;
}

.review-form .field-recaptcha {
  margin-bottom: 10px;
}

.form.send.friend .g-recaptcha {
  margin-top: 40px;
}

.am-visually-hidden {
  clip: rect(0, 0, 0, 0);
  width: 0;
  height: 0;
  opacity: 0;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.am-word-break {
  word-wrap: break-word;
  word-break: break-word;
}

.ie11 .am-word-break {
  word-break: break-all;
}

.opc-block-summary .vertex-message td, .cart-summary .vertex-message td {
  border-top: none;
  padding: 0;
}

.klarna-payments-method-cell {
  vertical-align: middle;
  display: table-cell;
}

label.klarna-payments-method-cell > span {
  padding-left: 5px;
}

span.klarna-payments-method-cell > img {
  display: block;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 780px) and (orientation: landscape) {
  .product-video {
    height: 100%;
    width: 81%;
  }
}

.file-uploader-area {
  position: relative;
}

.file-uploader-area input[type="file"] {
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  width: 0;
  position: absolute;
  overflow: hidden;
}

.file-uploader-area input[type="file"]:focus + .file-uploader-button {
  box-shadow: 0 0 0 1px #006bb4;
}

.file-uploader-area input[type="file"]:disabled + .file-uploader-button {
  cursor: default;
  opacity: .5;
  pointer-events: none;
}

.file-uploader-summary {
  vertical-align: top;
  display: inline-block;
}

.file-uploader-button {
  box-sizing: border-box;
  color: #333;
  cursor: pointer;
  vertical-align: middle;
  background: #eee;
  border: 1px solid #ccc;
  margin: 0;
  padding: 7px 15px;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  display: inline-block;
}

.file-uploader-button._is-dragover {
  background: #d4d4d4;
  border: 1px solid #006bb4;
}

.file-uploader-spinner {
  height: 30px;
  vertical-align: top;
  width: 15px;
  background-image: url("loader-1.fb337c01.gif");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 15px;
  margin-left: 10px;
  display: none;
}

.file-uploader-preview .action-remove {
  cursor: pointer;
  height: 27px;
  width: 25px;
  z-index: 2;
  padding: 2px;
  text-decoration: none;
  display: block;
  position: absolute;
  bottom: 4px;
  left: 6px;
}

.file-uploader-preview .action-remove > span {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.file-uploader-preview .action-remove:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  line-height: inherit;
  color: #514943;
  content: "";
  vertical-align: middle;
  speak: none;
  text-align: center;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  display: inline-block;
  overflow: hidden;
}

.file-uploader-preview .action-remove:hover:before {
  color: #736963;
}

.file-uploader-preview:hover .preview-image img, .file-uploader-preview:hover .preview-link:before {
  opacity: .7;
}

.file-uploader-preview .preview-link {
  height: 100%;
  display: block;
}

.file-uploader-preview .preview-image img {
  max-height: 100%;
  max-width: 100%;
  z-index: 1;
  margin: auto;
  position: absolute;
  inset: 0;
}

.file-uploader-preview .preview-video {
  text-decoration: none;
  display: inline-block;
}

.file-uploader-preview .preview-video:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 4rem;
  line-height: inherit;
  color: #ccc;
  content: "";
  vertical-align: middle;
  speak: none;
  text-align: center;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  display: inline-block;
  overflow: hidden;
}

.file-uploader-preview .preview-video:hover:before {
  color: #ccc;
}

.file-uploader-preview .preview-video:before {
  z-index: 2;
  margin-top: -2rem;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.file-uploader-preview .preview-document {
  text-decoration: none;
  display: inline-block;
}

.file-uploader-preview .preview-document:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 7rem;
  line-height: inherit;
  color: #ccc;
  content: "";
  vertical-align: middle;
  speak: none;
  text-align: center;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  display: inline-block;
  overflow: hidden;
}

.file-uploader-preview .preview-document:hover:before {
  color: #ccc;
}

.file-uploader-preview .preview-document:before {
  z-index: 2;
  margin-top: -3.5rem;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.file-uploader-preview, .file-uploader-placeholder {
  box-sizing: border-box;
  cursor: pointer;
  height: 150px;
  width: 150px;
  background: #fff;
  border: 1px solid #cacaca;
  margin: 10px 25px 10px 0;
  line-height: 1;
  display: block;
  position: relative;
  overflow: hidden;
}

.file-uploader._loading .file-uploader-spinner {
  display: inline-block;
}

.file-uploader .admin__field-note, .file-uploader .admin__field-error {
  margin-bottom: 10px;
}

.file-uploader .file-uploader-filename {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  word-break: break-all;
  overflow: hidden;
}

.file-uploader .file-uploader-filename:first-child {
  margin-bottom: 10px;
}

.file-uploader .file-uploader-meta {
  color: #9e9e9e;
}

.file-uploader .admin__field-fallback-reset {
  margin-left: 10px;
}

._keyfocus .file-uploader .action-remove:focus {
  box-shadow: 0 0 0 1px #006bb4;
}

.file-uploader-placeholder.placeholder-document {
  text-decoration: none;
  display: inline-block;
}

.file-uploader-placeholder.placeholder-document:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 5rem;
  line-height: inherit;
  color: #ccc;
  content: "";
  vertical-align: middle;
  speak: none;
  text-align: center;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  display: inline-block;
  overflow: hidden;
}

.file-uploader-placeholder.placeholder-document:hover:before {
  color: #ccc;
}

.file-uploader-placeholder.placeholder-document:before {
  z-index: 2;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
}

.file-uploader-placeholder.placeholder-image {
  text-decoration: none;
  display: inline-block;
}

.file-uploader-placeholder.placeholder-image:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 5rem;
  line-height: inherit;
  color: #ccc;
  content: "";
  vertical-align: middle;
  speak: none;
  text-align: center;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  display: inline-block;
  overflow: hidden;
}

.file-uploader-placeholder.placeholder-image:hover:before {
  color: #ccc;
}

.file-uploader-placeholder.placeholder-image:before {
  z-index: 2;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
}

.file-uploader-placeholder.placeholder-video {
  text-decoration: none;
  display: inline-block;
}

.file-uploader-placeholder.placeholder-video:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 3rem;
  line-height: inherit;
  color: #ccc;
  content: "";
  vertical-align: middle;
  speak: none;
  text-align: center;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  display: inline-block;
  overflow: hidden;
}

.file-uploader-placeholder.placeholder-video:hover:before {
  color: #ccc;
}

.file-uploader-placeholder.placeholder-video:before {
  z-index: 2;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.file-uploader-placeholder-text {
  color: #008bdb;
  text-align: center;
  margin-bottom: 15%;
  padding: 0 20px;
  font-size: 1.1rem;
  line-height: 1.42857;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.data-grid-file-uploader {
  min-width: 7rem;
}

.data-grid-file-uploader._loading .file-uploader-spinner {
  display: block;
}

.data-grid-file-uploader._loading .file-uploader-button:before {
  display: none;
}

.data-grid-file-uploader .file-uploader-image {
  max-height: 100%;
  max-width: 100%;
  z-index: 1;
  background: none;
  margin: auto;
  position: absolute;
  inset: 0;
}

.data-grid-file-uploader .file-uploader-image + .file-uploader-area .file-uploader-button:before {
  display: none;
}

.data-grid-file-uploader .file-uploader-area {
  z-index: 2;
}

.data-grid-file-uploader .file-uploader-spinner {
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
}

.data-grid-file-uploader .file-uploader-button {
  height: 48px;
  text-align: center;
  text-decoration: none;
  display: block;
}

.data-grid-file-uploader .file-uploader-button > span {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.data-grid-file-uploader .file-uploader-button:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #8a837f;
  content: "";
  vertical-align: middle;
  speak: none;
  text-align: center;
  font-family: "Font Awesome 5 Pro";
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 48px;
  display: inline-block;
  overflow: hidden;
}

.data-grid-file-uploader .file-uploader-button:hover:before {
  color: #666;
}

.data-grid-file-uploader .action-select-wrap {
  float: left;
}

.data-grid-file-uploader .action-select-wrap .action-select {
  height: 5rem;
  width: 2rem;
  border: 1px solid #cacaca;
  margin-left: -1px;
  padding: 0;
  display: block;
}

.data-grid-file-uploader .action-select-wrap .action-select:after {
  border-color: #8a837f #0000 #0000;
  margin: 0 0 0 -5px;
  left: 50%;
}

.data-grid-file-uploader .action-select-wrap .action-select:hover:after {
  border-color: #666 #0000 #0000;
}

.data-grid-file-uploader .action-select-wrap .action-select > span {
  display: none;
}

.data-grid-file-uploader .action-select-wrap .action-menu {
  z-index: 2;
  left: 4rem;
  right: auto;
}

.data-grid-file-uploader-inner {
  float: left;
  height: 5rem;
  width: 5rem;
  border: 1px solid #cacaca;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .field-tooltip .field-tooltip-content {
    top: 40px;
    left: auto;
    right: -10px;
  }

  .field-tooltip .field-tooltip-content:before, .field-tooltip .field-tooltip-content:after {
    height: 0;
    width: 0;
    border: 10px solid #0000;
    margin-top: -21px;
    top: 0;
    left: auto;
    right: 10px;
  }

  .field-tooltip .field-tooltip-content:before {
    border-bottom-color: #999;
  }

  .field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px;
  }
}

.jstree-node, .jstree-children, .jstree-container-ul {
  margin: 0;
  padding: 0;
  list-style-image: none;
  list-style-type: none;
  display: block;
}

.jstree-node {
  white-space: nowrap;
}

.jstree-anchor {
  color: #000;
  white-space: nowrap;
  vertical-align: top;
  margin: 0;
  padding: 0 4px 0 1px;
  display: inline-block;
}

.jstree-anchor:focus {
  outline: 0;
}

.jstree-anchor, .jstree-anchor:link, .jstree-anchor:visited, .jstree-anchor:hover, .jstree-anchor:active {
  color: inherit;
  text-decoration: none;
}

.jstree-icon, .jstree-icon:empty {
  vertical-align: top;
  text-align: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: inline-block;
}

.jstree-ocl {
  cursor: pointer;
}

.jstree-leaf > .jstree-ocl {
  cursor: default;
}

.jstree .jstree-open > .jstree-children {
  display: block;
}

.jstree .jstree-closed > .jstree-children, .jstree .jstree-leaf > .jstree-children {
  display: none;
}

.jstree-anchor > .jstree-themeicon {
  margin-right: 2px;
}

.jstree-no-icons .jstree-themeicon, .jstree-anchor > .jstree-themeicon-hidden, .jstree-hidden, .jstree-node.jstree-hidden {
  display: none;
}

.jstree-rtl .jstree-anchor {
  padding: 0 1px 0 4px;
}

.jstree-rtl .jstree-anchor > .jstree-themeicon {
  margin-left: 2px;
  margin-right: 0;
}

.jstree-rtl .jstree-node {
  margin-left: 0;
}

.jstree-rtl .jstree-container-ul > .jstree-node {
  margin-right: 0;
}

.jstree-wholerow-ul {
  min-width: 100%;
  display: inline-block;
  position: relative;
}

.jstree-wholerow-ul .jstree-leaf > .jstree-ocl {
  cursor: pointer;
}

.jstree-wholerow-ul .jstree-anchor, .jstree-wholerow-ul .jstree-icon {
  position: relative;
}

.jstree-wholerow-ul .jstree-wholerow {
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  left: 0;
}

.jstree-contextmenu .jstree-anchor {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.vakata-context {
  display: none;
}

.vakata-context, .vakata-context ul {
  background: #f5f5f5;
  border: 1px solid #979797;
  margin: 0;
  padding: 2px;
  position: absolute;
  box-shadow: 2px 2px 2px #999;
}

.vakata-context ul {
  margin-top: -2.7em;
  margin-left: -4px;
  list-style: none;
  left: 100%;
}

.vakata-context .vakata-context-right ul {
  margin-left: auto;
  margin-right: -4px;
  left: auto;
  right: 100%;
}

.vakata-context li {
  list-style: none;
}

.vakata-context li > a {
  width: auto;
  color: #000;
  white-space: nowrap;
  text-shadow: 1px 1px #fff;
  border-radius: 1px;
  padding: 0 2em;
  line-height: 2.4em;
  text-decoration: none;
  display: block;
}

.vakata-context li > a:hover {
  background-color: #e8eff7;
  position: relative;
  box-shadow: 0 0 2px #0a6aa1;
}

.vakata-context li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==");
  background-position: 100%;
  background-repeat: no-repeat;
}

.vakata-context li > a:focus {
  outline: 0;
}

.vakata-context .vakata-context-hover > a {
  background-color: #e8eff7;
  position: relative;
  box-shadow: 0 0 2px #0a6aa1;
}

.vakata-context .vakata-context-separator > a, .vakata-context .vakata-context-separator > a:hover {
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  text-shadow: 0 0 #0000;
  background: #fff;
  border: 0;
  border-top: 1px solid #e2e3e3;
  border-left: 1px solid #e0e0e0;
  border-radius: 0;
  margin: 0 0 0 2.4em;
  padding: 0;
  box-shadow: 0 0 #0000;
}

.vakata-context .vakata-contextmenu-disabled a, .vakata-context .vakata-contextmenu-disabled a:hover {
  color: silver;
  background-color: #0000;
  border: 0;
  box-shadow: 0 0;
}

.vakata-context .vakata-contextmenu-disabled > a > i {
  filter: grayscale();
}

.vakata-context li > a > i {
  width: 2.4em;
  height: 2.4em;
  vertical-align: top;
  text-align: center;
  background: none;
  margin: 0 0 0 -2em;
  line-height: 2.4em;
  text-decoration: none;
  display: inline-block;
}

.vakata-context li > a > i:empty {
  width: 2.4em;
  line-height: 2.4em;
}

.vakata-context li > a .vakata-contextmenu-sep {
  width: 1px;
  height: 2.4em;
  background: #fff;
  border-left: 1px solid #e2e3e3;
  margin: 0 .5em 0 0;
  display: inline-block;
}

.vakata-context .vakata-contextmenu-shortcut {
  color: silver;
  opacity: .5;
  font-size: .8em;
  display: none;
}

.vakata-context-rtl ul {
  margin-left: auto;
  margin-right: -4px;
  left: auto;
  right: 100%;
}

.vakata-context-rtl li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7");
  background-position: 0;
  background-repeat: no-repeat;
}

.vakata-context-rtl .vakata-context-separator > a {
  border-left: 0;
  border-right: 1px solid #e2e3e3;
  margin: 0 2.4em 0 0;
}

.vakata-context-rtl .vakata-context-left ul {
  margin-left: -4px;
  margin-right: auto;
  left: 100%;
  right: auto;
}

.vakata-context-rtl li > a > i {
  margin: 0 -2em 0 0;
}

.vakata-context-rtl li > a .vakata-contextmenu-sep {
  background: #e2e3e3;
  border-left-color: #fff;
  margin: 0 0 0 .5em;
}

#jstree-marker {
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin: -5px 0 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

#jstree-dnd {
  margin: 0;
  padding: 4px;
  line-height: 16px;
}

#jstree-dnd .jstree-icon, #jstree-dnd .jstree-copy {
  width: 16px;
  height: 16px;
  margin: 0 2px 0 0;
  padding: 0;
  text-decoration: none;
  display: inline-block;
}

#jstree-dnd .jstree-ok {
  background: green;
}

#jstree-dnd .jstree-er {
  background: red;
}

#jstree-dnd .jstree-copy {
  margin: 0 2px;
}

.jstree-default .jstree-node, .jstree-default .jstree-icon {
  background-color: #0000;
  background-repeat: no-repeat;
}

.jstree-default .jstree-anchor, .jstree-default .jstree-animated, .jstree-default .jstree-wholerow {
  transition: background-color .15s, box-shadow .15s;
}

.jstree-default .jstree-hovered, .jstree-default .jstree-context {
  background: #e7f4f9;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #ccc;
}

.jstree-default .jstree-clicked {
  background: #beebff;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #999;
}

.jstree-default .jstree-no-icons .jstree-anchor > .jstree-themeicon {
  display: none;
}

.jstree-default .jstree-disabled {
  color: #666;
  background: none;
}

.jstree-default .jstree-disabled.jstree-hovered {
  box-shadow: none;
  background: none;
}

.jstree-default .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default .jstree-disabled > .jstree-icon {
  opacity: .8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  filter: gray;
  -webkit-filter: grayscale();
}

.jstree-default .jstree-search {
  color: #8b0000;
  font-style: italic;
  font-weight: bold;
}

.jstree-default .jstree-no-checkboxes .jstree-checkbox {
  display: none !important;
}

.jstree-default.jstree-checkbox-no-clicked .jstree-clicked {
  box-shadow: none;
  background: none;
}

.jstree-default.jstree-checkbox-no-clicked .jstree-clicked.jstree-hovered {
  background: #e7f4f9;
}

.jstree-default.jstree-checkbox-no-clicked > .jstree-wholerow-ul .jstree-wholerow-clicked {
  background: none;
}

.jstree-default.jstree-checkbox-no-clicked > .jstree-wholerow-ul .jstree-wholerow-clicked.jstree-wholerow-hovered {
  background: #e7f4f9;
}

.jstree-default > .jstree-striped {
  min-width: 100%;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==") 0 0;
  display: inline-block;
}

.jstree-default > .jstree-wholerow-ul .jstree-hovered, .jstree-default > .jstree-wholerow-ul .jstree-clicked {
  box-shadow: none;
  background: none;
  border-radius: 0;
}

.jstree-default .jstree-wholerow {
  box-sizing: border-box;
}

.jstree-default .jstree-wholerow-hovered {
  background: #e7f4f9;
}

.jstree-default .jstree-wholerow-clicked {
  background: linear-gradient(#beebff 0%, #a8e4ff 100%);
}

.jstree-default .jstree-node {
  min-height: 24px;
  min-width: 24px;
  margin-left: 24px;
  line-height: 24px;
}

.jstree-default .jstree-anchor {
  height: 24px;
  line-height: 24px;
}

.jstree-default .jstree-icon, .jstree-default .jstree-icon:empty {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.jstree-default.jstree-rtl .jstree-node {
  margin-right: 24px;
}

.jstree-default .jstree-wholerow {
  height: 24px;
}

.jstree-default .jstree-node, .jstree-default .jstree-icon {
  background-image: url("32px.7fec035e.png");
}

.jstree-default .jstree-node {
  background-position: -292px -4px;
  background-repeat: repeat-y;
}

.jstree-default .jstree-last {
  background: none;
}

.jstree-default .jstree-open > .jstree-ocl {
  background-position: -132px -4px;
}

.jstree-default .jstree-closed > .jstree-ocl {
  background-position: -100px -4px;
}

.jstree-default .jstree-leaf > .jstree-ocl {
  background-position: -68px -4px;
}

.jstree-default .jstree-themeicon {
  background-position: -260px -4px;
}

.jstree-default > .jstree-no-dots .jstree-node, .jstree-default > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -36px -4px;
}

.jstree-default > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -4px -4px;
}

.jstree-default .jstree-disabled, .jstree-default .jstree-disabled.jstree-hovered {
  background: none;
}

.jstree-default .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default .jstree-checkbox {
  background-position: -164px -4px;
}

.jstree-default .jstree-checkbox:hover {
  background-position: -164px -36px;
}

.jstree-default.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox, .jstree-default .jstree-checked > .jstree-checkbox {
  background-position: -228px -4px;
}

.jstree-default.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover, .jstree-default .jstree-checked > .jstree-checkbox:hover {
  background-position: -228px -36px;
}

.jstree-default .jstree-anchor > .jstree-undetermined {
  background-position: -196px -4px;
}

.jstree-default .jstree-anchor > .jstree-undetermined:hover {
  background-position: -196px -36px;
}

.jstree-default .jstree-checkbox-disabled {
  opacity: .8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  filter: gray;
  -webkit-filter: grayscale();
}

.jstree-default > .jstree-striped {
  background-size: auto 48px;
}

.jstree-default.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -132px -36px;
}

.jstree-default.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -100px -36px;
}

.jstree-default.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -68px -36px;
}

.jstree-default.jstree-rtl > .jstree-no-dots .jstree-node, .jstree-default.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -36px -36px;
}

.jstree-default.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -4px -36px;
}

.jstree-default .jstree-themeicon-custom {
  background-color: #0000;
  background-image: none;
  background-position: 0 0;
}

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("throbber.ff68bdb0.gif") center no-repeat;
}

.jstree-default .jstree-file {
  background: url("32px.7fec035e.png") -100px -68px no-repeat;
}

.jstree-default .jstree-folder {
  background: url("32px.7fec035e.png") -260px -4px no-repeat;
}

.jstree-default > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

#jstree-dnd.jstree-default {
  padding: 0 4px;
  line-height: 24px;
}

#jstree-dnd.jstree-default .jstree-ok, #jstree-dnd.jstree-default .jstree-er {
  background-color: #0000;
  background-image: url("32px.7fec035e.png");
  background-repeat: no-repeat;
}

#jstree-dnd.jstree-default i {
  width: 24px;
  height: 24px;
  background: none;
  line-height: 24px;
}

#jstree-dnd.jstree-default .jstree-ok {
  background-position: -4px -68px;
}

#jstree-dnd.jstree-default .jstree-er {
  background-position: -36px -68px;
}

.jstree-default .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default .jstree-ellipsis .jstree-anchor {
  width: 71%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
}

.jstree-default.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default-small .jstree-node {
  min-height: 18px;
  min-width: 18px;
  margin-left: 18px;
  line-height: 18px;
}

.jstree-default-small .jstree-anchor {
  height: 18px;
  line-height: 18px;
}

.jstree-default-small .jstree-icon, .jstree-default-small .jstree-icon:empty {
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.jstree-default-small.jstree-rtl .jstree-node {
  margin-right: 18px;
}

.jstree-default-small .jstree-wholerow {
  height: 18px;
}

.jstree-default-small .jstree-node, .jstree-default-small .jstree-icon {
  background-image: url("32px.7fec035e.png");
}

.jstree-default-small .jstree-node {
  background-position: -295px -7px;
  background-repeat: repeat-y;
}

.jstree-default-small .jstree-last {
  background: none;
}

.jstree-default-small .jstree-open > .jstree-ocl {
  background-position: -135px -7px;
}

.jstree-default-small .jstree-closed > .jstree-ocl {
  background-position: -103px -7px;
}

.jstree-default-small .jstree-leaf > .jstree-ocl {
  background-position: -71px -7px;
}

.jstree-default-small .jstree-themeicon {
  background-position: -263px -7px;
}

.jstree-default-small > .jstree-no-dots .jstree-node, .jstree-default-small > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default-small > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -39px -7px;
}

.jstree-default-small > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -7px -7px;
}

.jstree-default-small .jstree-disabled, .jstree-default-small .jstree-disabled.jstree-hovered {
  background: none;
}

.jstree-default-small .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default-small .jstree-checkbox {
  background-position: -167px -7px;
}

.jstree-default-small .jstree-checkbox:hover {
  background-position: -167px -39px;
}

.jstree-default-small.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox, .jstree-default-small .jstree-checked > .jstree-checkbox {
  background-position: -231px -7px;
}

.jstree-default-small.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover, .jstree-default-small .jstree-checked > .jstree-checkbox:hover {
  background-position: -231px -39px;
}

.jstree-default-small .jstree-anchor > .jstree-undetermined {
  background-position: -199px -7px;
}

.jstree-default-small .jstree-anchor > .jstree-undetermined:hover {
  background-position: -199px -39px;
}

.jstree-default-small .jstree-checkbox-disabled {
  opacity: .8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  filter: gray;
  -webkit-filter: grayscale();
}

.jstree-default-small > .jstree-striped {
  background-size: auto 36px;
}

.jstree-default-small.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default-small.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default-small.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -135px -39px;
}

.jstree-default-small.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -103px -39px;
}

.jstree-default-small.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -71px -39px;
}

.jstree-default-small.jstree-rtl > .jstree-no-dots .jstree-node, .jstree-default-small.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default-small.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -39px -39px;
}

.jstree-default-small.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -7px -39px;
}

.jstree-default-small .jstree-themeicon-custom {
  background-color: #0000;
  background-image: none;
  background-position: 0 0;
}

.jstree-default-small > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("throbber.ff68bdb0.gif") center no-repeat;
}

.jstree-default-small .jstree-file {
  background: url("32px.7fec035e.png") -103px -71px no-repeat;
}

.jstree-default-small .jstree-folder {
  background: url("32px.7fec035e.png") -263px -7px no-repeat;
}

.jstree-default-small > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

#jstree-dnd.jstree-default-small {
  padding: 0 4px;
  line-height: 18px;
}

#jstree-dnd.jstree-default-small .jstree-ok, #jstree-dnd.jstree-default-small .jstree-er {
  background-color: #0000;
  background-image: url("32px.7fec035e.png");
  background-repeat: no-repeat;
}

#jstree-dnd.jstree-default-small i {
  width: 18px;
  height: 18px;
  background: none;
  line-height: 18px;
}

#jstree-dnd.jstree-default-small .jstree-ok {
  background-position: -7px -71px;
}

#jstree-dnd.jstree-default-small .jstree-er {
  background-position: -39px -71px;
}

.jstree-default-small .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default-small .jstree-ellipsis .jstree-anchor {
  width: 77%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default-small.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==");
}

.jstree-default-small.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default-large .jstree-node {
  min-height: 32px;
  min-width: 32px;
  margin-left: 32px;
  line-height: 32px;
}

.jstree-default-large .jstree-anchor {
  height: 32px;
  line-height: 32px;
}

.jstree-default-large .jstree-icon, .jstree-default-large .jstree-icon:empty {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.jstree-default-large.jstree-rtl .jstree-node {
  margin-right: 32px;
}

.jstree-default-large .jstree-wholerow {
  height: 32px;
}

.jstree-default-large .jstree-node, .jstree-default-large .jstree-icon {
  background-image: url("32px.7fec035e.png");
}

.jstree-default-large .jstree-node {
  background-position: -288px 0;
  background-repeat: repeat-y;
}

.jstree-default-large .jstree-last {
  background: none;
}

.jstree-default-large .jstree-open > .jstree-ocl {
  background-position: -128px 0;
}

.jstree-default-large .jstree-closed > .jstree-ocl {
  background-position: -96px 0;
}

.jstree-default-large .jstree-leaf > .jstree-ocl {
  background-position: -64px 0;
}

.jstree-default-large .jstree-themeicon {
  background-position: -256px 0;
}

.jstree-default-large > .jstree-no-dots .jstree-node, .jstree-default-large > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default-large > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -32px 0;
}

.jstree-default-large > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: 0 0;
}

.jstree-default-large .jstree-disabled, .jstree-default-large .jstree-disabled.jstree-hovered {
  background: none;
}

.jstree-default-large .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default-large .jstree-checkbox {
  background-position: -160px 0;
}

.jstree-default-large .jstree-checkbox:hover {
  background-position: -160px -32px;
}

.jstree-default-large.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox, .jstree-default-large .jstree-checked > .jstree-checkbox {
  background-position: -224px 0;
}

.jstree-default-large.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover, .jstree-default-large .jstree-checked > .jstree-checkbox:hover {
  background-position: -224px -32px;
}

.jstree-default-large .jstree-anchor > .jstree-undetermined {
  background-position: -192px 0;
}

.jstree-default-large .jstree-anchor > .jstree-undetermined:hover {
  background-position: -192px -32px;
}

.jstree-default-large .jstree-checkbox-disabled {
  opacity: .8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  filter: gray;
  -webkit-filter: grayscale();
}

.jstree-default-large > .jstree-striped {
  background-size: auto 64px;
}

.jstree-default-large.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default-large.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default-large.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -128px -32px;
}

.jstree-default-large.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -96px -32px;
}

.jstree-default-large.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -64px -32px;
}

.jstree-default-large.jstree-rtl > .jstree-no-dots .jstree-node, .jstree-default-large.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default-large.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -32px -32px;
}

.jstree-default-large.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: 0 -32px;
}

.jstree-default-large .jstree-themeicon-custom {
  background-color: #0000;
  background-image: none;
  background-position: 0 0;
}

.jstree-default-large > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("throbber.ff68bdb0.gif") center no-repeat;
}

.jstree-default-large .jstree-file {
  background: url("32px.7fec035e.png") -96px -64px no-repeat;
}

.jstree-default-large .jstree-folder {
  background: url("32px.7fec035e.png") -256px 0 no-repeat;
}

.jstree-default-large > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

#jstree-dnd.jstree-default-large {
  padding: 0 4px;
  line-height: 32px;
}

#jstree-dnd.jstree-default-large .jstree-ok, #jstree-dnd.jstree-default-large .jstree-er {
  background-color: #0000;
  background-image: url("32px.7fec035e.png");
  background-repeat: no-repeat;
}

#jstree-dnd.jstree-default-large i {
  width: 32px;
  height: 32px;
  background: none;
  line-height: 32px;
}

#jstree-dnd.jstree-default-large .jstree-ok {
  background-position: 0 -64px;
}

#jstree-dnd.jstree-default-large .jstree-er {
  background-position: -32px -64px;
}

.jstree-default-large .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default-large .jstree-ellipsis .jstree-anchor {
  width: 63%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default-large.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==");
}

.jstree-default-large.jstree-rtl .jstree-last {
  background: none;
}

@media (max-width: 768px) {
  #jstree-dnd.jstree-dnd-responsive {
    text-shadow: 1px 1px #fff;
    font-size: 1.1em;
    font-weight: bold;
    line-height: 40px;
  }

  #jstree-dnd.jstree-dnd-responsive > i {
    width: 40px;
    height: 40px;
    background: none;
  }

  #jstree-dnd.jstree-dnd-responsive > .jstree-ok {
    background-image: url("40px.987061b6.png");
    background-position: 0 -200px;
    background-size: 120px 240px;
  }

  #jstree-dnd.jstree-dnd-responsive > .jstree-er {
    background-image: url("40px.987061b6.png");
    background-position: -40px -200px;
    background-size: 120px 240px;
  }

  #jstree-marker.jstree-dnd-responsive {
    border-top-width: 10px;
    border-bottom-width: 10px;
    border-left-width: 10px;
    margin-top: -10px;
  }

  .jstree-default-responsive .jstree-icon {
    background-image: url("40px.987061b6.png");
  }

  .jstree-default-responsive .jstree-node, .jstree-default-responsive .jstree-leaf > .jstree-ocl {
    background: none;
  }

  .jstree-default-responsive .jstree-node {
    min-height: 40px;
    min-width: 40px;
    white-space: nowrap;
    margin-left: 40px;
    line-height: 40px;
  }

  .jstree-default-responsive .jstree-anchor {
    height: 40px;
    line-height: 40px;
  }

  .jstree-default-responsive .jstree-icon, .jstree-default-responsive .jstree-icon:empty {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .jstree-default-responsive > .jstree-container-ul > .jstree-node {
    margin-left: 0;
  }

  .jstree-default-responsive.jstree-rtl .jstree-node {
    background: none;
    margin-left: 0;
    margin-right: 40px;
  }

  .jstree-default-responsive.jstree-rtl .jstree-container-ul > .jstree-node {
    margin-right: 0;
  }

  .jstree-default-responsive .jstree-ocl, .jstree-default-responsive .jstree-themeicon, .jstree-default-responsive .jstree-checkbox {
    background-size: 120px 240px;
  }

  .jstree-default-responsive .jstree-leaf > .jstree-ocl, .jstree-default-responsive.jstree-rtl .jstree-leaf > .jstree-ocl {
    background: none;
  }

  .jstree-default-responsive .jstree-open > .jstree-ocl {
    background-position: 0 0 !important;
  }

  .jstree-default-responsive .jstree-closed > .jstree-ocl {
    background-position: 0 -40px !important;
  }

  .jstree-default-responsive.jstree-rtl .jstree-closed > .jstree-ocl {
    background-position: -40px 0 !important;
  }

  .jstree-default-responsive .jstree-themeicon {
    background-position: -40px -40px;
  }

  .jstree-default-responsive .jstree-checkbox, .jstree-default-responsive .jstree-checkbox:hover {
    background-position: -40px -80px;
  }

  .jstree-default-responsive.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox, .jstree-default-responsive.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover, .jstree-default-responsive .jstree-checked > .jstree-checkbox, .jstree-default-responsive .jstree-checked > .jstree-checkbox:hover {
    background-position: 0 -80px;
  }

  .jstree-default-responsive .jstree-anchor > .jstree-undetermined, .jstree-default-responsive .jstree-anchor > .jstree-undetermined:hover {
    background-position: 0 -120px;
  }

  .jstree-default-responsive .jstree-anchor {
    text-shadow: 1px 1px #fff;
    font-size: 1.1em;
    font-weight: bold;
  }

  .jstree-default-responsive > .jstree-striped {
    background: none;
  }

  .jstree-default-responsive .jstree-wholerow {
    height: 40px;
    background: #ebebeb;
    border-top: 1px solid #ffffffb3;
    border-bottom: 1px solid #40404033;
  }

  .jstree-default-responsive .jstree-wholerow-hovered {
    background: #e7f4f9;
  }

  .jstree-default-responsive .jstree-wholerow-clicked {
    background: #beebff;
  }

  .jstree-default-responsive .jstree-children .jstree-last > .jstree-wholerow {
    box-shadow: inset 0 -6px 3px -5px #666;
  }

  .jstree-default-responsive .jstree-children .jstree-open > .jstree-wholerow {
    border-top: 0;
    box-shadow: inset 0 6px 3px -5px #666;
  }

  .jstree-default-responsive .jstree-children .jstree-open + .jstree-open {
    box-shadow: none;
  }

  .jstree-default-responsive .jstree-node, .jstree-default-responsive .jstree-icon, .jstree-default-responsive .jstree-node > .jstree-ocl, .jstree-default-responsive .jstree-themeicon, .jstree-default-responsive .jstree-checkbox {
    background-image: url("40px.987061b6.png");
    background-size: 120px 240px;
  }

  .jstree-default-responsive .jstree-node {
    background-position: -80px 0;
    background-repeat: repeat-y;
  }

  .jstree-default-responsive .jstree-last {
    background: none;
  }

  .jstree-default-responsive .jstree-leaf > .jstree-ocl {
    background-position: -40px -120px;
  }

  .jstree-default-responsive .jstree-last > .jstree-ocl {
    background-position: -40px -160px;
  }

  .jstree-default-responsive .jstree-themeicon-custom {
    background-color: #0000;
    background-image: none;
    background-position: 0 0;
  }

  .jstree-default-responsive .jstree-file {
    background: url("40px.987061b6.png") 0 -160px / 120px 240px no-repeat;
  }

  .jstree-default-responsive .jstree-folder {
    background: url("40px.987061b6.png") -40px -40px / 120px 240px no-repeat;
  }

  .jstree-default-responsive > .jstree-container-ul > .jstree-node {
    margin-left: 0;
    margin-right: 0;
  }
}

.jstree-node, .jstree-children, .jstree-container-ul {
  margin: 0;
  padding: 0;
  list-style-image: none;
  list-style-type: none;
  display: block;
}

.jstree-node {
  white-space: nowrap;
}

.jstree-anchor {
  color: #000;
  white-space: nowrap;
  vertical-align: top;
  margin: 0;
  padding: 0 4px 0 1px;
  display: inline-block;
}

.jstree-anchor:focus {
  outline: 0;
}

.jstree-anchor, .jstree-anchor:link, .jstree-anchor:visited, .jstree-anchor:hover, .jstree-anchor:active {
  color: inherit;
  text-decoration: none;
}

.jstree-icon, .jstree-icon:empty {
  vertical-align: top;
  text-align: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: inline-block;
}

.jstree-ocl {
  cursor: pointer;
}

.jstree-leaf > .jstree-ocl {
  cursor: default;
}

.jstree .jstree-open > .jstree-children {
  display: block;
}

.jstree .jstree-closed > .jstree-children, .jstree .jstree-leaf > .jstree-children {
  display: none;
}

.jstree-anchor > .jstree-themeicon {
  margin-right: 2px;
}

.jstree-no-icons .jstree-themeicon, .jstree-anchor > .jstree-themeicon-hidden, .jstree-hidden, .jstree-node.jstree-hidden {
  display: none;
}

.jstree-rtl .jstree-anchor {
  padding: 0 1px 0 4px;
}

.jstree-rtl .jstree-anchor > .jstree-themeicon {
  margin-left: 2px;
  margin-right: 0;
}

.jstree-rtl .jstree-node {
  margin-left: 0;
}

.jstree-rtl .jstree-container-ul > .jstree-node {
  margin-right: 0;
}

.jstree-wholerow-ul {
  min-width: 100%;
  display: inline-block;
  position: relative;
}

.jstree-wholerow-ul .jstree-leaf > .jstree-ocl {
  cursor: pointer;
}

.jstree-wholerow-ul .jstree-anchor, .jstree-wholerow-ul .jstree-icon {
  position: relative;
}

.jstree-wholerow-ul .jstree-wholerow {
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  left: 0;
}

.jstree-contextmenu .jstree-anchor {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.vakata-context {
  display: none;
}

.vakata-context, .vakata-context ul {
  background: #f5f5f5;
  border: 1px solid #979797;
  margin: 0;
  padding: 2px;
  position: absolute;
  box-shadow: 2px 2px 2px #999;
}

.vakata-context ul {
  margin-top: -2.7em;
  margin-left: -4px;
  list-style: none;
  left: 100%;
}

.vakata-context .vakata-context-right ul {
  margin-left: auto;
  margin-right: -4px;
  left: auto;
  right: 100%;
}

.vakata-context li {
  list-style: none;
}

.vakata-context li > a {
  width: auto;
  color: #000;
  white-space: nowrap;
  text-shadow: 1px 1px #fff;
  border-radius: 1px;
  padding: 0 2em;
  line-height: 2.4em;
  text-decoration: none;
  display: block;
}

.vakata-context li > a:hover {
  background-color: #e8eff7;
  position: relative;
  box-shadow: 0 0 2px #0a6aa1;
}

.vakata-context li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==");
  background-position: 100%;
  background-repeat: no-repeat;
}

.vakata-context li > a:focus {
  outline: 0;
}

.vakata-context .vakata-context-hover > a {
  background-color: #e8eff7;
  position: relative;
  box-shadow: 0 0 2px #0a6aa1;
}

.vakata-context .vakata-context-separator > a, .vakata-context .vakata-context-separator > a:hover {
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  text-shadow: 0 0 #0000;
  background: #fff;
  border: 0;
  border-top: 1px solid #e2e3e3;
  border-left: 1px solid #e0e0e0;
  border-radius: 0;
  margin: 0 0 0 2.4em;
  padding: 0;
  box-shadow: 0 0 #0000;
}

.vakata-context .vakata-contextmenu-disabled a, .vakata-context .vakata-contextmenu-disabled a:hover {
  color: silver;
  background-color: #0000;
  border: 0;
  box-shadow: 0 0;
}

.vakata-context .vakata-contextmenu-disabled > a > i {
  filter: grayscale();
}

.vakata-context li > a > i {
  width: 2.4em;
  height: 2.4em;
  vertical-align: top;
  text-align: center;
  background: none;
  margin: 0 0 0 -2em;
  line-height: 2.4em;
  text-decoration: none;
  display: inline-block;
}

.vakata-context li > a > i:empty {
  width: 2.4em;
  line-height: 2.4em;
}

.vakata-context li > a .vakata-contextmenu-sep {
  width: 1px;
  height: 2.4em;
  background: #fff;
  border-left: 1px solid #e2e3e3;
  margin: 0 .5em 0 0;
  display: inline-block;
}

.vakata-context .vakata-contextmenu-shortcut {
  color: silver;
  opacity: .5;
  font-size: .8em;
  display: none;
}

.vakata-context-rtl ul {
  margin-left: auto;
  margin-right: -4px;
  left: auto;
  right: 100%;
}

.vakata-context-rtl li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7");
  background-position: 0;
  background-repeat: no-repeat;
}

.vakata-context-rtl .vakata-context-separator > a {
  border-left: 0;
  border-right: 1px solid #e2e3e3;
  margin: 0 2.4em 0 0;
}

.vakata-context-rtl .vakata-context-left ul {
  margin-left: -4px;
  margin-right: auto;
  left: 100%;
  right: auto;
}

.vakata-context-rtl li > a > i {
  margin: 0 -2em 0 0;
}

.vakata-context-rtl li > a .vakata-contextmenu-sep {
  background: #e2e3e3;
  border-left-color: #fff;
  margin: 0 0 0 .5em;
}

#jstree-marker {
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin: -5px 0 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

#jstree-dnd {
  margin: 0;
  padding: 4px;
  line-height: 16px;
}

#jstree-dnd .jstree-icon, #jstree-dnd .jstree-copy {
  width: 16px;
  height: 16px;
  margin: 0 2px 0 0;
  padding: 0;
  text-decoration: none;
  display: inline-block;
}

#jstree-dnd .jstree-ok {
  background: green;
}

#jstree-dnd .jstree-er {
  background: red;
}

#jstree-dnd .jstree-copy {
  margin: 0 2px;
}

.jstree-default-dark .jstree-node, .jstree-default-dark .jstree-icon {
  background-color: #0000;
  background-repeat: no-repeat;
}

.jstree-default-dark .jstree-anchor, .jstree-default-dark .jstree-animated, .jstree-default-dark .jstree-wholerow {
  transition: background-color .15s, box-shadow .15s;
}

.jstree-default-dark .jstree-hovered, .jstree-default-dark .jstree-context {
  background: #555;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #555;
}

.jstree-default-dark .jstree-clicked {
  background: #5fa2db;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #666;
}

.jstree-default-dark .jstree-no-icons .jstree-anchor > .jstree-themeicon {
  display: none;
}

.jstree-default-dark .jstree-disabled {
  color: #666;
  background: none;
}

.jstree-default-dark .jstree-disabled.jstree-hovered {
  box-shadow: none;
  background: none;
}

.jstree-default-dark .jstree-disabled.jstree-clicked {
  background: #333;
}

.jstree-default-dark .jstree-disabled > .jstree-icon {
  opacity: .8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  filter: gray;
  -webkit-filter: grayscale();
}

.jstree-default-dark .jstree-search {
  color: #fff;
  font-style: italic;
  font-weight: bold;
}

.jstree-default-dark .jstree-no-checkboxes .jstree-checkbox {
  display: none !important;
}

.jstree-default-dark.jstree-checkbox-no-clicked .jstree-clicked {
  box-shadow: none;
  background: none;
}

.jstree-default-dark.jstree-checkbox-no-clicked .jstree-clicked.jstree-hovered {
  background: #555;
}

.jstree-default-dark.jstree-checkbox-no-clicked > .jstree-wholerow-ul .jstree-wholerow-clicked {
  background: none;
}

.jstree-default-dark.jstree-checkbox-no-clicked > .jstree-wholerow-ul .jstree-wholerow-clicked.jstree-wholerow-hovered {
  background: #555;
}

.jstree-default-dark > .jstree-striped {
  min-width: 100%;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==") 0 0;
  display: inline-block;
}

.jstree-default-dark > .jstree-wholerow-ul .jstree-hovered, .jstree-default-dark > .jstree-wholerow-ul .jstree-clicked {
  box-shadow: none;
  background: none;
  border-radius: 0;
}

.jstree-default-dark .jstree-wholerow {
  box-sizing: border-box;
}

.jstree-default-dark .jstree-wholerow-hovered {
  background: #555;
}

.jstree-default-dark .jstree-wholerow-clicked {
  background: linear-gradient(#5fa2db 0% 100%);
}

.jstree-default-dark .jstree-node {
  min-height: 24px;
  min-width: 24px;
  margin-left: 24px;
  line-height: 24px;
}

.jstree-default-dark .jstree-anchor {
  height: 24px;
  line-height: 24px;
}

.jstree-default-dark .jstree-icon, .jstree-default-dark .jstree-icon:empty {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.jstree-default-dark.jstree-rtl .jstree-node {
  margin-right: 24px;
}

.jstree-default-dark .jstree-wholerow {
  height: 24px;
}

.jstree-default-dark .jstree-node, .jstree-default-dark .jstree-icon {
  background-image: url("32px.7fec035e.png");
}

.jstree-default-dark .jstree-node {
  background-position: -292px -4px;
  background-repeat: repeat-y;
}

.jstree-default-dark .jstree-last {
  background: none;
}

.jstree-default-dark .jstree-open > .jstree-ocl {
  background-position: -132px -4px;
}

.jstree-default-dark .jstree-closed > .jstree-ocl {
  background-position: -100px -4px;
}

.jstree-default-dark .jstree-leaf > .jstree-ocl {
  background-position: -68px -4px;
}

.jstree-default-dark .jstree-themeicon {
  background-position: -260px -4px;
}

.jstree-default-dark > .jstree-no-dots .jstree-node, .jstree-default-dark > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default-dark > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -36px -4px;
}

.jstree-default-dark > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -4px -4px;
}

.jstree-default-dark .jstree-disabled, .jstree-default-dark .jstree-disabled.jstree-hovered {
  background: none;
}

.jstree-default-dark .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default-dark .jstree-checkbox {
  background-position: -164px -4px;
}

.jstree-default-dark .jstree-checkbox:hover {
  background-position: -164px -36px;
}

.jstree-default-dark.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox, .jstree-default-dark .jstree-checked > .jstree-checkbox {
  background-position: -228px -4px;
}

.jstree-default-dark.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover, .jstree-default-dark .jstree-checked > .jstree-checkbox:hover {
  background-position: -228px -36px;
}

.jstree-default-dark .jstree-anchor > .jstree-undetermined {
  background-position: -196px -4px;
}

.jstree-default-dark .jstree-anchor > .jstree-undetermined:hover {
  background-position: -196px -36px;
}

.jstree-default-dark .jstree-checkbox-disabled {
  opacity: .8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  filter: gray;
  -webkit-filter: grayscale();
}

.jstree-default-dark > .jstree-striped {
  background-size: auto 48px;
}

.jstree-default-dark.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default-dark.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default-dark.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -132px -36px;
}

.jstree-default-dark.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -100px -36px;
}

.jstree-default-dark.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -68px -36px;
}

.jstree-default-dark.jstree-rtl > .jstree-no-dots .jstree-node, .jstree-default-dark.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default-dark.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -36px -36px;
}

.jstree-default-dark.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -4px -36px;
}

.jstree-default-dark .jstree-themeicon-custom {
  background-color: #0000;
  background-image: none;
  background-position: 0 0;
}

.jstree-default-dark > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("throbber.ff68bdb0.gif") center no-repeat;
}

.jstree-default-dark .jstree-file {
  background: url("32px.7fec035e.png") -100px -68px no-repeat;
}

.jstree-default-dark .jstree-folder {
  background: url("32px.7fec035e.png") -260px -4px no-repeat;
}

.jstree-default-dark > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

#jstree-dnd.jstree-default-dark {
  padding: 0 4px;
  line-height: 24px;
}

#jstree-dnd.jstree-default-dark .jstree-ok, #jstree-dnd.jstree-default-dark .jstree-er {
  background-color: #0000;
  background-image: url("32px.7fec035e.png");
  background-repeat: no-repeat;
}

#jstree-dnd.jstree-default-dark i {
  width: 24px;
  height: 24px;
  background: none;
  line-height: 24px;
}

#jstree-dnd.jstree-default-dark .jstree-ok {
  background-position: -4px -68px;
}

#jstree-dnd.jstree-default-dark .jstree-er {
  background-position: -36px -68px;
}

.jstree-default-dark .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default-dark .jstree-ellipsis .jstree-anchor {
  width: 71%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default-dark.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
}

.jstree-default-dark.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default-dark-small .jstree-node {
  min-height: 18px;
  min-width: 18px;
  margin-left: 18px;
  line-height: 18px;
}

.jstree-default-dark-small .jstree-anchor {
  height: 18px;
  line-height: 18px;
}

.jstree-default-dark-small .jstree-icon, .jstree-default-dark-small .jstree-icon:empty {
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
  margin-right: 18px;
}

.jstree-default-dark-small .jstree-wholerow {
  height: 18px;
}

.jstree-default-dark-small .jstree-node, .jstree-default-dark-small .jstree-icon {
  background-image: url("32px.7fec035e.png");
}

.jstree-default-dark-small .jstree-node {
  background-position: -295px -7px;
  background-repeat: repeat-y;
}

.jstree-default-dark-small .jstree-last {
  background: none;
}

.jstree-default-dark-small .jstree-open > .jstree-ocl {
  background-position: -135px -7px;
}

.jstree-default-dark-small .jstree-closed > .jstree-ocl {
  background-position: -103px -7px;
}

.jstree-default-dark-small .jstree-leaf > .jstree-ocl {
  background-position: -71px -7px;
}

.jstree-default-dark-small .jstree-themeicon {
  background-position: -263px -7px;
}

.jstree-default-dark-small > .jstree-no-dots .jstree-node, .jstree-default-dark-small > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default-dark-small > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -39px -7px;
}

.jstree-default-dark-small > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -7px -7px;
}

.jstree-default-dark-small .jstree-disabled, .jstree-default-dark-small .jstree-disabled.jstree-hovered {
  background: none;
}

.jstree-default-dark-small .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default-dark-small .jstree-checkbox {
  background-position: -167px -7px;
}

.jstree-default-dark-small .jstree-checkbox:hover {
  background-position: -167px -39px;
}

.jstree-default-dark-small.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox, .jstree-default-dark-small .jstree-checked > .jstree-checkbox {
  background-position: -231px -7px;
}

.jstree-default-dark-small.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover, .jstree-default-dark-small .jstree-checked > .jstree-checkbox:hover {
  background-position: -231px -39px;
}

.jstree-default-dark-small .jstree-anchor > .jstree-undetermined {
  background-position: -199px -7px;
}

.jstree-default-dark-small .jstree-anchor > .jstree-undetermined:hover {
  background-position: -199px -39px;
}

.jstree-default-dark-small .jstree-checkbox-disabled {
  opacity: .8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  filter: gray;
  -webkit-filter: grayscale();
}

.jstree-default-dark-small > .jstree-striped {
  background-size: auto 36px;
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default-dark-small.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default-dark-small.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -135px -39px;
}

.jstree-default-dark-small.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -103px -39px;
}

.jstree-default-dark-small.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -71px -39px;
}

.jstree-default-dark-small.jstree-rtl > .jstree-no-dots .jstree-node, .jstree-default-dark-small.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default-dark-small.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -39px -39px;
}

.jstree-default-dark-small.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -7px -39px;
}

.jstree-default-dark-small .jstree-themeicon-custom {
  background-color: #0000;
  background-image: none;
  background-position: 0 0;
}

.jstree-default-dark-small > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("throbber.ff68bdb0.gif") center no-repeat;
}

.jstree-default-dark-small .jstree-file {
  background: url("32px.7fec035e.png") -103px -71px no-repeat;
}

.jstree-default-dark-small .jstree-folder {
  background: url("32px.7fec035e.png") -263px -7px no-repeat;
}

.jstree-default-dark-small > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

#jstree-dnd.jstree-default-dark-small {
  padding: 0 4px;
  line-height: 18px;
}

#jstree-dnd.jstree-default-dark-small .jstree-ok, #jstree-dnd.jstree-default-dark-small .jstree-er {
  background-color: #0000;
  background-image: url("32px.7fec035e.png");
  background-repeat: no-repeat;
}

#jstree-dnd.jstree-default-dark-small i {
  width: 18px;
  height: 18px;
  background: none;
  line-height: 18px;
}

#jstree-dnd.jstree-default-dark-small .jstree-ok {
  background-position: -7px -71px;
}

#jstree-dnd.jstree-default-dark-small .jstree-er {
  background-position: -39px -71px;
}

.jstree-default-dark-small .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default-dark-small .jstree-ellipsis .jstree-anchor {
  width: 77%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==");
}

.jstree-default-dark-small.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default-dark-large .jstree-node {
  min-height: 32px;
  min-width: 32px;
  margin-left: 32px;
  line-height: 32px;
}

.jstree-default-dark-large .jstree-anchor {
  height: 32px;
  line-height: 32px;
}

.jstree-default-dark-large .jstree-icon, .jstree-default-dark-large .jstree-icon:empty {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
  margin-right: 32px;
}

.jstree-default-dark-large .jstree-wholerow {
  height: 32px;
}

.jstree-default-dark-large .jstree-node, .jstree-default-dark-large .jstree-icon {
  background-image: url("32px.7fec035e.png");
}

.jstree-default-dark-large .jstree-node {
  background-position: -288px 0;
  background-repeat: repeat-y;
}

.jstree-default-dark-large .jstree-last {
  background: none;
}

.jstree-default-dark-large .jstree-open > .jstree-ocl {
  background-position: -128px 0;
}

.jstree-default-dark-large .jstree-closed > .jstree-ocl {
  background-position: -96px 0;
}

.jstree-default-dark-large .jstree-leaf > .jstree-ocl {
  background-position: -64px 0;
}

.jstree-default-dark-large .jstree-themeicon {
  background-position: -256px 0;
}

.jstree-default-dark-large > .jstree-no-dots .jstree-node, .jstree-default-dark-large > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default-dark-large > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -32px 0;
}

.jstree-default-dark-large > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: 0 0;
}

.jstree-default-dark-large .jstree-disabled, .jstree-default-dark-large .jstree-disabled.jstree-hovered {
  background: none;
}

.jstree-default-dark-large .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default-dark-large .jstree-checkbox {
  background-position: -160px 0;
}

.jstree-default-dark-large .jstree-checkbox:hover {
  background-position: -160px -32px;
}

.jstree-default-dark-large.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox, .jstree-default-dark-large .jstree-checked > .jstree-checkbox {
  background-position: -224px 0;
}

.jstree-default-dark-large.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover, .jstree-default-dark-large .jstree-checked > .jstree-checkbox:hover {
  background-position: -224px -32px;
}

.jstree-default-dark-large .jstree-anchor > .jstree-undetermined {
  background-position: -192px 0;
}

.jstree-default-dark-large .jstree-anchor > .jstree-undetermined:hover {
  background-position: -192px -32px;
}

.jstree-default-dark-large .jstree-checkbox-disabled {
  opacity: .8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  filter: gray;
  -webkit-filter: grayscale();
}

.jstree-default-dark-large > .jstree-striped {
  background-size: auto 64px;
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default-dark-large.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default-dark-large.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -128px -32px;
}

.jstree-default-dark-large.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -96px -32px;
}

.jstree-default-dark-large.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -64px -32px;
}

.jstree-default-dark-large.jstree-rtl > .jstree-no-dots .jstree-node, .jstree-default-dark-large.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: none;
}

.jstree-default-dark-large.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -32px -32px;
}

.jstree-default-dark-large.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: 0 -32px;
}

.jstree-default-dark-large .jstree-themeicon-custom {
  background-color: #0000;
  background-image: none;
  background-position: 0 0;
}

.jstree-default-dark-large > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("throbber.ff68bdb0.gif") center no-repeat;
}

.jstree-default-dark-large .jstree-file {
  background: url("32px.7fec035e.png") -96px -64px no-repeat;
}

.jstree-default-dark-large .jstree-folder {
  background: url("32px.7fec035e.png") -256px 0 no-repeat;
}

.jstree-default-dark-large > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

#jstree-dnd.jstree-default-dark-large {
  padding: 0 4px;
  line-height: 32px;
}

#jstree-dnd.jstree-default-dark-large .jstree-ok, #jstree-dnd.jstree-default-dark-large .jstree-er {
  background-color: #0000;
  background-image: url("32px.7fec035e.png");
  background-repeat: no-repeat;
}

#jstree-dnd.jstree-default-dark-large i {
  width: 32px;
  height: 32px;
  background: none;
  line-height: 32px;
}

#jstree-dnd.jstree-default-dark-large .jstree-ok {
  background-position: 0 -64px;
}

#jstree-dnd.jstree-default-dark-large .jstree-er {
  background-position: -32px -64px;
}

.jstree-default-dark-large .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default-dark-large .jstree-ellipsis .jstree-anchor {
  width: 63%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==");
}

.jstree-default-dark-large.jstree-rtl .jstree-last {
  background: none;
}

@media (max-width: 768px) {
  #jstree-dnd.jstree-dnd-responsive {
    text-shadow: 1px 1px #fff;
    font-size: 1.1em;
    font-weight: bold;
    line-height: 40px;
  }

  #jstree-dnd.jstree-dnd-responsive > i {
    width: 40px;
    height: 40px;
    background: none;
  }

  #jstree-dnd.jstree-dnd-responsive > .jstree-ok {
    background-image: url("40px.987061b6.png");
    background-position: 0 -200px;
    background-size: 120px 240px;
  }

  #jstree-dnd.jstree-dnd-responsive > .jstree-er {
    background-image: url("40px.987061b6.png");
    background-position: -40px -200px;
    background-size: 120px 240px;
  }

  #jstree-marker.jstree-dnd-responsive {
    border-top-width: 10px;
    border-bottom-width: 10px;
    border-left-width: 10px;
    margin-top: -10px;
  }

  .jstree-default-dark-responsive .jstree-icon {
    background-image: url("40px.987061b6.png");
  }

  .jstree-default-dark-responsive .jstree-node, .jstree-default-dark-responsive .jstree-leaf > .jstree-ocl {
    background: none;
  }

  .jstree-default-dark-responsive .jstree-node {
    min-height: 40px;
    min-width: 40px;
    white-space: nowrap;
    margin-left: 40px;
    line-height: 40px;
  }

  .jstree-default-dark-responsive .jstree-anchor {
    height: 40px;
    line-height: 40px;
  }

  .jstree-default-dark-responsive .jstree-icon, .jstree-default-dark-responsive .jstree-icon:empty {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .jstree-default-dark-responsive > .jstree-container-ul > .jstree-node {
    margin-left: 0;
  }

  .jstree-default-dark-responsive.jstree-rtl .jstree-node {
    background: none;
    margin-left: 0;
    margin-right: 40px;
  }

  .jstree-default-dark-responsive.jstree-rtl .jstree-container-ul > .jstree-node {
    margin-right: 0;
  }

  .jstree-default-dark-responsive .jstree-ocl, .jstree-default-dark-responsive .jstree-themeicon, .jstree-default-dark-responsive .jstree-checkbox {
    background-size: 120px 240px;
  }

  .jstree-default-dark-responsive .jstree-leaf > .jstree-ocl, .jstree-default-dark-responsive.jstree-rtl .jstree-leaf > .jstree-ocl {
    background: none;
  }

  .jstree-default-dark-responsive .jstree-open > .jstree-ocl {
    background-position: 0 0 !important;
  }

  .jstree-default-dark-responsive .jstree-closed > .jstree-ocl {
    background-position: 0 -40px !important;
  }

  .jstree-default-dark-responsive.jstree-rtl .jstree-closed > .jstree-ocl {
    background-position: -40px 0 !important;
  }

  .jstree-default-dark-responsive .jstree-themeicon {
    background-position: -40px -40px;
  }

  .jstree-default-dark-responsive .jstree-checkbox, .jstree-default-dark-responsive .jstree-checkbox:hover {
    background-position: -40px -80px;
  }

  .jstree-default-dark-responsive.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox, .jstree-default-dark-responsive.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover, .jstree-default-dark-responsive .jstree-checked > .jstree-checkbox, .jstree-default-dark-responsive .jstree-checked > .jstree-checkbox:hover {
    background-position: 0 -80px;
  }

  .jstree-default-dark-responsive .jstree-anchor > .jstree-undetermined, .jstree-default-dark-responsive .jstree-anchor > .jstree-undetermined:hover {
    background-position: 0 -120px;
  }

  .jstree-default-dark-responsive .jstree-anchor {
    text-shadow: 1px 1px #fff;
    font-size: 1.1em;
    font-weight: bold;
  }

  .jstree-default-dark-responsive > .jstree-striped {
    background: none;
  }

  .jstree-default-dark-responsive .jstree-wholerow {
    height: 40px;
    background: #333;
    border-top: 1px solid #666;
    border-bottom: 1px solid #000;
  }

  .jstree-default-dark-responsive .jstree-wholerow-hovered {
    background: #555;
  }

  .jstree-default-dark-responsive .jstree-wholerow-clicked {
    background: #5fa2db;
  }

  .jstree-default-dark-responsive .jstree-children .jstree-last > .jstree-wholerow {
    box-shadow: inset 0 -6px 3px -5px #111;
  }

  .jstree-default-dark-responsive .jstree-children .jstree-open > .jstree-wholerow {
    border-top: 0;
    box-shadow: inset 0 6px 3px -5px #111;
  }

  .jstree-default-dark-responsive .jstree-children .jstree-open + .jstree-open {
    box-shadow: none;
  }

  .jstree-default-dark-responsive .jstree-node, .jstree-default-dark-responsive .jstree-icon, .jstree-default-dark-responsive .jstree-node > .jstree-ocl, .jstree-default-dark-responsive .jstree-themeicon, .jstree-default-dark-responsive .jstree-checkbox {
    background-image: url("40px.987061b6.png");
    background-size: 120px 240px;
  }

  .jstree-default-dark-responsive .jstree-node {
    background-position: -80px 0;
    background-repeat: repeat-y;
  }

  .jstree-default-dark-responsive .jstree-last {
    background: none;
  }

  .jstree-default-dark-responsive .jstree-leaf > .jstree-ocl {
    background-position: -40px -120px;
  }

  .jstree-default-dark-responsive .jstree-last > .jstree-ocl {
    background-position: -40px -160px;
  }

  .jstree-default-dark-responsive .jstree-themeicon-custom {
    background-color: #0000;
    background-image: none;
    background-position: 0 0;
  }

  .jstree-default-dark-responsive .jstree-file {
    background: url("40px.987061b6.png") 0 -160px / 120px 240px no-repeat;
  }

  .jstree-default-dark-responsive .jstree-folder {
    background: url("40px.987061b6.png") -40px -40px / 120px 240px no-repeat;
  }

  .jstree-default-dark-responsive > .jstree-container-ul > .jstree-node {
    margin-left: 0;
    margin-right: 0;
  }
}

.jstree-default-dark {
  background: #333;
}

.jstree-default-dark .jstree-anchor {
  color: #999;
  text-shadow: 1px 1px #00000080;
}

.jstree-default-dark .jstree-clicked, .jstree-default-dark .jstree-checked, .jstree-default-dark .jstree-hovered {
  color: #fff;
}

#jstree-marker.jstree-default-dark {
  background: none;
  border-left-color: #999;
}

.jstree-default-dark .jstree-anchor > .jstree-icon {
  opacity: .75;
}

.jstree-default-dark .jstree-clicked > .jstree-icon, .jstree-default-dark .jstree-hovered > .jstree-icon, .jstree-default-dark .jstree-checked > .jstree-icon {
  opacity: 1;
}

.jstree-default-dark.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAACZmZl+9SADAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
}

.jstree-default-dark.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAACZmZl+9SADAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==");
}

.jstree-default-dark-small.jstree-rtl .jstree-last {
  background: none;
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAACZmZl+9SADAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==");
}

.jstree-default-dark-large.jstree-rtl .jstree-last {
  background: none;
}

@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .product-item:nth-child(3n):hover .product-image-container, .product-item:nth-child(3n):focus .product-image-container, .product-item:nth-child(3n):focus-within .product-image-container {
    border-radius: 0 5px 5px 0;
  }

  .product-item:nth-child(3n) .product-price-list-container {
    border-radius: 5px 0 0 5px;
    left: calc(-97% + 22px);
    right: auto;
  }
}

@media (min-width: 768px), print {
  .abs-product-options-list-desktop dt, .block-giftregistry-shared .item-options dt {
    clear: left;
    float: left;
    margin: 0 10px 5px 0;
  }

  .block-giftregistry-shared .item-options dt:after {
    content: ": ";
  }

  .abs-product-options-list-desktop dd, .block-giftregistry-shared .item-options dd {
    float: left;
    margin: 0 0 5px;
    display: inline-block;
  }

  .abs-button-desktop {
    width: auto;
  }

  .abs-blocks-2columns, .abs-discount-block-desktop .block, .storecredit .block, .account .column.main .block:not(.widget) .block-content .box, .form-address-edit > .fieldset, .form-edit-account .fieldset, .paypal-review-discount .block, .cart-discount .block {
    width: 48%;
  }

  .abs-discount-block-desktop .block:nth-child(1), .storecredit .block:nth-child(1), .account .column.main .block:not(.widget) .block-content .box:nth-child(1), .form-address-edit > .fieldset:nth-child(1), .form-edit-account .fieldset:nth-child(1), .paypal-review-discount .block:nth-child(1), .cart-discount .block:nth-child(1) {
    clear: left;
    float: left;
  }

  .abs-discount-block-desktop .block:nth-child(2), .storecredit .block:nth-child(2), .account .column.main .block:not(.widget) .block-content .box:nth-child(2), .form-address-edit > .fieldset:nth-child(2), .form-edit-account .fieldset:nth-child(2), .paypal-review-discount .block:nth-child(2), .cart-discount .block:nth-child(2) {
    float: right;
  }

  .abs-discount-block-desktop .block:nth-child(2) + *, .storecredit .block:nth-child(2) + *, .account .column.main .block:not(.widget) .block-content .box:nth-child(2) + *, .form-address-edit > .fieldset:nth-child(2) + *, .form-edit-account .fieldset:nth-child(2) + *, .paypal-review-discount .block:nth-child(2) + *, .cart-discount .block:nth-child(2) + * {
    clear: both;
  }

  .abs-margin-for-blocks-and-widgets-desktop, .customer-review .product-details {
    margin-bottom: 50px;
  }

  .abs-reset-left-margin-desktop, .column.main .paypal-review .actions-toolbar, .column.main .block-giftregistry-shared-items .actions-toolbar {
    margin-left: 0;
  }

  .abs-action-remove-desktop, .abs-add-fields-desktop .fieldset .additional .action.remove, .form-giftregistry-share .fieldset .additional .action.remove, .form-giftregistry-edit .fieldset .additional .action.remove, .form-create-return .fieldset .additional .action.remove, .form.send.friend .fieldset .additional .action.remove {
    margin-left: 90%;
  }

  .abs-add-fields-desktop .fieldset .field .control, .form-giftregistry-share .fieldset .field .control, .form-giftregistry-edit .fieldset .field .control, .form-create-return .fieldset .field .control, .form.send.friend .fieldset .field .control {
    width: auto;
  }

  .abs-margin-for-forms-desktop {
    margin-left: 25.8%;
  }

  .abs-visually-hidden-desktop, .modes-label, .block-search .label, .block-collapsible-nav .title {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .abs-add-clearfix-desktop:before, .abs-add-clearfix-desktop:after, .paypal-review .block-content:before, .paypal-review .block-content:after, .paypal-review-discount:before, .paypal-review-discount:after, .order-review-form:before, .order-review-form:after, .block-cart-failed .block-content:before, .block-cart-failed .block-content:after, .cart-container:before, .cart-container:after, .block-giftregistry-shared .item-options:before, .block-giftregistry-shared .item-options:after, .data.table .gift-wrapping .nested:before, .data.table .gift-wrapping .nested:after, .data.table .gift-wrapping .content:before, .data.table .gift-wrapping .content:after, .block-wishlist-management:before, .block-wishlist-management:after, .magento-rma-guest-returns .column.main .block.block-order-details-view:before, .magento-rma-guest-returns .column.main .block.block-order-details-view:after, .account .page-title-wrapper:before, .account .page-title-wrapper:after, .account .column.main .block:not(.widget) .block-content:before, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:before, .block-addresses-list .items.addresses:after, .order-links:before, .order-links:after, .account .column.main .block.block-order-details-view:before, .account .column.main .block.block-order-details-view:after, [class^="sales-guest-"] .column.main .block.block-order-details-view:before, [class^="sales-guest-"] .column.main .block.block-order-details-view:after, .sales-guest-view .column.main .block.block-order-details-view:before, .sales-guest-view .column.main .block.block-order-details-view:after, .page-header .header.panel:before, .page-header .header.panel:after, .header.content:before, .header.content:after {
    content: "";
    display: table;
  }

  .abs-add-clearfix-desktop:after, .paypal-review .block-content:after, .paypal-review-discount:after, .order-review-form:after, .block-cart-failed .block-content:after, .cart-container:after, .block-giftregistry-shared .item-options:after, .data.table .gift-wrapping .nested:after, .data.table .gift-wrapping .content:after, .block-wishlist-management:after, .magento-rma-guest-returns .column.main .block.block-order-details-view:after, .account .page-title-wrapper:after, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:after, .order-links:after, .account .column.main .block.block-order-details-view:after, [class^="sales-guest-"] .column.main .block.block-order-details-view:after, .sales-guest-view .column.main .block.block-order-details-view:after, .page-header .header.panel:after, .header.content:after {
    clear: both;
  }

  .abs-add-box-sizing-desktop, .abs-shopping-cart-items-desktop, .column.main, .sidebar-main, .sidebar-additional, .bundle-options-container .block-bundle-summary, .block.crosssell, .magento-rma-guest-returns .column.main .block.block-order-details-view .block-content:not(.widget) .box, .account .column.main .block.block-order-details-view .block-content:not(.widget) .box, [class^="sales-guest-"] .column.main .block.block-order-details-view .block-content:not(.widget) .box, .sales-guest-view .column.main .block.block-order-details-view .block-content:not(.widget) .box, .block-cart-failed, .cart-container .cart-gift-item, .abs-add-box-sizing-desktop-m {
    box-sizing: border-box;
  }

  .abs-revert-field-type-desktop .fieldset > .field, .abs-revert-field-type-desktop .fieldset .fields > .field {
    margin: 0 0 20px;
  }

  .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .label, .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .label {
    box-sizing: content-box;
    float: none;
    width: auto;
    text-align: left;
    padding: 0;
  }

  .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .control, .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .control {
    float: none;
    width: auto;
  }

  .abs-revert-field-type-desktop .fieldset > .field > .label, .abs-revert-field-type-desktop .fieldset .fields > .field > .label {
    margin: 0 0 8px;
    display: inline-block;
  }

  .abs-revert-field-type-desktop .fieldset > .field.choice:before, .abs-revert-field-type-desktop .fieldset .fields > .field.choice:before, .abs-revert-field-type-desktop .fieldset > .field.no-label:before, .abs-revert-field-type-desktop .fieldset .fields > .field.no-label:before {
    display: none;
  }

  .abs-form-field-column-2 .fieldset .field, .form-giftregistry-share .fieldset .field, .form-giftregistry-edit .fieldset .field, .form-create-return .fieldset .field, .form.send.friend .fieldset .field {
    box-sizing: border-box;
    width: 50%;
    vertical-align: top;
    padding: 0 12px 0 0;
    display: inline-block;
  }

  .abs-form-field-column-2 .fieldset .field + .fieldset, .form-giftregistry-share .fieldset .field + .fieldset, .form-giftregistry-edit .fieldset .field + .fieldset, .form-create-return .fieldset .field + .fieldset, .form.send.friend .fieldset .field + .fieldset {
    clear: both;
  }

  .form-giftregistry-share .fieldset .field:nth-last-child(1), .form-giftregistry-share .fieldset .field:nth-last-child(2), .form-giftregistry-edit .fieldset .field:nth-last-child(1), .form-giftregistry-edit .fieldset .field:nth-last-child(2), .form-create-return .fieldset .field:nth-last-child(1), .form-create-return .fieldset .field:nth-last-child(2), .form.send.friend .fieldset .field:nth-last-child(1), .form.send.friend .fieldset .field:nth-last-child(2) {
    margin-bottom: 0;
  }

  .abs-form-field-column-2 .fieldset .field .field, .form-giftregistry-share .fieldset .field .field, .form-giftregistry-edit .fieldset .field .field, .form-create-return .fieldset .field .field, .form.send.friend .fieldset .field .field {
    width: 100%;
    padding: 0;
  }

  .abs-form-field-revert-column-1 {
    width: 100%;
  }

  .abs-forms-general-desktop, .form-giftcard-redeem, .form-giftregistry-create {
    max-width: 500px;
  }

  .abs-forms-general-desktop .legend, .form-giftcard-redeem .legend, .form-giftregistry-create .legend {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .abs-forms-general-desktop .legend + br, .form-giftcard-redeem .legend + br, .form-giftregistry-create .legend + br {
    display: none;
  }

  .abs-revert-side-paddings, .checkout-cart-index .page-title-wrapper, .cart-empty {
    padding-left: 0;
    padding-right: 0;
  }

  .abs-account-block-font-size, .block-balance-giftcard .block-content, .block-reviews-dashboard .product-name, .block-addresses-list address, .box-billing-address .box-content, .box-shipping-address .box-content, .box-address-billing .box-content, .box-address-shipping .box-content, .box-information .box-content {
    font-size: 1.6rem;
  }

  .abs-account-table-margin-desktop, .table-wrapper.balance-history, .table-wrapper.reward-history {
    margin-top: -25px;
  }

  .abs-action-print {
    text-decoration: none;
    display: inline-block;
  }

  .abs-action-print:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    content: "";
    vertical-align: middle;
    speak: none;
    text-align: center;
    margin: 0 4px 0 0;
    font-family: "Font Awesome 5 Pro";
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    display: inline-block;
    overflow: hidden;
  }

  .abs-no-display-desktop, .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
    display: none;
  }

  .abs-status-desktop, .return-status, .order-status {
    float: right;
    margin-top: 0;
  }

  .abs-title-orders-desktop .page-main .page-title-wrapper .order-date, .magento-rma-returns-returns .page-main .page-title-wrapper .order-date, .magento-rma-returns-view .page-main .page-title-wrapper .order-date, .account .page-main .page-title-wrapper .order-date, [class^="sales-guest-"] .page-main .page-title-wrapper .order-date, .sales-guest-view .page-main .page-title-wrapper .order-date {
    margin: -14px 0 18px;
  }

  .abs-table-bordered-desktop, .abs-table-bordered-desktop > thead > tr > th, .abs-table-bordered-desktop > tbody > tr > th, .abs-table-bordered-desktop > tfoot > tr > th, .abs-table-bordered-desktop > thead > tr > td, .abs-table-bordered-desktop > tbody > tr > td, .abs-table-bordered-desktop > tfoot > tr > td {
    border: none;
  }

  .abs-table-bordered-desktop > thead > tr > th, .abs-table-bordered-desktop > thead > tr > td {
    border-bottom: 1px solid #ccc;
  }

  .abs-pager-toolbar, .toolbar-giftregistry-results, .toolbar-wishlist-results, .account .toolbar {
    position: relative;
  }

  .abs-pager-toolbar .toolbar-amount, .abs-pager-toolbar .limiter, .toolbar-giftregistry-results .toolbar-amount, .toolbar-giftregistry-results .limiter, .toolbar-wishlist-results .toolbar-amount, .toolbar-wishlist-results .limiter, .account .toolbar .toolbar-amount, .account .toolbar .limiter {
    z-index: 1;
    position: relative;
  }

  .abs-pager-toolbar .limiter, .toolbar-giftregistry-results .limiter, .toolbar-wishlist-results .limiter, .account .toolbar .limiter {
    float: right;
    display: inline-block;
  }

  .abs-pager-toolbar .toolbar-amount, .toolbar-giftregistry-results .toolbar-amount, .toolbar-wishlist-results .toolbar-amount, .account .toolbar .toolbar-amount {
    padding: 0;
    line-height: 30px;
  }

  .abs-shopping-cart-items-desktop, .block-cart-failed, .cart-container .cart-gift-item {
    width: 75%;
    float: left;
    order: 1;
    padding-right: 4%;
    position: relative;
  }

  .abs-discount-block-desktop .block > .title, .paypal-review-discount .block > .title, .cart-discount .block > .title {
    border: 0;
    padding: 0 0 10px;
  }

  .abs-discount-block-desktop .block > .title strong, .paypal-review-discount .block > .title strong, .cart-discount .block > .title strong {
    font-size: 1.6rem;
  }

  .abs-discount-block-desktop .block .content, .paypal-review-discount .block .content, .cart-discount .block .content {
    padding: 0 0 20px;
  }

  .abs-discount-block-desktop .actions-toolbar .secondary, .paypal-review-discount .actions-toolbar .secondary, .cart-discount .actions-toolbar .secondary {
    position: absolute;
    bottom: -30px;
    left: 0;
  }

  .navigation, .breadcrumbs, .page-header .header.panel, .header.content, .footer.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main {
    box-sizing: border-box;
    max-width: 1920px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .page-main {
    width: 100%;
  }

  .columns {
    display: block;
  }

  .column.main {
    min-height: 300px;
  }

  .page-layout-1column .column.main {
    width: 100%;
    order: 2;
  }

  .page-layout-3columns .column.main {
    width: 58.3333%;
    order: 2;
    display: inline-block;
  }

  .page-layout-2columns-left .column.main {
    width: 79.1667%;
    float: right;
    order: 2;
  }

  .page-layout-2columns-right .column.main {
    width: 79.1667%;
    float: left;
    order: 1;
  }

  .sidebar-main {
    padding-right: 2%;
  }

  .page-layout-3columns .sidebar-main, .page-layout-2columns-left .sidebar-main, .page-layout-2columns-right .sidebar-main {
    width: 20.8333%;
    float: left;
    order: 1;
  }

  .page-layout-2columns-right .sidebar-main {
    padding-left: 2%;
    padding-right: 0;
  }

  .sidebar-additional {
    clear: right;
    padding-left: 2%;
  }

  .page-layout-3columns .sidebar-additional {
    width: 20.8333%;
    float: right;
    order: 3;
  }

  .page-layout-2columns-left .sidebar-additional, .page-layout-2columns-right .sidebar-additional {
    width: 20.8333%;
    float: right;
    order: 2;
  }

  .page-layout-2columns-left .sidebar-additional {
    clear: left;
    float: left;
    padding-left: 0;
    padding-right: 2%;
  }

  .panel.header {
    padding: 10px 20px;
  }

  .table > tbody > tr > th, .table > tbody > tr > td, .table > tbody + tbody {
    border-top: 1px solid #ccc;
  }

  .nav-toggle {
    display: none;
  }

  .nav-sections {
    flex-shrink: 0;
    flex-basis: auto;
    margin-bottom: 25px;
  }

  .nav-sections-item-title {
    display: none;
  }

  .nav-sections-item-content {
    display: block !important;
  }

  .nav-sections-item-content > * {
    display: none;
  }

  .nav-sections-item-content > .navigation {
    display: block;
  }

  .navigation {
    height: inherit;
    overflow: inherit;
    width: 100%;
    z-index: 3;
    background: #f0f0f0;
    padding: 0;
    font-weight: 700;
    position: relative;
    top: 0;
    left: auto;
  }

  .navigation:empty {
    display: none;
  }

  .navigation ul {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    position: relative;
  }

  .navigation li.level0 {
    border-top: none;
  }

  .navigation li.level1 {
    position: relative;
  }

  .navigation .level0 {
    margin: 0 10px 0 0;
    display: inline-block;
    position: relative;
  }

  .navigation .level0:last-child {
    margin-right: 0;
    padding-right: 0;
  }

  .navigation .level0:hover:after {
    content: "";
    width: 10px;
    height: calc(100% + 3px);
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
  }

  .navigation .level0 > .level-top {
    color: #575757;
    box-sizing: border-box;
    padding: 0 12px;
    line-height: 47px;
    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  .navigation .level0 > .level-top:hover, .navigation .level0 > .level-top.ui-state-focus {
    color: #333;
    text-decoration: none;
  }

  .navigation .level0.active > .level-top, .navigation .level0.has-active > .level-top {
    color: #333;
    border: 0 solid #4a4a4a;
    border-bottom-width: 3px;
    text-decoration: none;
    display: inline-block;
  }

  .navigation .level0.parent:hover > .submenu {
    overflow: visible !important;
  }

  .navigation .level0.parent > .level-top {
    padding-right: 20px;
  }

  .navigation .level0.parent > .level-top > .ui-menu-icon {
    text-decoration: none;
    display: inline-block;
    position: absolute;
    right: 0;
  }

  .navigation .level0.parent > .level-top > .ui-menu-icon > span {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .navigation .level0.parent > .level-top > .ui-menu-icon:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    content: "";
    vertical-align: middle;
    speak: none;
    text-align: center;
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    display: inline-block;
    overflow: hidden;
  }

  .navigation .level0 .submenu {
    min-width: 230px;
    z-index: 1;
    background: #fff;
    border: 1px solid #ccc;
    margin-top: 11px;
    padding: 15px 0;
    font-weight: 400;
    display: none;
    position: absolute;
    left: 0;
    box-shadow: 0 5px 5px #00000030;
    margin: 0 !important;
  }

  .navigation .level0 .submenu > ul {
    margin-top: 11px;
  }

  .navigation .level0 .submenu > ul:before, .navigation .level0 .submenu > ul:after {
    content: "";
    display: block;
    position: absolute;
    overflow: hidden;
  }

  .navigation .level0 .submenu > ul:before {
    color: #fff;
    height: 0;
    width: 0;
    z-index: 4;
    border: 10px solid #0000;
    border-bottom-color: #fff;
    top: -20px;
    left: 20px;
  }

  .navigation .level0 .submenu > ul:after {
    height: 0;
    width: 0;
    color: #ccc;
    z-index: 3;
    border: 11px solid #0000;
    border-bottom-color: #ccc;
    top: -22px;
    left: 19px;
  }

  .navigation .level0 .submenu:before {
    content: "";
    width: 100%;
    height: 4px;
    z-index: 1;
    display: block;
    position: absolute;
    top: -4px;
    left: 0;
  }

  .navigation .level0 .submenu a {
    line-height: inherit;
    color: #575757;
    padding: 8px 20px;
    display: block;
  }

  .navigation .level0 .submenu a:hover, .navigation .level0 .submenu a.ui-state-focus {
    color: #333;
    background: #e8e8e8;
    text-decoration: none;
  }

  .navigation .level0 .submenu .active > a {
    color: #333;
    border: 0 solid #4a4a4a;
    border-left-width: 3px;
  }

  .navigation .level0 .submenu .submenu {
    top: 0 !important;
    left: 100% !important;
  }

  .navigation .level0 .submenu .submenu-reverse {
    right: 100%;
    left: auto !important;
  }

  .navigation .level0 .submenu li {
    margin: 0;
  }

  .navigation .level0 .submenu li.parent > a > .ui-menu-icon {
    text-decoration: none;
    display: inline-block;
    position: absolute;
    right: 3px;
  }

  .navigation .level0 .submenu li.parent > a > .ui-menu-icon > span {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .navigation .level0 .submenu li.parent > a > .ui-menu-icon:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    content: "";
    vertical-align: middle;
    speak: none;
    text-align: center;
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    display: inline-block;
    overflow: hidden;
  }

  .navigation .level0.more {
    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  .navigation .level0.more:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: inherit;
    color: inherit;
    content: "";
    vertical-align: middle;
    speak: none;
    text-align: center;
    font-family: "Font Awesome 5 Pro";
    font-weight: bold;
    display: inline-block;
    overflow: hidden;
  }

  .navigation .level0.more:before {
    display: none;
  }

  .navigation .level0.more:after {
    cursor: pointer;
    z-index: 1;
    padding: 8px 12px;
    position: relative;
  }

  .navigation .level0.more:hover > .submenu {
    overflow: visible !important;
  }

  .navigation .level0.more li {
    display: block;
  }

  .panel.header .links, .panel.header .switcher {
    display: inline-block;
  }

  .legend {
    border-bottom: 1px solid #c5c5c5;
  }

  .product.data.items {
    z-index: 1;
    border-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    position: relative;
  }

  .product.data.items:before, .product.data.items:after {
    content: "";
    display: table;
  }

  .product.data.items:after {
    clear: both;
  }

  .product.data.items > .item.title {
    float: left;
    width: auto;
  }

  .product.data.items > .item.title > .switch {
    height: 20px;
    z-index: 2;
    display: block;
    position: relative;
  }

  .product.data.items > .item.content {
    box-sizing: border-box;
    float: right;
    width: 100%;
    margin-top: 20px;
    margin-left: -100%;
  }

  .product.data.items > .item.content:before, .product.data.items > .item.content:after {
    content: "";
    display: table;
  }

  .product.data.items > .item.content:after {
    clear: both;
  }

  .product.data.items > .item.content.active {
    display: block;
  }

  .product.data.items > .item.title {
    margin: 0 -1px 0 0;
  }

  .product.data.items > .item.title > .switch {
    color: #6d6d6d;
    height: 40px;
    background: #f6f6f6;
    border: 1px solid #ccc;
    border-bottom: none;
    padding: 1px 35px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 40px;
    text-decoration: none;
  }

  .product.data.items > .item.title > .switch:visited, .product.data.items > .item.title > .switch:hover {
    color: #6d6d6d;
    text-decoration: none;
  }

  .product.data.items > .item.title > .switch:active {
    color: #333;
    text-decoration: none;
  }

  .product.data.items > .item.title:not(.disabled) > .switch:focus, .product.data.items > .item.title:not(.disabled) > .switch:hover {
    background: #fff;
  }

  .product.data.items > .item.title:not(.disabled) > .switch:active, .product.data.items > .item.title.active > .switch, .product.data.items > .item.title.active > .switch:focus, .product.data.items > .item.title.active > .switch:hover {
    color: #333;
    background: #fff;
    text-decoration: none;
  }

  .product.data.items > .item.title.active > .switch, .product.data.items > .item.title.active > .switch:focus, .product.data.items > .item.title.active > .switch:hover {
    padding-bottom: 2px;
  }

  .product.data.items > .item.content {
    background: #fff;
    border: 1px solid #ccc;
    margin-top: 43px;
    padding: 35px;
  }

  .product.data.items .item.title a:after {
    display: none;
  }

  .actions-toolbar {
    text-align: left;
  }

  .actions-toolbar:before, .actions-toolbar:after {
    content: "";
    display: table;
  }

  .actions-toolbar:after {
    clear: both;
  }

  .actions-toolbar .primary {
    float: left;
  }

  .actions-toolbar .primary, .actions-toolbar .secondary, .actions-toolbar .primary a.action, .actions-toolbar .secondary a.action {
    display: inline-block;
  }

  .actions-toolbar .primary .action {
    margin: 0 15px 0 0;
  }

  .actions-toolbar .secondary a.action {
    margin-top: 6px;
  }

  .actions-toolbar > .primary, .actions-toolbar > .secondary {
    margin-bottom: 0;
  }

  .actions-toolbar > .primary .action, .actions-toolbar > .secondary .action {
    width: auto;
    margin-bottom: 0;
  }

  .popup-content .fieldset .actions-toolbar .secondary {
    float: none;
    display: inline-block;
  }

  .popup-content .fieldset .actions-toolbar .action.cancel {
    margin-top: 6px;
  }

  .modal-popup.modal-slide .modal-footer {
    text-align: right;
    padding-top: 0;
  }

  .brand-images .brands-letter {
    width: inherit;
    padding-right: 10px;
  }

  .brand-images {
    display: flex;
  }

  .am-brands-fullwidth {
    width: 100% !important;
  }

  .ui-tooltip.ui-widget {
    max-width: 450px;
  }

  .filter-options-content a:hover {
    text-decoration: none;
  }

  .catalog-topnav {
    display: block;
  }

  .page-layout-1column .toolbar-products {
    position: inherit !important;
  }

  .filter-options-content .am-filter-price {
    width: 40%;
  }

  .sidebar > .block-category-list {
    margin-bottom: 14px;
  }

  .amasty-catalog-topnav {
    margin-top: 3px;
  }

  .amasty-catalog-topnav.catalog-topnav .filter-options .filter-options-content {
    z-index: 1001;
    min-width: 200px;
  }

  .amasty-catalog-topnav .filter-options {
    margin-bottom: 20px;
    padding: 3px 0;
  }

  .amasty-catalog-topnav .filter-subtitle {
    display: none;
  }

  .amasty-catalog-topnav .amshopby-category-dropdown {
    min-width: 200px;
  }

  .amasty-catalog-topnav .filter-options-item {
    margin-bottom: 0;
  }

  .amasty-catalog-topnav .filter-options-item:first-child .filter-options-title {
    padding-left: 0;
  }

  .amasty-catalog-topnav .filter-options-title {
    padding-right: 5px;
  }

  .amasty-catalog-topnav .filter-options-title:after {
    margin: 0 0 0 15px;
    display: inline-block;
    position: static;
    transform: translateY(-2px);
  }

  .amasty-catalog-topnav .filter-options-item .filter-options-content .amshopby-flyout-block .items.amshopby-fly-out-view {
    box-shadow: none;
    margin: -11px;
  }

  .filter-options input[type="checkbox"] {
    clip: rect(0, 0, 0, 0);
    width: 0;
    height: 0;
    opacity: 0;
    border: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .filter-options input[type="checkbox"] + .amshopby-choice {
    word-wrap: break-word;
    word-break: break-word;
    min-height: 12px;
    cursor: pointer;
    margin: 0;
    padding: 0 0 0 22px;
    line-height: 1.2;
    display: block;
    position: absolute;
    top: 5px;
    left: 0;
  }

  .ie11 .filter-options input[type="checkbox"] + .amshopby-choice {
    word-break: break-all;
  }

  .filter-options input[type="checkbox"] + .amshopby-choice:before, .filter-options input[type="checkbox"] + .amshopby-choice:after {
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    content: "";
    border-radius: 2px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .filter-options input[type="checkbox"] + .amshopby-choice:before {
    background: #fff;
    border: 1px solid #e3e3e3;
  }

  .filter-options input[type="checkbox"] + .amshopby-choice:after {
    content: none;
    background: #1979c3 url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxNSAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xMS42NjkzIDMuNTAwNjFjLS4yMDc4LjAwNjk1LS40MDQ4LjA5ODg1LS41NDkzLjI1NjItMS45NTcwMyAyLjA2NDE1LTMuNDM4ODMgMy43NzY1NS01LjI4MDQxIDUuNzQ3ODNMMy44MTczIDcuNzA0NzRjLS4wNzk5Mi0uMDcxNi0uMTcyNDUtLjEyNTk1LS4yNzIzMy0uMTU5OTItLjA5OTg3LS4wMzM5Ny0uMjA1MTItLjA0NjktLjMwOTc0LS4wMzgwNi0uMTA0NjIuMDA4ODUtLjIwNjU2LjAzOTI5LS4zLjA4OTYtLjA5MzQ1LjA1MDMtLjE3NjU2LjExOTQ4LS4yNDQ1OS4yMDM2LS4wNjgwNC4wODQxMS0uMTE5NjYuMTgxNTEtLjE1MTkzLjI4NjYyLS4wMzIyNy4xMDUxMS0uMDQ0NTUuMjE1ODgtLjAzNjE1LjMyNTk4LjAwODQxLjExMDExLjAzNzM0LjIxNzQuMDg1MTUuMzE1NzQuMDQ3ODEuMDk4MzMuMTEzNTUuMTg1NzkuMTkzNDguMjU3MzlMNS4zNzc3MSAxMS4yOThjLjE1MzE1LjEzNzkuMzUwMTkuMjA5OC41NTE2LjIwMTMuMjAxNDEtLjAwODYuMzkyMzEtLjA5NjkuNTM0NDQtLjI0NzNDOC42MTA3NCA4Ljk4NzU3IDEwLjE0MiA3LjE3NTQ0IDEyLjI1NiA0Ljk0NTc4Yy4xMTY1LS4xMTgzNy4xOTYtLjI3MTIuMjI4MS0uNDM4MjMuMDMyMS0uMTY3MDMuMDE1My0uMzQwMzgtLjA0ODQtLjQ5NzA5LS4wNjM2LS4xNTY3MS0uMTcwOS0uMjg5MzctLjMwNzgtLjM4MDQyLS4xMzY5LS4wOTEwNS0uMjk2OC0uMTM2MTktLjQ1ODYtLjEyOTQzeiIvPgo8L3N2Zz4K") center / 12px;
  }

  .filter-options input[type="checkbox"] + .amshopby-choice:hover:before, .filter-options input[type="checkbox"]:checked + .amshopby-choice:before {
    border-color: #1979c3;
  }

  .filter-options input[type="checkbox"]:checked + .amshopby-choice:after {
    content: "";
  }

  ._keyfocus .filter-options input[type="checkbox"]:active + .amshopby-choice:before, ._keyfocus .filter-options input[type="checkbox"]:focus + .amshopby-choice:before, .filter-options input[type="checkbox"]:active + .amshopby-choice:before, .filter-options input[type="checkbox"]:focus + .amshopby-choice:before {
    border-color: #1979c3;
  }

  .filter-options input[type="checkbox"]:not(:checked).mage-error + .amshopby-choice:before {
    border-color: #f23b3b;
  }

  .am_shopby_apply_filters {
    bottom: inherit;
    z-index: 212;
    visibility: hidden;
    width: inherit;
    padding: 0;
    display: none;
    position: absolute;
  }

  .am_shopby_apply_filters.visible {
    bottom: inherit;
    visibility: visible;
  }

  .am_shopby_apply_filters.-fixed {
    position: fixed;
  }

  .am_shopby_apply_filters.-fixed .am-show-button:before {
    display: none;
  }

  .am-show-button {
    min-width: 180px;
    width: inherit;
    border: none;
    border-radius: 6px;
    justify-content: space-between;
    margin: 0 0 10px;
    padding: 12px 10px;
    position: relative;
    box-shadow: 0 4px 8px #bec7db36, 0 6px 25px #bec7db47;
  }

  .am-show-button > .am-items {
    margin: 0 auto;
  }

  .am-show-button:before {
    width: 10px;
    height: 10px;
    content: "";
    background: #fff;
    position: absolute;
    transform: rotate(45deg);
  }

  .am-show-button.-vertical:before {
    top: calc(50% - 5px);
    right: calc(100% - 5px);
  }

  .am-show-button.-horizontal:before {
    top: calc(100% - 5px);
    left: calc(50% - 5px);
  }

  #amasty-shopby-product-list .amshopby-overlay-block > .amshopby-loader {
    top: 30%;
    transform: translate(-50%, -30%);
  }

  .klarna-payments-method > .payment-method-title {
    padding: 11.5px 0;
  }

  .payment-method-braintree .cvv .field-tooltip {
    left: 6rem;
  }

  .braintree-paypal-account {
    width: 50%;
    border: 1px solid #ccc;
    border-width: 1px 0;
    display: inline-block;
  }

  .account .table-credit-cards .col.actions {
    width: 100px;
  }

  .block-category-event.block:last-child {
    margin-bottom: 30px;
    padding: 10px 0 30px;
  }

  .block-category-event .block-title {
    margin: 0;
  }

  .block-category-event .block-title strong {
    font-size: 2.4rem;
  }

  .block-category-event .ticker li {
    margin: 0 50px;
    display: none;
  }

  .block-category-event .ticker .value {
    font-size: 6rem;
  }

  .block-category-event .ticker .label {
    text-transform: none;
    font-size: 1.4rem;
  }

  .block-category-event .dates .date {
    font-size: 5rem;
  }

  .block-category-event .dates .start {
    padding-right: 50px;
  }

  .block-category-event .dates .start:after {
    font-size: 5rem;
    right: 10px;
  }

  .contact-index-index .column:not(.sidebar-additional) .form.contact {
    min-width: 600px;
  }

  .paypal-review .paypal-review-title {
    border-bottom: 1px solid #ccc;
  }

  .paypal-review .block-content .box-order-shipping-address, .paypal-review .block-content .box-order-shipping-method, .paypal-review .block-content .box-order-shipping-method + .box-order-billing-address {
    box-sizing: border-box;
    float: left;
    width: 33%;
  }

  .paypal-review .block-content .box-order-shipping-address {
    width: 34%;
    padding: 0 5%;
  }

  .paypal-review .table-paypal-review-items .col.price, .paypal-review .table-paypal-review-items .col.qty {
    text-align: center;
  }

  .paypal-review .table-paypal-review-items .col.item {
    width: 60%;
  }

  .paypal-review .col.subtotal, .paypal-review .mark, .paypal-review .amount {
    text-align: right;
  }

  .paypal-review-discount {
    border-top: 1px solid #ccc;
  }

  .paypal-review-discount .block {
    margin-bottom: 15px;
  }

  .paypal-review-discount .block.giftcard.active {
    padding-bottom: 40px;
  }

  .paypal-review-discount .block .title:after {
    margin-left: 10px;
    display: inline;
    position: static;
  }

  .paypal-review-discount .block .content {
    padding-bottom: 0;
  }

  .order-review-form .actions-toolbar > .primary {
    float: right;
  }

  .order-review-form .action.primary {
    margin-right: 0;
  }

  .block-cart-failed .secondary .action {
    margin: 0;
  }

  .block-cart-failed .actions.primary {
    float: right;
  }

  .bundle-actions .action.primary.customize {
    width: auto;
  }

  .bundle-options-container .legend.title {
    font-size: 40px;
  }

  .bundle-options-container .bundle-options-wrapper, .bundle-options-container .product-options-wrapper {
    float: left;
    width: 57%;
  }

  .bundle-options-container .block-bundle-summary {
    float: right;
    width: 40%;
    margin-top: 66px;
    padding: 10px 20px;
    position: relative;
  }

  .bundle-options-container .block-bundle-summary .price-box .price-wrapper, .bundle-options-container .block-bundle-summary .price-box .price-wrapper > .price {
    color: #575757;
    font-size: 36px;
    font-weight: 600;
    line-height: 36px;
  }

  .bundle-options-container .block-bundle-summary .price-container .weee {
    color: #575757;
  }

  .bundle-options-container .block-bundle-summary .price-container .weee .price {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .bundle-options-container .block-bundle-summary .price-including-tax + .price-excluding-tax .price {
    font-size: 1.4rem;
    line-height: 16px;
  }

  .bundle-options-container .block-bundle-summary .box-tocart .action.primary {
    width: auto;
    margin-right: 1%;
  }

  .bundle-options-container .block-bundle-summary .product-addto-links {
    text-align: left;
  }

  .page-layout-2columns-left .bundle-options-container .bundle-options-wrapper, .page-layout-2columns-left .bundle-options-container .block-bundle-summary, .page-layout-2columns-right .bundle-options-container .bundle-options-wrapper, .page-layout-2columns-right .bundle-options-container .block-bundle-summary, .page-layout-3columns .bundle-options-container .bundle-options-wrapper, .page-layout-3columns .bundle-options-container .block-bundle-summary {
    width: 48%;
  }

  .checkout-cart-index .page-title-wrapper, .checkout-cart-index .page.messages, .cart-container .form-cart {
    width: 70%;
    padding-right: 20px;
  }

  .cart-container .form-cart .actions.main {
    text-align: right;
  }

  .cart-container .widget {
    float: left;
  }

  .checkout-cart-index .header-right {
    z-index: 10;
    position: fixed;
    top: 30px;
    right: 20px;
  }

  .checkout-cart-index .header-right .authorization-link > a, .checkout-cart-index .header-right .my-account-link {
    background: #e62645;
  }

  .checkout-cart-index .header-right .authorization-link > a:hover, .checkout-cart-index .header-right .my-account-link:hover, .checkout-cart-index .header-right .authorization-link > a:focus, .checkout-cart-index .header-right .my-account-link:focus, .checkout-cart-index .header-right .authorization-link > a:active, .checkout-cart-index .header-right .my-account-link:active {
    background: #e43866;
  }

  .cart-summary {
    width: calc(30.0003% + 7.99988px);
    min-height: 100%;
    background: #1d252c;
    padding: 80px 20px 20px;
    position: fixed;
    top: 0;
    right: 0;
    box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b;
  }

  .cart-summary .summary.title {
    display: none;
  }

  .cart-summary .action.primary.checkout {
    padding: 20px 10px;
  }

  .cart .cart-item:not(:last-child) {
    margin-bottom: 40px;
  }

  .cart .parent-item-details {
    order: 1;
  }

  .cart .cart-item-buttons {
    min-width: 100%;
    order: 3;
    justify-content: center;
    margin: 10px 0;
  }

  .cart .table-columns {
    order: 2;
  }

  .cart .table-columns .table-column {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .cart .table-columns .table-column:after {
    min-width: 20px;
  }

  .cart.table-wrapper .items {
    min-width: 100%;
    width: auto;
  }

  .cart.table-wrapper tbody td {
    padding-top: 20px;
  }

  .cart.table-wrapper .item .col.item {
    padding: 20px 8px 20px 0;
  }

  .cart.table-wrapper .item-actions td {
    padding: 0;
  }

  .cart.table-wrapper .product-item-photo {
    max-width: 100%;
    vertical-align: top;
    width: 1%;
    padding-right: 20px;
    display: table-cell;
    position: static;
  }

  .cart.table-wrapper .product-item-details {
    vertical-align: top;
    white-space: normal;
    width: 99%;
    padding-bottom: 35px;
    display: table-cell;
  }

  .cart-products-toolbar {
    margin: 2px 0 0;
  }

  .cart-products-toolbar .toolbar-amount {
    margin: 0;
    line-height: 30px;
  }

  .cart-products-toolbar .pages {
    float: right;
  }

  .cart-products-toolbar .pages .item:last-child {
    margin-right: 0;
  }

  .cart.table-wrapper .cart-products-toolbar + .cart thead tr th.col {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .cart.table-wrapper .cart + .cart-products-toolbar {
    margin-top: 25px;
  }

  .cart-discount {
    width: 75%;
    float: left;
    box-sizing: border-box;
    border: 0;
    order: 1;
    padding-right: 4%;
  }

  .cart-discount .block .title:after {
    margin-left: 10px;
    display: inline;
    position: static;
  }

  .cart-discount .block.discount {
    width: auto;
  }

  .block.crosssell {
    width: 75%;
    float: left;
    order: 1;
    padding: 0 4% 0 0;
  }

  .block.crosssell .products-grid .product-item {
    width: 25%;
  }

  .minicart-wrapper {
    margin-right: 10px;
  }

  .minicart-wrapper .block-minicart {
    width: 390px;
  }

  .minicart-wrapper .action.showcart .counter-number, .minicart-wrapper .action.showcart .counter.qty {
    display: none;
  }

  .minilist .action.delete:before, .minicart-wrapper .action.edit:before {
    font-size: 16px;
    line-height: inherit;
  }

  .opc-main-wrapper.no-summary ol.opc li {
    margin-bottom: 40px;
  }

  .opc-main-wrapper > .opc-col-wrapper {
    flex-wrap: wrap;
    margin: 0 -10px;
    display: flex;
  }

  .opc-main-wrapper .summary-container, .opc-main-wrapper .sidebar-container {
    padding: 0 10px;
  }

  .opc-main-wrapper .summary-container {
    width: 60%;
  }

  .opc-main-wrapper .sidebar-container {
    width: 40%;
  }

  .opc-main-wrapper .minicart-items-wrapper {
    max-height: 1000px;
    margin: 0 0 40px;
  }

  .opc-wrapper {
    width: 100%;
  }

  .checkout-onepage-success .print {
    margin: 23px 0 0;
    display: block;
  }

  .opc-totals {
    margin-bottom: 40px;
  }

  .checkout-index-index .modal-popup .form-shipping-address {
    max-width: 100%;
  }

  .checkout-index-index .modal-popup .modal-footer .action-save-address {
    float: right;
    margin: 0 0 0 20px;
  }

  .checkout-shipping-method .actions-toolbar .action.primary {
    float: none;
    margin: 0;
  }

  .shipping-new-address-form {
    flex-wrap: wrap;
    margin: 0 -10px;
    display: flex;
  }

  .shipping-new-address-form > .field {
    width: 50%;
    padding: 0 10px;
  }

  .opc-wrapper .shipping-address-items {
    margin: 0 -10px -10px;
  }

  .opc-wrapper .shipping-address-item {
    width: calc(50% - 20px);
  }

  .opc-wrapper .new-address-popup {
    text-align: right;
  }

  .opc-wrapper .form-login, .opc-wrapper .form-shipping-address {
    max-width: 100%;
  }

  .opc-wrapper .form-login {
    border-bottom: none;
    padding-bottom: 20px;
  }

  .opc-block-shipping-information .shipping-information {
    margin-bottom: 40px;
  }

  .opc-block-shipping-information .shipping-information .ship-to, .opc-block-shipping-information .shipping-information .ship-via {
    width: 100%;
    margin: 0;
  }

  .opc-block-shipping-information .shipping-information .ship-to:last-child, .opc-block-shipping-information .shipping-information .ship-via:last-child {
    margin-bottom: 0;
  }

  .opc-block-shipping-information .shipping-information .ship-to {
    margin-bottom: 20px;
  }

  .opc-payment-top {
    margin: 0 -10px;
    display: flex;
  }

  .opc-payment-top > div {
    margin: 0 10px;
  }

  .opc-summary-wrapper .modal-header .action-close {
    display: none;
  }

  .opc-block-summary .table-totals {
    max-width: 320px;
  }

  .authentication-dropdown {
    transform-origin: 0 0;
    text-align: left;
    -webkit-transition: -webkit-transform .1s linear, visibility 0s linear .1s;
    visibility: hidden;
    width: 100%;
    background-color: #fff;
    border: 1px solid #aeaeae;
    transition: transform .1s linear, visibility 0s linear .1s;
    position: absolute;
    top: 100%;
    transform: scale(1, 0);
  }

  .authentication-dropdown._show {
    z-index: 100;
    -webkit-transition: -webkit-transform .1s linear, visibility linear;
    visibility: visible;
    transition: transform .1s linear, visibility linear;
    transform: scale(1);
  }

  .authentication-wrapper {
    width: 33.3333%;
    text-align: right;
  }

  .block-authentication .block-title {
    border-bottom: 0;
    margin: 0;
    font-size: 2.1rem;
  }

  .block-authentication .block-title h2 {
    margin: 0 0 20px;
  }

  .block-authentication .actions-toolbar > .primary {
    float: right;
    margin-right: 0;
    display: inline;
  }

  .block-authentication .actions-toolbar > .primary .action {
    margin-right: 0;
  }

  .block-authentication .actions-toolbar > .secondary {
    float: left;
  }

  .block-authentication .aw-ca-creat-wrapper {
    padding-top: 40px;
  }

  .popup-authentication .modal-header {
    z-index: 1;
    margin-bottom: -28px;
  }

  .popup-authentication .modal-content {
    padding: 0;
  }

  .popup-authentication .modal-inner-wrap {
    min-width: 700px;
    width: 90%;
    max-width: 1024px;
  }

  .popup-authentication .block-authentication {
    flex-direction: row;
    display: flex;
  }

  .popup-authentication .block[class], .popup-authentication .form-login, .popup-authentication .fieldset, .popup-authentication .block-content {
    flex-direction: column;
    flex-grow: 1;
    display: flex;
  }

  .popup-authentication .block[class] {
    box-sizing: border-box;
    float: left;
    width: 50%;
  }

  .popup-authentication .block[class] + .block {
    border-top: 0;
    margin: 0;
  }

  .popup-authentication .block[class] + .block:before {
    top: 50%;
    left: 0;
  }

  .popup-authentication .block-image {
    background-image: url("auth.2b7ead4d.jpg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .popup-authentication .block-customer-login {
    padding: 40px;
  }

  .checkout-payment-method .fieldset > .field-select-billing > .control {
    float: none;
    width: 100%;
  }

  .checkout-payment-method .payment-method-content .fieldset > .field {
    margin: 0 0 20px;
  }

  .checkout-payment-method .payment-method-content .fieldset > .field.choice:before {
    width: 0;
    padding: 0;
  }

  .checkout-payment-method .payment-method-content .fieldset > .field.type .control {
    margin-left: 25.8%;
  }

  .checkout-payment-method .payment-method-content .fieldset > .field.type.no-detection .control {
    margin-left: 0;
  }

  .checkout-billing-address .action-update {
    float: right;
  }

  .checkout-billing-address .actions-toolbar .action-cancel {
    margin: 6px 20px 0 0;
  }

  .checkout-payment-method .payment-option-title {
    padding: 0 0 10px;
  }

  .checkout-payment-method .payment-option-content .payment-option-inner + .actions-toolbar {
    margin-left: 0;
  }

  .storecredit .block-balance-history {
    width: 100%;
  }

  .table-balance-history .col {
    width: 25%;
  }

  .page-product-downloadable .product-options-wrapper {
    float: left;
    width: 55%;
  }

  .page-product-downloadable .product-options-bottom {
    float: right;
    width: 40%;
  }

  .page-product-giftcard .product-info-main .price-box .price-container.price-final {
    font-size: 21px;
  }

  .page-product-giftcard .product-info-main .price-box .price-container.price-final .price {
    font-size: 36px;
    line-height: 36px;
  }

  .page-product-giftcard .product-info-main .product-info-stock-sku {
    padding-bottom: 0;
  }

  .page-product-giftcard .product-info-main .product-reviews-summary {
    float: none;
  }

  .page-product-giftcard .product-add-form {
    margin-top: -20px;
  }

  .page-product-giftcard .product-add-form .field:not(.text) {
    box-sizing: border-box;
    width: 50%;
    vertical-align: top;
    margin: 0 0 20px;
    padding: 0 12px 0 0;
    display: inline-block;
  }

  .page-product-giftcard .product-add-form .field:not(.text) > .label {
    margin: 0 0 8px;
    display: inline-block;
  }

  .page-product-giftcard .product-add-form .field:not(.text):last-child {
    margin-bottom: 0;
  }

  .page-product-giftcard .product-add-form .field:not(.text) + .fieldset {
    clear: both;
  }

  .page-product-giftcard .product-add-form .field:not(.text) > .label {
    font-weight: 600;
  }

  .page-product-giftcard .product-add-form .field:not(.text) > .label + br {
    display: none;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .choice input {
    vertical-align: top;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .fields.group:before, .page-product-giftcard .product-add-form .field:not(.text) .fields.group:after {
    content: "";
    display: table;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .fields.group:after {
    clear: both;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .fields.group .field {
    box-sizing: border-box;
    float: left;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-2 .field {
    width: 50% !important;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-3 .field {
    width: 33.3% !important;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-4 .field {
    width: 25% !important;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-5 .field {
    width: 20% !important;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .addon {
    width: 100%;
    flex-wrap: nowrap;
    padding: 0;
    display: inline-flex;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .addon textarea, .page-product-giftcard .product-add-form .field:not(.text) .addon select, .page-product-giftcard .product-add-form .field:not(.text) .addon input {
    width: auto;
    flex-basis: 100%;
    order: 2;
    margin: 0;
    display: inline-block;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore, .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter {
    height: 32px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    background: #fff padding-box padding-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    order: 3;
    padding: 0 9px;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857;
    display: inline-block;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore:disabled, .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter:disabled {
    opacity: .5;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore::-webkit-input-placeholder, .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter::-webkit-input-placeholder {
    color: #575757;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore::-moz-placeholder, .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter::-moz-placeholder {
    color: #575757;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore:-ms-input-placeholder, .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter:-ms-input-placeholder {
    color: #575757;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore {
    float: left;
    order: 1;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .additional {
    margin-top: 10px;
  }

  .page-product-giftcard .product-add-form .field:not(.text).required > .label:after, .page-product-giftcard .product-add-form .field:not(.text)._required > .label:after {
    content: "*";
    color: #e02b27;
    margin: 0 0 0 5px;
    font-size: 1.2rem;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .note {
    margin: 3px 0 0;
    padding: 0;
    font-size: 1.2rem;
    text-decoration: none;
    display: inline-block;
  }

  .page-product-giftcard .product-add-form .field:not(.text) .note:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
    speak: none;
    text-align: center;
    font-family: "Font Awesome 5 Pro";
    font-size: 24px;
    font-weight: bold;
    line-height: 12px;
    display: inline-block;
    overflow: hidden;
  }

  .page-product-giftcard .product-add-form .field:not(.text):nth-child(2n+1) {
    padding-right: 25px;
  }

  .page-product-giftcard .product-add-form .field:not(.text):nth-child(2n) {
    padding-right: 0;
  }

  .page-product-giftcard .product-add-form .product-options-wrapper .field:not(.date) > .control {
    width: auto;
  }

  .gift-options {
    z-index: 1;
    position: relative;
  }

  .gift-options .actions-toolbar {
    clear: both;
    padding: 0;
    position: static;
  }

  .gift-options .actions-toolbar .secondary {
    float: right;
  }

  .gift-options .actions-toolbar .secondary .action {
    float: right;
    margin-left: 20px;
    margin-right: 0;
  }

  .gift-options .actions-toolbar .secondary .action-cancel {
    float: left;
    margin-top: 6px;
    display: block;
  }

  .gift-options .actions-toolbar:nth-child(3):before {
    content: "";
    width: 0;
    border-left: 1px solid #c1c1c1;
    display: block;
    position: absolute;
    top: 0;
    bottom: 5rem;
    left: 50%;
    overflow: hidden;
  }

  .gift-options-title {
    font-size: 1.8rem;
    font-weight: 300;
  }

  .gift-item-block .title {
    padding: 20px 0;
    font-size: 1.6rem;
  }

  .item-gift td {
    padding-left: 0;
    padding-right: 0;
  }

  .cart.table-wrapper .gift-options .actions-toolbar {
    clear: both;
    padding: 0;
    position: static;
  }

  .cart .action-gift {
    float: left;
  }

  .cart-container .cart-gift-item {
    margin-bottom: 20px;
  }

  .cart-container .cart-gift-item .gift-options, .cart-container .cart-gift-item .gift-summary {
    padding-bottom: 20px;
  }

  .form-giftregistry-share .fieldset .field, .form-giftregistry-edit .fieldset .field {
    width: 45%;
  }

  .form-giftregistry-edit .fieldset.shipping_address .fieldset {
    margin-top: 20px;
  }

  .table-giftregistry .col.create {
    white-space: nowrap;
  }

  .table-giftregistry .col.message {
    width: 40%;
  }

  .table-giftregistry .col.actions, .table-giftregistry-items .col.note {
    width: 30%;
  }

  .account .data.table.table-giftregistry-items > thead > tr > th, .account .data.table.table-giftregistry-items > tbody > tr > th, .account .data.table.table-giftregistry-items > tfoot > tr > th, .account .data.table.table-giftregistry-items > thead > tr > td, .account .data.table.table-giftregistry-items > tbody > tr > td, .account .data.table.table-giftregistry-items > tfoot > tr > td {
    border-top: 1px solid #ccc;
  }

  .account .data.table.table-giftregistry-items > caption + thead > tr:first-child > th, .account .data.table.table-giftregistry-items > colgroup + thead > tr:first-child > th, .account .data.table.table-giftregistry-items > thead:first-child > tr:first-child > th, .account .data.table.table-giftregistry-items > caption + thead > tr:first-child > td, .account .data.table.table-giftregistry-items > colgroup + thead > tr:first-child > td, .account .data.table.table-giftregistry-items > thead:first-child > tr:first-child > td {
    border-top: 0;
  }

  .account .data.table.table-giftregistry-items > tbody + tbody {
    border-top: 1px solid #ccc;
  }

  .account .data.table.table-giftregistry-items th {
    border-bottom: 0;
  }

  .account .data.table.table-giftregistry-items > tbody > tr:nth-child(2n) > td, .account .data.table.table-giftregistry-items > tbody > tr:nth-child(2n) > th {
    background: none;
  }

  .form-giftregistry-search .fieldset {
    margin-bottom: 29px;
  }

  .form-giftregistry-search .fieldset .field.name, .form-giftregistry-search .fieldset .field.lastname {
    box-sizing: border-box;
    width: 50%;
    vertical-align: top;
    margin-bottom: auto;
    padding: 0 12px 0 0;
    display: inline-block;
  }

  .form-giftregistry-search .fieldset .field.name + .fieldset, .form-giftregistry-search .fieldset .field.lastname + .fieldset {
    clear: both;
  }

  .form-giftregistry-search .fieldset .field.lastname {
    padding: 0;
  }

  .block-giftregistry-shared-items .item {
    border-top: 0;
    border-bottom: 1px solid #ccc;
  }

  .block-giftregistry-shared-items .product-item-photo {
    max-width: 100%;
    vertical-align: top;
    width: 1%;
    padding: 0 20px 0 0;
    display: table-cell;
  }

  .block-giftregistry-shared-items .product-item-details {
    vertical-align: top;
    width: 99%;
    word-break: normal;
    display: table-cell;
  }

  .block-giftregistry-shared-items .col.product {
    width: 48%;
  }

  .block-giftregistry-shared-items .col:not(.product) {
    text-align: center;
  }

  .block-giftregistry-shared-items .col.price {
    padding-top: 17px;
  }

  .block-giftregistry-shared-items .input-text.qty {
    margin-top: -4px;
  }

  .gift-options-cart-item .gift-wrapping, .cart-gift-item .gift-wrapping {
    box-sizing: border-box;
    float: left;
    width: 50%;
    padding-right: 20px;
  }

  .gift-options-cart-item .gift-wrapping + .gift-message, .cart-gift-item .gift-wrapping + .gift-message {
    box-sizing: border-box;
    float: left;
    width: 50%;
    border-left: 1px solid #c1c1c1;
    padding-left: 4.5rem;
  }

  .gift-options-cart-item .gift-summary .regular-price, .cart-gift-item .gift-summary .regular-price {
    white-space: nowrap;
  }

  .gift-options-cart-item .gift-wrapping-name, .cart-gift-item .gift-wrapping-name {
    padding-right: 20px;
  }

  .order-options .gift-wrapping, .table-order-review .gift-wrapping {
    max-width: 50%;
  }

  .page-product-grouped .product-info-price {
    float: none;
  }

  .page-product-grouped .minimal-price {
    margin-top: -8px;
  }

  .box-tocart .action.instant-purchase {
    width: 49%;
    margin-bottom: 0;
    margin-right: 1%;
  }

  .table-invitations .col {
    width: 50%;
  }

  .wishlist.window.popup {
    width: 380px;
    margin-left: -212px;
    inset: 20% auto auto 50%;
  }

  .block-wishlist-management {
    margin-bottom: 20px;
  }

  .block-wishlist-management .wishlist-select {
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 15px;
    display: table;
  }

  .block-wishlist-management .wishlist-select .wishlist-name {
    vertical-align: top;
    white-space: nowrap;
    width: 5%;
    margin-right: 10px;
    padding: 5px 10px 10px;
    display: table-cell;
  }

  .block-wishlist-management .wishlist-select-items {
    vertical-align: top;
    padding-right: 160px;
    display: table-cell;
  }

  .block-wishlist-management .wishlist-select-items .item {
    margin-right: 10px;
    padding: 5px 10px 10px;
    display: inline-block;
  }

  .block-wishlist-management .wishlist-select-items .item:last-child {
    margin-right: 0;
  }

  .block-wishlist-management .wishlist-select-items .current {
    border-bottom: 3px solid #4a4a4a;
    font-weight: 600;
  }

  .block-wishlist-management .wishlist-select .wishlist-name-current {
    display: none;
  }

  .block-wishlist-management .wishlist-add.item {
    position: absolute;
    top: 0;
    right: 0;
  }

  .block-wishlist-management .wishlist-title strong {
    font-size: 4rem;
  }

  .block-wishlist-management .wishlist-info {
    float: left;
  }

  .block-wishlist-management .wishlist-toolbar {
    float: right;
  }

  .block-wishlist-info-items .product-item-photo {
    margin-left: 0;
  }

  .products-grid.wishlist .product-item-checkbox {
    float: left;
  }

  .products-grid.wishlist .product-item-checkbox + .product-item-name {
    margin-left: 25px;
  }

  .block.newsletter {
    max-width: 44%;
    width: max-content;
  }

  .product-reviews-summary {
    margin-bottom: 15px;
  }

  .products.wrapper.list .product-reviews-summary {
    margin: 0;
  }

  .product-reviews-summary .reviews-actions {
    margin-top: 3px;
    font-size: 14px;
  }

  .form-create-return .fieldset .field {
    width: 45%;
  }

  .magento-rma-guest-returns .column.main .block.block-order-details-view .block-content:not(.widget) .box {
    clear: none;
    float: left;
    width: 25%;
  }

  .block-returns-tracking .block-title .action {
    margin: 0 0 0 30px;
  }

  .block-returns-tracking .block-title .actions-track {
    float: right;
    margin-top: 12px;
  }

  .form.send.friend {
    width: 60%;
  }

  .form.send.friend .fieldset .field {
    width: 45%;
  }

  .form.send.friend .fieldset .field.text {
    width: 90%;
  }

  .my-credit-cards .card-type img, .products-grid.wishlist .product-item-info:hover .product-item-inner {
    display: block;
  }

  .products-grid.wishlist .product-item-tooltip {
    display: inline-block;
  }

  .products-grid.wishlist .product-item-actions {
    margin: 10px 0 0;
  }

  .products-grid.wishlist .product-item-actions > * {
    margin-top: 7px;
    margin-bottom: 7px;
    display: inline-block;
  }

  .products-grid.wishlist .product-item .fieldset {
    display: table;
  }

  .products-grid.wishlist .product-item .fieldset .field.qty, .products-grid.wishlist .product-item .fieldset .product-item-actions {
    vertical-align: bottom;
    display: table-cell;
  }

  .products-grid.wishlist .product-item .fieldset .field.qty {
    padding-right: 10px;
  }

  .products-grid.wishlist .product-item .box-tocart .actions-primary {
    margin: 0;
  }

  .products-grid.wishlist .product-item .box-tocart .stock {
    margin: 20px 0 0;
  }

  .products-grid.wishlist .product-item .tocart, .wishlist-index-index .product-item-info {
    width: auto;
  }

  .wishlist-index-index .product-item-inner {
    z-index: 2;
    background: #fff;
    border: 1px solid #bbb;
    border-top: none;
    margin: 9px 0 0 -1px;
    padding: 0 9px 9px;
    position: absolute;
    left: 0;
    right: -1px;
    box-shadow: 3px 4px 4px #0000004d;
  }

  .wishlist-index-index .product-item-inner .comment-box {
    margin-top: -18px;
  }

  .aw-create-customer .aw-block-new-company {
    min-width: 600px;
    width: 50%;
  }

  .aw-ca-company-create .aw-ca__form .fieldset-wrapper {
    background: #fff;
    border-radius: 5px;
    margin-bottom: 40px;
    padding: 20px;
  }

  .aw-ca-company-create .aw-ca__form .fieldset-wrapper .fieldset > .field {
    width: 50%;
  }

  .aw-ca-company-create .aw-ca__form .fieldset-wrapper.consent-fieldset-wrapper {
    margin-bottom: 0;
    padding-top: 0;
  }

  .aw-ca-company-create .aw-ca__form .action-basic {
    margin-top: 0;
  }

  .aw-ca__form .fieldset-wrapper.consent-fieldset-wrapper {
    background: none;
  }

  .aw-ca__form .fieldset-wrapper.consent-fieldset-wrapper .fieldset > .field {
    width: 100%;
  }

  .product-items {
    margin-left: -20px;
    margin-right: -20px;
  }

  .product-item {
    width: 33%;
    padding: 0 20px 20px;
  }

  .product-item:hover .product-image-container, .product-item:focus .product-image-container, .product-item:focus-within .product-image-container {
    border-radius: 5px 0 0 5px;
  }

  .product-item:hover .product-price-list-container, .product-item:focus .product-price-list-container, .product-item:focus-within .product-price-list-container {
    display: block;
  }

  .product-item-actions .action {
    max-width: 130px;
  }

  .product-item-actions .action, .product-item-actions .action.view {
    margin: 5px;
  }

  .product-item-actions .action.tocart {
    min-width: 148px;
  }

  .product-item .product-price-list-availability {
    display: none;
  }

  .page-products .columns {
    z-index: 1;
    padding-top: 0;
    position: relative;
  }

  .toolbar-amount {
    float: left;
    display: block;
    position: static;
  }

  .products.wrapper ~ .toolbar .pages {
    margin-bottom: 0;
  }

  .modes {
    float: left;
    margin-right: 20px;
    display: inline-block;
  }

  .products.wrapper ~ .toolbar .modes {
    display: none;
  }

  .modes-mode {
    color: #5e5e5e;
    float: left;
    text-align: center;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-right: 0;
    padding: 7px 10px;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
    display: inline-block;
    box-shadow: inset 0 1px #fff, inset 0 -1px #cccccc4d;
  }

  .modes-label + .modes-mode {
    border-radius: 3px 0 0 3px;
  }

  .modes-mode:hover {
    color: #5e5e5e;
    background: #ebebeb;
  }

  .modes-mode:last-child {
    border-right: 1px solid #ccc;
    border-radius: 0 3px 3px 0;
  }

  .modes-mode.active {
    color: #9e9e9e;
    background: #dedede;
    box-shadow: inset 0 1px #cccc, inset 0 -1px #cccccc4d;
  }

  .modes-mode > span {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .modes-mode:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: inherit;
    color: #7d7d7d;
    content: "";
    vertical-align: middle;
    speak: none;
    text-align: center;
    font-family: "Font Awesome 5 Pro";
    font-weight: bold;
    display: inline-block;
    overflow: hidden;
  }

  .modes-mode:hover:before {
    color: #7d7d7d;
  }

  .page-products .sorter {
    position: static;
  }

  .mode-list:before {
    content: "";
  }

  .product-info-main .page-title-wrapper h1 {
    margin-bottom: 15px;
  }

  .product-info-main .product-add-form {
    padding-top: 20px;
  }

  .product-info-top {
    max-width: 1440px;
    flex-wrap: wrap;
  }

  .product-info-top > .page-title-wrapper {
    flex-basis: 100%;
  }

  .product-info-top > .product-info-main {
    padding-left: 20px;
  }

  .product-info-top > .product.media {
    padding-right: 20px;
  }

  .product-social-links {
    text-align: left;
  }

  .product-options-bottom .price-box .price-container, .product-info-price .price-box .price-container {
    font-size: 21px;
  }

  .product-options-bottom .price-box .price-container .price, .product-info-price .price-box .price-container .price {
    font-size: 36px;
    line-height: 36px;
  }

  .product-options-bottom .price-box .price-including-tax + .price-excluding-tax .price, .product-info-price .price-box .price-including-tax + .price-excluding-tax .price {
    font-size: 1.4rem;
    line-height: 16px;
  }

  .product.media .gallery-placeholder {
    max-width: 450px;
    margin: 0 auto;
  }

  .page-layout-1column .product-info-main {
    width: 50%;
  }

  .page-layout-1column .product.media {
    width: 50%;
    max-width: 600px;
  }

  .page-layout-2columns-left .product-info-main, .page-layout-2columns-right .product-info-main, .page-layout-3columns .product-info-main {
    width: 48%;
  }

  .page-layout-2columns-left .product.media, .page-layout-2columns-right .product.media, .page-layout-3columns .product.media {
    width: 50%;
  }

  .product-add-form .product-options-wrapper .field .control {
    width: 80%;
  }

  .sidebar .product-items .product-item-info .product-item-photo {
    float: left;
    margin: 0 10px 10px 0;
    position: relative;
    top: auto;
    left: auto;
  }

  .sidebar .product-items .product-item-details {
    margin: 0;
  }

  .sidebar .product-items .product-item-actions {
    clear: left;
  }

  .catalog-category-view.page-layout-1column .column.main {
    min-height: inherit;
  }

  .compare.wrapper {
    float: right;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .compare.wrapper .action.compare {
    color: #333;
    line-height: 32px;
    text-decoration: none;
  }

  .compare.wrapper .action.compare:visited {
    color: #333;
    text-decoration: none;
  }

  .compare.wrapper .action.compare:hover, .compare.wrapper .action.compare:active {
    color: #333;
    text-decoration: underline;
  }

  .compare.wrapper .counter.qty {
    color: #7d7d7d;
  }

  .compare.wrapper .counter.qty:before {
    content: "(";
  }

  .compare.wrapper .counter.qty:after {
    content: ")";
  }

  .product-content-table th, .product-content-table td {
    padding: 20px;
  }

  .product-content-table tr th {
    width: 200px;
  }

  .block-search {
    width: 360px;
    padding-left: 15px;
  }

  .block-search .control {
    border-top: 0;
    margin: 0;
    padding: 0;
  }

  .block-search input {
    height: 40px;
    border-radius: 20px;
    margin: 0;
    padding: 0 40px 0 35px;
    font-size: 16px;
    position: static;
  }

  .block-search input::-webkit-input-placeholder {
    color: #575757;
  }

  .block-search input:-moz-placeholder {
    color: #575757;
  }

  .block-search input::-moz-placeholder {
    color: #575757;
  }

  .block-search input:-ms-input-placeholder {
    color: #575757;
  }

  .block-search .search-tooltip {
    left: 10px;
  }

  .block-search .search-tooltip > a {
    text-decoration: none;
    display: inline-block;
  }

  .block-search .search-tooltip > a > span {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .block-search .search-tooltip > a:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    content: "";
    vertical-align: top;
    speak: none;
    text-align: center;
    margin: 0;
    font-family: "Font Awesome 5 Pro";
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
    display: inline-block;
    overflow: hidden;
  }

  .block-search .search-tooltip > a:hover:before, .block-search .search-tooltip > a:active:before {
    color: #333;
  }

  .block-search .search-tooltip > a:before {
    font-weight: bold;
  }

  .block-search .action.search {
    text-decoration: none;
    display: inline-block;
    right: 15px;
  }

  .block-search .action.search > span {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .block-search .action.search:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #e62645;
    content: "";
    vertical-align: top;
    speak: none;
    text-align: center;
    margin: 0;
    font-family: "Font Awesome 5 Pro";
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;
    display: inline-block;
    overflow: hidden;
  }

  .block-search .action.search:hover:before, .block-search .action.search:active:before {
    color: #e43866;
  }

  .block-search .action.search:before {
    font-weight: bold;
  }

  .search-autocomplete {
    margin-top: 0;
  }

  .search-autocomplete, .search-autocomplete ul:not(:empty) {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .search-autocomplete ul li {
    padding: 10px 50px 10px 20px;
  }

  .search-autocomplete ul li .amount {
    top: 10px;
    right: 20px;
  }

  .search-links {
    padding-left: 10px;
  }

  .login-container {
    width: 100%;
    max-width: 400px;
    border: 1px solid #cecece;
    margin: 20px auto;
  }

  .login-container-left {
    width: 30%;
  }

  .login-container-right {
    width: 70%;
    padding: 40px;
  }

  .login-container .block.login .actions-toolbar > .primary {
    margin-bottom: 0;
    margin-right: 30px;
  }

  .login-container .block.login .actions-toolbar > .secondary {
    float: left;
  }

  .form-create-account .fieldset-fullname .fields {
    width: 100%;
    display: table;
  }

  .form-create-account .fieldset-fullname .fields .field {
    display: table-cell;
  }

  .form-create-account .fieldset-fullname .fields .field + .field {
    padding-left: 10px;
  }

  .form-create-account .fieldset-fullname .field-name-prefix, .form-create-account .fieldset-fullname .field-name-suffix {
    width: 50px;
  }

  .form.password.reset, .form.send.confirmation, .form.password.forget, .form.create.account, .form.search.advanced, .form.form-orders-search {
    min-width: 600px;
    width: 50%;
  }

  .account.page-layout-2columns-left .sidebar-main, .account.page-layout-2columns-left .sidebar-additional {
    width: 22.3%;
  }

  .account.page-layout-2columns-left .column.main {
    width: 77.7%;
  }

  .account.page-layout-2columns-left .sidebar-main .block {
    margin-bottom: 0;
  }

  .account .column.main .block:not(.widget) {
    margin-bottom: 40px;
  }

  .account .data.table {
    margin-bottom: 0;
  }

  .account .data.table .col.actions {
    white-space: nowrap;
  }

  .block-addresses-list .items.addresses {
    font-size: 0;
  }

  .block-addresses-list .items.addresses > .item {
    vertical-align: top;
    width: 48%;
    margin-bottom: 20px;
    font-size: 14px;
    display: inline-block;
  }

  .block-addresses-list .items.addresses > .item:nth-last-child(1), .block-addresses-list .items.addresses > .item:nth-last-child(2) {
    margin-bottom: 0;
  }

  .block-addresses-list .items.addresses > .item:nth-child(2n) {
    margin-left: 4%;
  }

  .page-main .block {
    margin-bottom: 20px;
  }

  .form-edit-account .fieldset .fieldset {
    width: 100%;
    margin-bottom: 20px;
  }

  .control.captcha-image .captcha-img {
    margin: 0 10px 10px 0;
  }

  .order-products-toolbar .pager, .customer-addresses-toolbar .pager {
    border-top: 1px solid #ccc;
    padding: 10px 0;
  }

  .filter.block {
    margin-bottom: 40px;
  }

  .filter-title {
    display: none;
  }

  .filter-content .item {
    margin: 10px 0;
  }

  .filter-actions {
    margin-bottom: 30px;
  }

  .filter.active .filter-options, .filter-options {
    clear: both;
    overflow: initial;
    background: #f2f2f2;
    display: block;
    position: static;
  }

  .filter-subtitle {
    display: block;
    position: static;
  }

  .page-layout-1column .toolbar-products {
    width: 100%;
    position: absolute;
    top: 0;
  }

  .page-layout-1column .products ~ .toolbar-products {
    position: static;
  }

  .page-layout-1column.page-with-filter .column.main {
    z-index: 1;
    padding-top: 45px;
    position: relative;
  }

  .page-layout-1column .filter.block {
    border-top: 1px solid #ccc;
  }

  .page-layout-1column .filter-content {
    margin-top: 10px;
  }

  .page-layout-1column .filter-subtitle {
    display: none;
  }

  .page-layout-1column .filter-options-item {
    border: 0;
    margin-right: 25px;
    display: inline-block;
    position: relative;
  }

  .page-layout-1column .filter-options-item.active {
    z-index: 2;
  }

  .page-layout-1column .filter-options-item.active .filter-options-content {
    visibility: visible;
  }

  .page-layout-1column .filter-options-item.active:hover {
    z-index: 3;
  }

  .page-layout-1column .filter-options-item.active:after, .page-layout-1column .filter-options-item.active:before {
    height: 0;
    width: 0;
    content: "";
    z-index: 3;
    border: 8px solid #0000;
    border-bottom-color: #000;
    display: block;
    position: absolute;
    bottom: -1px;
    left: 5px;
  }

  .page-layout-1column .filter-options-item.active:after {
    z-index: 4;
    border-bottom-color: #fff;
    margin-top: 2px;
  }

  .page-layout-1column .filter-options-title {
    padding: 0 20px 0 0;
  }

  .page-layout-1column .filter-options-title:after {
    z-index: 3;
    top: 3px;
    right: 2px;
  }

  .page-layout-1column .filter-options-content {
    -ms-box-shadow: 0 3px 5px 0 #323232bf;
    visibility: hidden;
    width: 180px;
    z-index: 2;
    background: #fff;
    border: 1px solid #ccc;
    padding: 5px 0;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 5px #323232bf;
  }

  .page-layout-1column .filter-options-content .item {
    margin: 0;
    padding: 5px;
  }

  .page-layout-1column .filter-options-content .item a {
    margin-left: 0;
  }

  .page-layout-1column .filter-options-content .item:hover {
    background-color: #e8e8e8;
  }

  .page-layout-1column .filter-current {
    line-height: 35px;
    display: inline;
  }

  .page-layout-1column .filter-current-subtitle {
    color: #7d7d7d;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    display: inline;
  }

  .page-layout-1column .filter-current-subtitle:after {
    content: ":";
  }

  .page-layout-1column .filter-current .item, .page-layout-1column .filter-current .items {
    display: inline;
  }

  .page-layout-1column .filter-current .item {
    white-space: nowrap;
    margin-right: 25px;
  }

  .page-layout-1column .filter-current .action.remove {
    line-height: normal;
  }

  .page-layout-1column .filter-actions {
    white-space: nowrap;
    display: inline;
  }

  .page-layout-1column .filter-actions ~ .filter-options {
    margin-top: 25px;
  }

  .order-links {
    margin: 0 0 1px;
  }

  .order-links .item {
    float: left;
    margin: 0 -1px -1px 0;
  }

  .order-links .item a {
    padding: 1px 20px;
  }

  .order-links .item strong {
    border-bottom: 0;
    margin-bottom: -1px;
    padding: 1px 20px 2px;
  }

  .order-actions-toolbar .action.print {
    float: right;
    display: block;
  }

  .account .column.main .block.block-order-details-view .block-content:not(.widget) .box, [class^="sales-guest-"] .column.main .block.block-order-details-view .block-content:not(.widget) .box, .sales-guest-view .column.main .block.block-order-details-view .block-content:not(.widget) .box {
    clear: none;
    float: left;
    width: 25%;
  }

  .block-order-details-comments {
    margin: 0 0 60px;
  }

  .block-order-details-comments .comment-date {
    clear: left;
    float: left;
    max-width: 90px;
    margin-right: 50px;
  }

  .block-order-details-comments .comment-content {
    overflow: hidden;
  }

  .order-details-items {
    margin-top: -1px;
    padding: 25px;
  }

  .order-details-items .col.price {
    text-align: center;
  }

  .order-details-items .col.subtotal {
    text-align: right;
  }

  .order-details-items tbody td {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .order-details-items tfoot .amount, .order-details-items tfoot .mark {
    text-align: right;
  }

  .order-details-items.ordered .order-title {
    display: none;
  }

  .order-pager-wrapper .order-pager-wrapper-top {
    padding-left: 0;
    padding-right: 0;
  }

  .order-pager-wrapper.order-pager-wrapper-top {
    display: none;
  }

  .order-pager-wrapper .toolbar-amount {
    position: relative;
  }

  .order-pager-wrapper .pages {
    float: right;
  }

  .table-order-items tbody .col.label, .table-order-items tbody .col.value {
    padding-left: 0;
  }

  .table-order-items.invoice .col.qty, .table-order-items.shipment .col.qty, .table-order-items.creditmemo .col.qty, .table-order-items.creditmemo .col.discount, .table-order-items.creditmemo .col.subtotal {
    text-align: center;
  }

  .table-order-items.creditmemo .col.total {
    text-align: right;
  }

  html, body {
    height: 100%;
  }

  .navigation ul {
    padding: 0 8px;
  }

  .page-header {
    border: 0;
    margin-bottom: 0;
  }

  .page-header .panel.wrapper {
    background-color: #6e716e;
    border-bottom: 1px solid #e8e8e8;
  }

  .page-header .header.panel {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .page-header .switcher {
    float: right;
    order: 1;
    margin-left: 15px;
    margin-right: -6px;
  }

  .page-main > .page-title-wrapper .page-title {
    display: inline-block;
  }

  .page-main > .page-title-wrapper .page-title + .action {
    float: right;
    margin-top: 20px;
  }

  .customer-welcome .action.switch {
    -moz-box-sizing: content-box;
    box-shadow: none;
    line-height: inherit;
    text-shadow: none;
    color: #fff;
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
    font-weight: 400;
    text-decoration: none;
  }

  .customer-welcome .action.switch:focus, .customer-welcome .action.switch:hover, .customer-welcome .action.switch:active {
    background: none;
    border: none;
  }

  .customer-welcome .action.switch.disabled, .customer-welcome .action.switch[disabled], fieldset[disabled] .customer-welcome .action.switch {
    pointer-events: none;
    opacity: .5;
  }

  .customer-welcome.active .action.switch:after {
    content: "";
  }

  .customer-welcome.active .customer-menu {
    display: block;
  }

  .customer-welcome .greet {
    display: none;
  }

  .header.panel > .header.links {
    float: right;
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
  }

  .header.panel > .header.links > li {
    vertical-align: top;
    margin: 0 0 0 15px;
    display: inline-block;
  }

  .header.panel > .header.links > li.welcome, .header.panel > .header.links > li > a {
    line-height: 1.4;
    display: inline-block;
  }

  .header.panel > .header.links > li.welcome a {
    color: #fff;
    padding-left: 5px;
  }

  .header.panel > .header.links > .authorization-link:after {
    content: attr(data-label);
    margin: 0 -5px 0 5px;
    display: inline-block;
  }

  .header.panel > .header.links > .authorization-link:last-child:after, .header.panel > .header.links > .customer-welcome + .authorization-link {
    display: none;
  }

  .header.content {
    align-items: flex-start;
    padding: 20px;
  }

  .header.content .header-right {
    order: 3;
  }

  .header.content .header-search {
    order: 2;
  }

  .logo {
    width: 82px;
  }

  .logo img {
    max-height: inherit;
  }

  .authorization-link > a {
    color: #fff;
    cursor: pointer;
    box-sizing: border-box;
    vertical-align: middle;
    background: #e62645;
    border: 0;
    border-radius: 5px;
    padding: 10px 15px;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.6rem;
    text-decoration: none;
    display: inline-block;
  }

  .authorization-link > a:hover, .authorization-link > a:active, .authorization-link > a:focus {
    text-decoration: none;
  }

  .authorization-link > a:visited {
    color: #fff;
  }

  .authorization-link > a:focus, .authorization-link > a:hover, .authorization-link > a:active {
    color: #fff;
    background: #e43866;
    border: 0;
  }

  .authorization-link > a.disabled, .authorization-link > a[disabled], fieldset[disabled] .authorization-link > a {
    opacity: .5;
    cursor: default;
    pointer-events: none;
  }

  .authorization-link > a:visited {
    color: #fff;
  }

  .my-account-link {
    color: #fff;
    cursor: pointer;
    box-sizing: border-box;
    vertical-align: middle;
    background: #e62645;
    border: 0;
    border-radius: 5px;
    padding: 10px 15px;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.6rem;
    text-decoration: none;
    display: inline-block;
  }

  .my-account-link:hover, .my-account-link:active, .my-account-link:focus {
    text-decoration: none;
  }

  .my-account-link:visited {
    color: #fff;
  }

  .my-account-link:focus, .my-account-link:hover, .my-account-link:active {
    color: #fff;
    background: #e43866;
    border: 0;
  }

  .my-account-link.disabled, .my-account-link[disabled], fieldset[disabled] .my-account-link {
    opacity: .5;
    cursor: default;
    pointer-events: none;
  }

  .my-account-link:visited {
    color: #fff;
  }

  .page-wrapper {
    margin: 0;
    transition: margin .3s ease-out;
    position: relative;
  }

  .page-wrapper > .breadcrumbs, .page-wrapper > .top-container, .page-wrapper > .widget {
    box-sizing: border-box;
    width: 100%;
  }

  .page-footer .switcher .options ul.dropdown {
    margin: 0 0 0 20px;
    top: auto;
    bottom: -10px;
    left: 100%;
  }

  .page-footer .switcher .options ul.dropdown:before, .page-footer .switcher .options ul.dropdown:after {
    inset: auto 100% 13px auto;
  }

  .page-footer .switcher .options ul.dropdown:before {
    border-color: #0000 #fff #0000 #0000;
  }

  .page-footer .switcher .options ul.dropdown:after {
    border-color: #0000 #bbb #0000 #0000;
    margin: 0 0 -1px -1px;
  }

  .footer-row {
    border-top: 1px solid #ccc;
    justify-content: space-between;
    align-items: center;
  }

  .footer-column {
    padding: 0 10px;
  }

  .footer-top {
    padding-left: env(safe-area-inset-left);
  }

  .footer-top-content {
    margin-left: 40px;
    padding-left: 0;
    position: relative;
  }

  .footer-top-content:before, .footer-top-content:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
  }

  .footer-top-content:before {
    border-width: 0 0 41px 41px;
    border-color: #0000 #0000 #ccc;
    top: -1px;
    left: -41px;
  }

  .footer-top-content:after {
    border-width: 0 0 40px 40px;
    border-color: #0000 #0000 #f2f2f2;
    left: -40px;
  }

  .footer-top-content > .footer-column:first-child > .footer-switcher > li:first-child > span, .footer-top-content > .footer-column:first-child > .footer-switcher > li:first-child > a {
    padding-left: 0;
  }

  .footer-logo {
    padding: 0 20px;
  }

  .footer-links > li:not(:last-child) a:after, .footer-links > li:not(:last-child) span:after {
    content: "|";
    width: 2px;
    font-weight: normal;
    position: absolute;
    top: 5px;
    right: 0;
  }

  .cms-no-route .page-main {
    text-align: center;
  }

  .cms-no-route .page-title-wrapper:before {
    content: "";
    width: 150px;
    height: 150px;
    background-image: url("warning.95afc693.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto 20px;
    display: block;
  }

  .cms-no-route .primary.action {
    margin-top: 20px;
  }

  .block.widget .products-grid .product-item {
    width: 33.3333%;
  }

  .sidebar .block.widget .products-grid .product-item {
    width: 100%;
    margin-left: 0;
  }

  .sidebar .block.widget .products-grid .product-item .actions-secondary {
    padding: 10px 0;
    display: block;
  }

  .page-layout-1column .block.widget .products-grid .product-item {
    width: 23.5%;
    margin-left: 2%;
  }

  .page-layout-1column .block.widget .products-grid .product-item:nth-child(3n+1) {
    margin-left: 2%;
  }

  .page-layout-1column .block.widget .products-grid .product-item:nth-child(4n+1) {
    margin-left: 0;
  }

  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 50%;
  }

  .sidebar .block.widget .pager .pages-item-next {
    padding: 0;
  }

  .sidebar .block.widget .pager .pages-item-next .action {
    margin: 0;
  }

  body.no-scroll-desktop {
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
  }

  .cms-index-index .page.messages, .sketcher-index-index .page.messages {
    padding-left: 20px;
    padding-right: 20px;
  }

  .homepage-logo {
    width: 200px;
  }

  .homepage-search .product-search {
    width: 600px;
  }

  .homepage-search .product-search-input-wrapper {
    margin: 30px 0 20px;
  }

  .homepage-search .search-tooltip {
    left: 12px;
  }

  .homepage-search .search-tooltip > a {
    text-decoration: none;
    display: inline-block;
  }

  .homepage-search .search-tooltip > a > span {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .homepage-search .search-tooltip > a:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    content: "";
    vertical-align: top;
    speak: none;
    text-align: center;
    margin: 0;
    font-family: "Font Awesome 5 Pro";
    font-size: 14px;
    font-weight: bold;
    line-height: 35px;
    display: inline-block;
    overflow: hidden;
  }

  .homepage-search .search-tooltip > a:hover:before, .homepage-search .search-tooltip > a:active:before {
    color: #333;
  }

  .homepage-search input {
    height: 48px;
    border-radius: 24px;
    padding: 0 44px;
  }

  .homepage-search .search-autocomplete, .homepage-search .search-autocomplete ul:not(:empty) {
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
  }

  .homepage-search .action.search {
    text-decoration: none;
    display: inline-block;
    right: 15px;
  }

  .homepage-search .action.search > span {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .homepage-search .action.search:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #e62645;
    content: "";
    vertical-align: top;
    speak: none;
    text-align: center;
    margin: 0;
    font-family: "Font Awesome 5 Pro";
    font-size: 18px;
    font-weight: bold;
    line-height: 48px;
    display: inline-block;
    overflow: hidden;
  }

  .homepage-search .action.search:hover:before, .homepage-search .action.search:active:before {
    color: #e43866;
  }

  .homepage-search-buttons .home-search-button, .homepage-search-buttons .home-search-button-primary {
    padding: 10px 15px;
  }

  .sketcher-search-container {
    height: 480px;
  }

  .sketcher-buttons {
    padding: 20px 0;
  }

  .sketcher-buttons .sketcher-button {
    position: relative;
  }

  .sketcher-buttons .sketcher-button .tooltip-content {
    max-width: 360px;
    min-width: 210px;
    z-index: 100;
    text-align: left;
    color: #333;
    background: #fff;
    border: 1px solid #bbb;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 12px 16px;
    line-height: 1.4;
    display: none;
    position: absolute;
    bottom: 100%;
    left: 0;
  }

  .sketcher-buttons .sketcher-button .tooltip-content:after, .sketcher-buttons .sketcher-button .tooltip-content:before {
    content: "";
    height: 0;
    width: 0;
    border: solid #0000;
    position: absolute;
  }

  .sketcher-buttons .sketcher-button .tooltip-content:after {
    border-width: 5px;
    border-color: #0000;
  }

  .sketcher-buttons .sketcher-button .tooltip-content:before {
    border-width: 6px;
    border-color: #0000;
  }

  .sketcher-buttons .sketcher-button .tooltip-content:after, .sketcher-buttons .sketcher-button .tooltip-content:before {
    top: 100%;
  }

  .sketcher-buttons .sketcher-button .tooltip-content:after {
    border-top-color: #fff;
    margin-left: -5px;
    left: 15px;
  }

  .sketcher-buttons .sketcher-button .tooltip-content:before {
    border-top-color: #bbb;
    margin-left: -6px;
    left: 15px;
  }

  .sketcher-buttons .sketcher-button .tooltip-toggle {
    cursor: help;
  }

  .sketcher-buttons .sketcher-button .tooltip-toggle:hover + .tooltip-content, .sketcher-buttons .sketcher-button .tooltip-toggle:focus + .tooltip-content, .sketcher-buttons .sketcher-button:hover .tooltip-content {
    display: block;
  }

  .sketcher-buttons .sketcher-button .tooltip-content {
    min-width: auto;
    width: 210px;
    left: calc(50% - 105px);
  }

  .sketcher-buttons .sketcher-button .tooltip-content:before, .sketcher-buttons .sketcher-button .tooltip-content:after {
    left: 50%;
  }

  .conceptSearch-wrapper {
    max-width: 1280px;
    border-radius: 5px;
    margin: 0 auto;
    padding: 10px;
  }

  .conceptSearch-wrapper:not(.conceptSearch-wrapper--loading) {
    border: 1px solid #ccc;
  }

  .conceptSearch-table thead th {
    padding: 0 10px 10px;
    font-size: 1.6rem;
  }

  .conceptSearch-table th:first-child, .conceptSearch-table td:first-child {
    padding-left: 0;
  }

  .conceptSearch-table th:last-child, .conceptSearch-table td:last-child {
    padding-right: 0;
  }

  .conceptSearch-table tbody td {
    vertical-align: top;
    padding: 10px;
    font-size: 1.5rem;
  }

  .conceptSearch-table tbody td:first-child {
    font-size: 1.8rem;
  }

  .conceptSearch-paging-container {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    display: flex;
  }

  .dataTables_length {
    text-align: center;
  }

  .dataTables_length label {
    padding-right: .36em;
  }

  .dataTables_paginate {
    text-align: center;
    margin-right: -10px;
  }

  .conceptSearch-buttons {
    padding: 10px 0;
  }

  .conceptSearch-selected:not(:empty) {
    border-top: 1px solid #ccc;
    margin-top: 10px;
    margin-bottom: -10px;
  }

  .conceptSearch-selected ul {
    margin: 0 -5px;
    padding-bottom: 5px;
  }

  .conceptSearch-selected ul li {
    padding: 5px 10px 5px 5px;
  }

  .conceptSearch-results-mini {
    height: 40px;
    border-radius: 20px;
    margin: 0;
    padding: 0 9px;
    font-size: 16px;
    position: static;
  }

  .conceptSearch-results-mini i {
    margin-right: 6px;
    font-size: 20px;
    line-height: 38px;
  }

  .conceptSearch-sidebar {
    min-width: 250px;
    flex-basis: 250px;
    margin-right: 20px;
  }

  .conceptSearch-heads .conceptSearch-head > span {
    min-width: 250px;
  }

  .conceptSearch-heads .conceptSearch-head-name {
    min-width: 75px;
  }

  .conceptSearch-heads .conceptSearch-head img {
    width: 50px;
    height: 50px;
  }

  .conceptSearch-heads .conceptSearch-head-count {
    min-width: 95px;
  }

  .conceptSearch-heads .conceptSearch-head-image-popout {
    display: block;
  }

  .conceptSearch-results-selected {
    position: sticky;
    top: 20px;
  }

  .conceptSearch-results-selected-toggle {
    display: none;
  }

  .conceptSearch-results-selected ul {
    max-height: 400px;
  }

  .conceptSearch-results-selected-stats {
    margin-top: 3rem;
  }

  .conceptSearch-main {
    max-width: calc(100% - 270px);
  }

  .conceptSearch-grid {
    margin-left: -10px;
    margin-right: -10px;
  }

  .conceptSearch-grid-molecule {
    max-width: 300px;
    flex-basis: 33.33%;
    padding: 0 10px 20px;
  }

  .conceptSearch-grid-molecule:hover .conceptSearch-grid-molecule-image {
    border-radius: 5px 0 0 5px;
  }

  .conceptSearch-grid-molecule:hover .conceptSearch-grid-molecule-info-container {
    display: block;
  }

  .conceptSearch-grid-molecule-name {
    margin-bottom: 5px;
    font-size: 1.8rem;
  }

  .conceptSearch-list-wrapper {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0 auto;
    padding: 10px;
  }

  .conceptSearch-molecule-modal-image {
    width: 40%;
  }

  .conceptSearch-molecule-modal-image img {
    max-height: 200px;
  }

  .conceptSearch-molecule-modal-options {
    width: 60%;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .conceptSearch-compare {
    width: 100vw;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    left: 50%;
    right: 50%;
  }

  .conceptSearch-compare-header {
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .conceptSearch-compare-table th, .conceptSearch-compare-table-radar {
    position: sticky;
    left: 0;
  }

  .conceptSearch-compare-molecule-remove {
    display: none;
  }

  .footer-row {
    padding-right: 70px;
  }

  .number-input {
    justify-content: flex-end;
    display: inline-flex;
  }

  .number-input-prepend, .number-input-append {
    cursor: pointer;
    text-transform: none;
    background-color: #0000;
    padding: .5rem .75rem;
    overflow: visible;
  }

  .number-input-prepend {
    color: #333;
    background-color: #e0e1e2;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .number-input-prepend:hover, .number-input-prepend:focus {
    background-color: #cbcdce;
  }

  .number-input-prepend:active {
    background-color: #bec0c2;
  }

  .number-input-prepend:focus, .number-input-prepend:active {
    box-shadow: 0 0 0 .125rem #f2f2f2;
  }

  .number-input-append {
    color: #fff;
    background-color: #e62645;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .number-input-append:hover, .number-input-append:focus, .number-input-append:active {
    background-color: #e43866;
  }

  .number-input-append:focus, .number-input-append:active {
    box-shadow: 0 0 0 .125rem #eb6919;
  }

  .number-input-field[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
    width: 25%;
    min-width: 4rem;
    background-color: #0000;
    border: 1px solid #e0e1e2;
    border-width: 1px 0;
    border-radius: 0;
    padding: .5rem .75rem;
  }

  .number-input-field[type="number"]::-webkit-inner-spin-button, .number-input-field[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@media (min-width: 1024px), print {
  .ambrands-search-wrapper {
    float: right;
    width: 20%;
  }

  .brand-images .brands-letter {
    width: inherit;
    padding-right: 35px;
  }

  .brand-images .brands-letter .brand-item {
    margin: 0 10px 20px;
  }

  .amslider .slide {
    max-width: 32%;
    flex-basis: auto;
    margin: 0 20px 20px 0;
  }

  .ambrands-list-popup {
    width: 600px;
    height: 450px;
    opacity: 0;
    pointer-events: none;
    background: #fff;
    transition: all .5s;
    position: absolute;
    box-shadow: 0 0 1px 1px #ccc;
  }

  .ambrands-menu-item:hover .ambrands-list-popup, .ammenu-item:hover .ambrands-list-popup {
    opacity: 1;
    pointer-events: auto;
    display: block;
  }

  .ambrands-list-container {
    height: 100%;
    font-size: 0;
  }

  .ambrands-popup-filter {
    box-sizing: border-box;
    width: 27%;
    height: 100%;
    vertical-align: top;
    text-align: center;
    background: #f0f0f0;
    padding: 20px;
    font-size: 0;
    display: inline-block;
  }

  .ambrands-popup-filter .ambrands-letter {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    text-align: center;
    color: #333;
    font-size: 14px;
    line-height: 40px;
    display: inline-block;
  }

  .ambrands-popup-filter .ambrands-letter:hover {
    color: #333;
    background: #ccc;
    text-decoration: none;
  }

  .ambrands-popup-filter .ambrands-letter.-disabled {
    opacity: .6;
    pointer-events: none;
    background-color: #f1f1f1;
  }

  .ambrands-popup-filter .ambrands-letter.-active {
    color: #fff;
    background: #f58d0f;
  }

  .ambrands-popup-filter .ambrands-letter.-letter-all {
    width: 100%;
    margin-bottom: 15px;
  }

  .ambrands-popup-items {
    box-sizing: border-box;
    width: 73%;
    height: 100%;
    padding: 20px;
    font-size: 14px;
    display: inline-block;
    overflow: auto;
  }

  .ambrands-popup-items .brand-images .brands-letter {
    width: 100%;
    padding: 0;
  }

  .ambrands-popup-items .brand-item {
    text-align: center;
  }

  .ambrands-popup-items .brand-item .label:before {
    content: none;
  }

  .ambrands-popup-items .brand-item .count {
    transform: translateX(50%);
  }

  .ambrands-popup-items .brand-images .brands-letter .brand-item {
    margin: 0 20px 20px 0;
  }

  .ambrands-popup-items .letter {
    margin-bottom: 15px;
  }

  .filter-options-content .am-filter-price {
    width: 43%;
  }

  .amslider-container.am-swiper-slider {
    width: 85%;
  }

  .checkout-cart-index .page-main {
    max-width: 1280px;
  }

  .cart .cart-item {
    justify-content: space-between;
  }

  .cart .parent-item-details {
    order: 1;
  }

  .cart .cart-item-buttons {
    min-width: auto;
    order: 2;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 10px;
  }

  .cart .table-columns {
    order: 3;
  }

  .cart-summary {
    padding: 115px 40px 40px;
  }

  .cart-summary .action.primary.checkout {
    padding: 20px;
  }

  .minicart-wrapper .action.showcart .counter.qty {
    display: inline;
  }

  .checkout-index-index .modal-popup .modal-inner-wrap {
    width: 800px;
    margin-left: -400px;
    left: 50%;
  }

  .opc-wrapper .shipping-address-item {
    width: calc(33.333% - 20px);
  }

  .wishlist-index-index .products-grid .product-items {
    margin: 0;
  }

  .wishlist-index-index .products-grid .product-item {
    width: 24.439%;
    margin-bottom: 20px;
    margin-left: .748001%;
    padding: 0;
  }

  .wishlist-index-index .products-grid .product-item:nth-child(4n+1) {
    margin-left: 0;
  }

  .product-item-actions .actions-primary {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }

  .product-item-actions .action {
    max-width: 130px;
  }

  .product-item-actions .action, .product-item-actions .action.view {
    margin: 0 5px;
  }

  .product-item .product-price-list-availability {
    display: block;
  }

  .page-layout-1column .product-info-main {
    width: 40%;
  }

  .page-layout-1column .product.media {
    width: 57%;
  }

  .box-tocart .paypal:first-of-type {
    margin-top: 13px;
  }

  .block-search {
    width: 560px;
  }

  .search-links {
    padding-left: 20px;
  }

  .login-container {
    width: 90%;
  }

  .login-container-left, .login-container-right {
    width: 100%;
  }

  .order-links .item a {
    padding: 1px 35px;
  }

  .order-links .item strong {
    padding: 1px 35px 2px;
  }

  .message.global.cookie .actions .action {
    padding: 10px 20px;
  }

  .block.widget .products-grid .product-item {
    width: 20%;
  }

  .page-layout-1column .block.widget .products-grid .product-item {
    width: 18.4%;
    margin-left: 2%;
  }

  .page-layout-1column .block.widget .products-grid .product-item:nth-child(4n+1) {
    margin-left: 2%;
  }

  .page-layout-1column .block.widget .products-grid .product-item:nth-child(5n+1) {
    margin-left: 0;
  }

  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 25%;
  }

  .block.widget .products-grid .product-items {
    margin: 0;
  }

  .block.widget .products-grid .product-item {
    width: 24.439%;
    margin-left: .748001%;
    padding: 0;
  }

  .block.widget .products-grid .product-item:nth-child(4n+1) {
    margin-left: 0;
  }

  .homepage-logo {
    width: 260px;
  }

  .homepage-search .product-search {
    width: 700px;
  }

  .homepage-search .search-tooltip {
    left: 18px;
  }

  .homepage-search input {
    height: 60px;
    border-radius: 30px;
    padding: 0 22px;
  }

  .homepage-search .search-autocomplete, .homepage-search .search-autocomplete ul:not(:empty) {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  .homepage-search .action.search {
    right: 20px;
  }

  .homepage-search .action.search:before {
    line-height: 60px;
  }

  .homepage-search-buttons .home-search-button, .homepage-search-buttons .home-search-button-primary {
    padding: 10px 20px;
  }

  .sketcher-search-container {
    height: 500px;
  }

  .conceptSearch-grid {
    margin-left: -10px;
    margin-right: -10px;
  }

  .conceptSearch-grid-molecule {
    flex-basis: 25%;
    padding: 0 10px 20px;
  }
}

@media (min-width: 1440px), print {
  .brands-filters .letter {
    border-right: none;
    border-radius: 0;
    margin: 0;
  }

  .brands-filters .letter-all {
    margin-right: 20px;
  }

  .brands-filters .letter:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .brands-filters .letter:last-child {
    border-right: 1px solid #ccc;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .amslider .wrapper {
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }

  .product-item {
    width: 25%;
  }

  .product-item:nth-child(4n):hover .product-image-container, .product-item:nth-child(4n):focus .product-image-container, .product-item:nth-child(4n):focus-within .product-image-container {
    border-radius: 0 5px 5px 0;
  }

  .product-item:nth-child(4n) .product-price-list-container {
    border-radius: 5px 0 0 5px;
    left: calc(-97% + 22px);
    right: auto;
  }

  .sidebar .product-items .product-item-info .product-item-photo {
    float: none;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .sidebar .product-items .product-item-details {
    margin-left: 85px;
  }

  .block-search {
    width: 600px;
  }

  .message.global.cookie .actions .action {
    padding: 10px 20px;
  }

  .conceptSearch-wrapper {
    padding: 20px;
  }

  .conceptSearch-table tbody td {
    padding: 10px;
    font-size: 1.6rem;
  }

  .conceptSearch-table tbody tr:last-child td {
    padding-bottom: 20px;
  }

  .conceptSearch-paging-container {
    padding-top: 20px;
  }

  .conceptSearch-buttons {
    padding: 20px 0;
  }

  .conceptSearch-selected:not(:empty) {
    margin-top: 20px;
    margin-bottom: -10px;
    padding-top: 10px;
  }

  .conceptSearch-selected ul li {
    padding: 10px 15px 10px 10px;
  }

  .conceptSearch-sidebar {
    min-width: 275px;
    flex-basis: 275px;
    margin-right: 20px;
  }

  .conceptSearch-main {
    max-width: calc(100% - 295px);
  }

  .conceptSearch-grid {
    margin-left: -10px;
    margin-right: -10px;
  }

  .conceptSearch-grid-molecule {
    flex-basis: 20%;
    padding: 0 10px 20px;
  }

  .conceptSearch-grid-molecule-info-availability {
    display: initial;
  }

  .conceptSearch-list-wrapper {
    padding: 20px;
  }

  .conceptSearch-grid-molecule:nth-child(5n):hover .conceptSearch-grid-molecule-image, .conceptSearch-grid-molecule:nth-child(5n):focus .conceptSearch-grid-molecule-image, .conceptSearch-grid-molecule:nth-child(5n):focus-within .conceptSearch-grid-molecule-image {
    border-radius: 0 5px 5px 0;
  }

  .conceptSearch-grid-molecule:nth-child(5n) .conceptSearch-grid-molecule-info-container {
    border-radius: 5px 0 0 5px;
    left: calc(-97% + 12px);
    right: auto;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
    animation: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .conceptSearch-grid-molecule:nth-child(3n):hover .conceptSearch-grid-molecule-image, .conceptSearch-grid-molecule:nth-child(3n):focus .conceptSearch-grid-molecule-image, .conceptSearch-grid-molecule:nth-child(3n):focus-within .conceptSearch-grid-molecule-image {
    border-radius: 0 5px 5px 0;
  }

  .conceptSearch-grid-molecule:nth-child(3n) .conceptSearch-grid-molecule-info-container {
    border-radius: 5px 0 0 5px;
    left: calc(-97% + 12px);
    right: auto;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .conceptSearch-grid-molecule:nth-child(4n):hover .conceptSearch-grid-molecule-image, .conceptSearch-grid-molecule:nth-child(4n):focus .conceptSearch-grid-molecule-image, .conceptSearch-grid-molecule:nth-child(4n):focus-within .conceptSearch-grid-molecule-image {
    border-radius: 0 5px 5px 0;
  }

  .conceptSearch-grid-molecule:nth-child(4n) .conceptSearch-grid-molecule-info-container {
    border-radius: 5px 0 0 5px;
    left: calc(-97% + 12px);
    right: auto;
  }
}

.am-slider:not(.-loaded) {
  visibility: visible !important;
  opacity: 1 !important;
}

.faq {
  min-width: 320px;
  max-width: 320px;
  background-color: #fff;
  border-radius: 25px;
  margin: 150px 5% 5%;
  padding: 50px 25px;
}

.faq__heading {
  color: var(--blue-dark);
  text-align: center;
  font-size: 3.2rem;
  font-weight: 700;
}

.faq__detail {
  border-bottom: 1px solid #e7e7e9;
  padding: 20px;
}

.faq__summary:hover, .faq__summary:active {
  color: var(--orange);
  cursor: pointer;
}

.faq__summary {
  list-style: none;
}

.faq__summary::-webkit-details-marker {
  display: none;
}

.faq__summary {
  text-align: right;
  padding: 0;
  font-weight: bold;
  display: block;
  position: relative;
}

.faq__summary:after {
  color: #e62645;
  content: "";
  font-family: "Font Awesome 5 Pro";
  transition: all .2s;
  display: inline-block;
}

details[open] .faq__summary:after {
  font-family: "Font Awesome 5 Pro";
  transition: all .2s;
  display: inline-block;
  transform: rotate(45deg);
}

details[open] .faq__summary {
  margin-bottom: 14px;
}

.concept_group_item .custom-checkbox > * {
  display: inline-block;
}

.faq__question {
  text-align: left;
  width: 90%;
  text-align: left;
  width: 90%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.faq__summary {
  color: var(--blue-dark);
  font-size: 1.4rem;
  line-height: 14px;
}

.faq__summary:focus {
  color: var(--orange);
  outline: none;
}

.faq__text {
  color: var(--blue-mid);
  font-size: var(--base-font-size);
  margin: 10px 0 0;
  line-height: 1.6;
}

.hidden-lg {
  display: block;
}

.visible-lg {
  display: none;
}

.concept_group_item .custom-checkbox .checkbox {
  margin-bottom: -2px;
  margin-right: 10px;
  display: inline-block;
}

.concept_group_item .custom-checkbox:hover p {
  text-decoration: underline;
}

.concept_group_item .custom-checkbox:hover {
  cursor: pointer;
}

@media (min-width: 900px) {
  .faq {
    min-width: 900px;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .faq__logo__holder {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .faq__holder {
    border: 1px solid #cecece;
    border-radius: 5px;
  }

  .faq__heading {
    text-align: left;
  }

  .hidden-lg {
    display: none;
  }

  .visible-lg {
    margin-top: 120px;
    display: block;
    position: absolute;
    left: -80px;
  }

  .faq__text {
    margin: 0 0 10px;
  }

  .faq__detail {
    margin: 10px 0 0 0 0;
    padding: 14px;
  }
}

#custom-quote-form {
  width: 100%;
  padding-right: 0;
}

#checkout-doug input {
  margin-bottom: 10px;
}

#checkout-doug input:last-of-type {
  margin-bottom: 26px;
}

#checkout-doug label {
  font-size: 13px;
}

.checkout-cart-index .page-main > .page-title-wrapper .page-title {
  justify-content: space-between;
  display: flex;
}

.range-inputs > input {
  max-width: 40px;
  text-align: center;
}

.range-inputs {
  justify-content: space-between;
  display: flex;
}

.filter-options-content {
  margin-bottom: -20px;
  padding-top: 1px;
}

#gsearch {
  margin-bottom: 7px;
}

.header.links > li:last-of-type {
  margin-left: 10px;
}

.header.links {
  display: flex;
}

.section.newupdatedcontent h1 {
  color: #1e252b;
  font-size: 40px;
  font-weight: 800;
  line-height: 50px;
}

.section.newupdatedcontent {
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.nucsubheader {
  margin-top: -10px;
}

.section.newupdatedcontent h1 {
  color: #1e252b;
  font-weight: 700;
}

.section.newupdatedcontent ol > h2 {
  margin-left: -40px;
}

body.fluo .route-home #maincontent {
  background: #1d252d;
}

body.fluo .route-home input#product_search_input {
  border: 3px solid #ed215d;
  border-radius: 10px;
}

body.fluo .route-home .homepage-search-buttons > a {
  color: #fff;
  background: #f05423;
}

body.fluo .route-home .homepage-search-buttons > a:hover {
  background: #ed215d;
}

body.fluo .route-home footer.page-footer, body.fluo .route-home .footer-row, body.fluo .route-home .footer-top-content:after, body.fluo .route-home .footer-top-content:before {
  margin-top: 0;
  color: #fff !important;
  background: #1d252d !important;
  border: none !important;
}

body.fluo .route-home ul.footer-switcher.footer-links > li > span {
  display: none;
}

body.fluo .route-home .footer-links > li a, body.fluo .route-home .footer-links > li span, body.fluo .route-home .footer-logo > p {
  color: #fff !important;
}

body.fluo .route-home img.fluorochem-logo {
  filter: brightness(0) invert();
}

select.location-selector {
  cursor: pointer;
}

.fluo-back-btn {
  display: none !important;
}

body.fluo .route-home .fluo-back-btn {
  color: #fff;
  background: #e62645;
  display: block !important;
}

body.fluo .route-home .fluo-back-btn:hover {
  background: #e43866;
}

/*# sourceMappingURL=index.e52a4ecc.css.map */
