.search-tooltip > a {
    border-radius: 50%;
background: #e62645;
width: 35px;
height: 35px;
text-align: center;
margin: 12.5px 13px 12.5px -5px;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-thin.woff2') format('woff2'), url('../fonts/lato-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-thinitalic.woff2') format('woff2'), url('../fonts/lato-thinitalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-light.woff2') format('woff2'), url('../fonts/lato-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-lightitalic.woff2') format('woff2'), url('../fonts/lato-lightitalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-regular.woff2') format('woff2'), url('../fonts/lato-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-regularitalic.woff2') format('woff2'), url('../fonts/lato-regularitalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-bold.woff2') format('woff2'), url('../fonts/lato-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-bolditalic.woff2') format('woff2'), url('../fonts/lato-bolditalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-black.woff2') format('woff2'), url('../fonts/lato-black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-blackitalic.woff2') format('woff2'), url('../fonts/lato-blackitalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap
}

h1 {
    font-weight: 700
}

.login-container .g-recaptcha,
.form-login .g-recaptcha {
    margin-bottom: 10px !important
}

.required-captcha.checkbox {
    position: absolute;
    display: block;
    visibility: visible;
    overflow: hidden;
    opacity: 0;
    width: 1px;
    height: 1px
}

.review-form .field-recaptcha {
    margin-bottom: 10px
}

.form.send.friend .g-recaptcha {
    margin-top: 40px
}

.am-visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    border: 0;
    opacity: 0
}

.am-word-break {
    word-wrap: break-word;
    word-break: break-word
}

.ie11 .am-word-break {
    word-break: break-all
}

.opc-block-summary .vertex-message td,
.cart-summary .vertex-message td {
    padding: 0;
    border-top: none
}

.klarna-payments-method-cell {
    display: table-cell;
    vertical-align: middle
}

label.klarna-payments-method-cell>span {
    padding-left: 5px
}

span.klarna-payments-method-cell>img {
    display: block
}

@media only screen and (min-device-width:320px) and (max-device-width:780px) and (orientation:landscape) {
    .product-video {
        height: 100%;
        width: 81%
    }
}

.file-uploader-area {
    position: relative
}

.file-uploader-area input[type='file'] {
    cursor: pointer;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    visibility: hidden;
    width: 0
}

.file-uploader-area input[type='file']:focus+.file-uploader-button {
    box-shadow: 0 0 0 1px #006bb4
}

.file-uploader-area input[type='file']:disabled+.file-uploader-button {
    cursor: default;
    opacity: .5;
    pointer-events: none
}

.file-uploader-summary {
    display: inline-block;
    vertical-align: top
}

.file-uploader-button {
    background: #eee;
    border: 1px solid #ccc;
    box-sizing: border-box;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.6rem;
    margin: 0;
    padding: 7px 15px;
    vertical-align: middle
}

.file-uploader-button._is-dragover {
    background: #d4d4d4;
    border: 1px solid #006bb4
}

.file-uploader-spinner {
    background-image: url('../images/loader-1.gif');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 15px;
    display: none;
    height: 30px;
    margin-left: 10px;
    vertical-align: top;
    width: 15px
}

.file-uploader-preview .action-remove {
    bottom: 4px;
    cursor: pointer;
    display: block;
    height: 27px;
    left: 6px;
    padding: 2px;
    position: absolute;
    text-decoration: none;
    width: 25px;
    z-index: 2
}

.file-uploader-preview .action-remove>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.file-uploader-preview .action-remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.6rem;
    line-height: inherit;
    color: #514943;
    content: '\e604';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-preview .action-remove:hover:before {
    color: #736963
}

.file-uploader-preview:hover .preview-image img,
.file-uploader-preview:hover .preview-link:before {
    opacity: .7
}

.file-uploader-preview .preview-link {
    display: block;
    height: 100%
}

.file-uploader-preview .preview-image img {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.file-uploader-preview .preview-video {
    display: inline-block;
    text-decoration: none
}

.file-uploader-preview .preview-video:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 4rem;
    line-height: inherit;
    color: #ccc;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-preview .preview-video:hover:before {
    color: #ccc
}

.file-uploader-preview .preview-video:before {
    left: 0;
    margin-top: -2rem;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2
}

.file-uploader-preview .preview-document {
    display: inline-block;
    text-decoration: none
}

.file-uploader-preview .preview-document:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 7rem;
    line-height: inherit;
    color: #ccc;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-preview .preview-document:hover:before {
    color: #ccc
}

.file-uploader-preview .preview-document:before {
    left: 0;
    margin-top: -3.5rem;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2
}

.file-uploader-preview,
.file-uploader-placeholder {
    background: #fff;
    border: 1px solid #cacaca;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 150px;
    line-height: 1;
    margin: 10px 25px 10px 0;
    overflow: hidden;
    position: relative;
    width: 150px
}

.file-uploader._loading .file-uploader-spinner {
    display: inline-block
}

.file-uploader .admin__field-note,
.file-uploader .admin__field-error {
    margin-bottom: 10px
}

.file-uploader .file-uploader-filename {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    word-break: break-all
}

.file-uploader .file-uploader-filename:first-child {
    margin-bottom: 10px
}

.file-uploader .file-uploader-meta {
    color: #9e9e9e
}

.file-uploader .admin__field-fallback-reset {
    margin-left: 10px
}

._keyfocus .file-uploader .action-remove:focus {
    box-shadow: 0 0 0 1px #006bb4
}

.file-uploader-placeholder.placeholder-document {
    display: inline-block;
    text-decoration: none
}

.file-uploader-placeholder.placeholder-document:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 5rem;
    line-height: inherit;
    color: #ccc;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-placeholder.placeholder-document:hover:before {
    color: #ccc
}

.file-uploader-placeholder.placeholder-document:before {
    left: 0;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 2
}

.file-uploader-placeholder.placeholder-image {
    display: inline-block;
    text-decoration: none
}

.file-uploader-placeholder.placeholder-image:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 5rem;
    line-height: inherit;
    color: #ccc;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-placeholder.placeholder-image:hover:before {
    color: #ccc
}

.file-uploader-placeholder.placeholder-image:before {
    left: 0;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 2
}

.file-uploader-placeholder.placeholder-video {
    display: inline-block;
    text-decoration: none
}

.file-uploader-placeholder.placeholder-video:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 3rem;
    line-height: inherit;
    color: #ccc;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-placeholder.placeholder-video:hover:before {
    color: #ccc
}

.file-uploader-placeholder.placeholder-video:before {
    left: 0;
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 2
}

.file-uploader-placeholder-text {
    bottom: 0;
    color: #008bdb;
    font-size: 1.1rem;
    left: 0;
    line-height: 1.42857143;
    margin-bottom: 15%;
    padding: 0 20px;
    position: absolute;
    right: 0;
    text-align: center
}

.data-grid-file-uploader {
    min-width: 7rem
}

.data-grid-file-uploader._loading .file-uploader-spinner {
    display: block
}

.data-grid-file-uploader._loading .file-uploader-button:before {
    display: none
}

.data-grid-file-uploader .file-uploader-image {
    background: transparent;
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.data-grid-file-uploader .file-uploader-image+.file-uploader-area .file-uploader-button:before {
    display: none
}

.data-grid-file-uploader .file-uploader-area {
    z-index: 2
}

.data-grid-file-uploader .file-uploader-spinner {
    height: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.data-grid-file-uploader .file-uploader-button {
    height: 48px;
    text-align: center;
    display: block;
    text-decoration: none
}

.data-grid-file-uploader .file-uploader-button>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.data-grid-file-uploader .file-uploader-button:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.3rem;
    line-height: 48px;
    color: #8a837f;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.data-grid-file-uploader .file-uploader-button:hover:before {
    color: #666
}

.data-grid-file-uploader .action-select-wrap {
    float: left
}

.data-grid-file-uploader .action-select-wrap .action-select {
    border: 1px solid #cacaca;
    display: block;
    height: 5rem;
    margin-left: -1px;
    padding: 0;
    width: 2rem
}

.data-grid-file-uploader .action-select-wrap .action-select:after {
    border-color: #8a837f transparent transparent transparent;
    left: 50%;
    margin: 0 0 0 -5px
}

.data-grid-file-uploader .action-select-wrap .action-select:hover:after {
    border-color: #666 transparent transparent transparent
}

.data-grid-file-uploader .action-select-wrap .action-select>span {
    display: none
}

.data-grid-file-uploader .action-select-wrap .action-menu {
    left: 4rem;
    right: auto;
    z-index: 2
}

.data-grid-file-uploader-inner {
    border: 1px solid #cacaca;
    float: left;
    height: 5rem;
    position: relative;
    width: 5rem
}

@media only screen and (max-width:768px) {
    .field-tooltip .field-tooltip-content {
        left: auto;
        right: -10px;
        top: 40px
    }

    .field-tooltip .field-tooltip-content::before,
    .field-tooltip .field-tooltip-content::after {
        border: 10px solid transparent;
        height: 0;
        left: auto;
        margin-top: -21px;
        right: 10px;
        top: 0;
        width: 0
    }

    .field-tooltip .field-tooltip-content::before {
        border-bottom-color: #999
    }

    .field-tooltip .field-tooltip-content::after {
        border-bottom-color: #f4f4f4;
        top: 1px
    }
}

.jstree-node,
.jstree-children,
.jstree-container-ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none
}

.jstree-node {
    white-space: nowrap
}

.jstree-anchor {
    display: inline-block;
    color: black;
    white-space: nowrap;
    padding: 0 4px 0 1px;
    margin: 0;
    vertical-align: top
}

.jstree-anchor:focus {
    outline: 0
}

.jstree-anchor,
.jstree-anchor:link,
.jstree-anchor:visited,
.jstree-anchor:hover,
.jstree-anchor:active {
    text-decoration: none;
    color: inherit
}

.jstree-icon {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    text-align: center
}

.jstree-icon:empty {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    text-align: center
}

.jstree-ocl {
    cursor: pointer
}

.jstree-leaf>.jstree-ocl {
    cursor: default
}

.jstree .jstree-open>.jstree-children {
    display: block
}

.jstree .jstree-closed>.jstree-children,
.jstree .jstree-leaf>.jstree-children {
    display: none
}

.jstree-anchor>.jstree-themeicon {
    margin-right: 2px
}

.jstree-no-icons .jstree-themeicon,
.jstree-anchor>.jstree-themeicon-hidden {
    display: none
}

.jstree-hidden,
.jstree-node.jstree-hidden {
    display: none
}

.jstree-rtl .jstree-anchor {
    padding: 0 1px 0 4px
}

.jstree-rtl .jstree-anchor>.jstree-themeicon {
    margin-left: 2px;
    margin-right: 0
}

.jstree-rtl .jstree-node {
    margin-left: 0
}

.jstree-rtl .jstree-container-ul>.jstree-node {
    margin-right: 0
}

.jstree-wholerow-ul {
    position: relative;
    display: inline-block;
    min-width: 100%
}

.jstree-wholerow-ul .jstree-leaf>.jstree-ocl {
    cursor: pointer
}

.jstree-wholerow-ul .jstree-anchor,
.jstree-wholerow-ul .jstree-icon {
    position: relative
}

.jstree-wholerow-ul .jstree-wholerow {
    width: 100%;
    cursor: pointer;
    position: absolute;
    left: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.jstree-contextmenu .jstree-anchor {
    -webkit-user-select: none;
    -webkit-touch-callout: none
}

.vakata-context {
    display: none
}

.vakata-context,
.vakata-context ul {
    margin: 0;
    padding: 2px;
    position: absolute;
    background: #f5f5f5;
    border: 1px solid #979797;
    box-shadow: 2px 2px 2px #999
}

.vakata-context ul {
    list-style: none;
    left: 100%;
    margin-top: -2.7em;
    margin-left: -4px
}

.vakata-context .vakata-context-right ul {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -4px
}

.vakata-context li {
    list-style: none
}

.vakata-context li>a {
    display: block;
    padding: 0 2em 0 2em;
    text-decoration: none;
    width: auto;
    color: black;
    white-space: nowrap;
    line-height: 2.4em;
    text-shadow: 1px 1px 0 #fff;
    border-radius: 1px
}

.vakata-context li>a:hover {
    position: relative;
    background-color: #e8eff7;
    box-shadow: 0 0 2px #0a6aa1
}

.vakata-context li>a.vakata-context-parent {
    background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==");
    background-position: right center;
    background-repeat: no-repeat
}

.vakata-context li>a:focus {
    outline: 0
}

.vakata-context .vakata-context-hover>a {
    position: relative;
    background-color: #e8eff7;
    box-shadow: 0 0 2px #0a6aa1
}

.vakata-context .vakata-context-separator>a,
.vakata-context .vakata-context-separator>a:hover {
    background: white;
    border: 0;
    border-top: 1px solid #e2e3e3;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    padding: 0;
    margin: 0 0 0 2.4em;
    border-left: 1px solid #e0e0e0;
    text-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
    border-radius: 0
}

.vakata-context .vakata-contextmenu-disabled a,
.vakata-context .vakata-contextmenu-disabled a:hover {
    color: silver;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0
}

.vakata-context .vakata-contextmenu-disabled>a>i {
    filter: grayscale(100%)
}

.vakata-context li>a>i {
    text-decoration: none;
    display: inline-block;
    width: 2.4em;
    height: 2.4em;
    background: transparent;
    margin: 0 0 0 -2em;
    vertical-align: top;
    text-align: center;
    line-height: 2.4em
}

.vakata-context li>a>i:empty {
    width: 2.4em;
    line-height: 2.4em
}

.vakata-context li>a .vakata-contextmenu-sep {
    display: inline-block;
    width: 1px;
    height: 2.4em;
    background: white;
    margin: 0 .5em 0 0;
    border-left: 1px solid #e2e3e3
}

.vakata-context .vakata-contextmenu-shortcut {
    font-size: 0.8em;
    color: silver;
    opacity: 0.5;
    display: none
}

.vakata-context-rtl ul {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -4px
}

.vakata-context-rtl li>a.vakata-context-parent {
    background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7");
    background-position: left center;
    background-repeat: no-repeat
}

.vakata-context-rtl .vakata-context-separator>a {
    margin: 0 2.4em 0 0;
    border-left: 0;
    border-right: 1px solid #e2e3e3
}

.vakata-context-rtl .vakata-context-left ul {
    right: auto;
    left: 100%;
    margin-left: -4px;
    margin-right: auto
}

.vakata-context-rtl li>a>i {
    margin: 0 -2em 0 0
}

.vakata-context-rtl li>a .vakata-contextmenu-sep {
    margin: 0 0 0 .5em;
    border-left-color: white;
    background: #e2e3e3
}

#jstree-marker {
    position: absolute;
    top: 0;
    left: 0;
    margin: -5px 0 0 0;
    padding: 0;
    border-right: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0
}

#jstree-dnd {
    line-height: 16px;
    margin: 0;
    padding: 4px
}

#jstree-dnd .jstree-icon,
#jstree-dnd .jstree-copy {
    display: inline-block;
    text-decoration: none;
    margin: 0 2px 0 0;
    padding: 0;
    width: 16px;
    height: 16px
}

#jstree-dnd .jstree-ok {
    background: green
}

#jstree-dnd .jstree-er {
    background: red
}

#jstree-dnd .jstree-copy {
    margin: 0 2px 0 2px
}

.jstree-default .jstree-node,
.jstree-default .jstree-icon {
    background-repeat: no-repeat;
    background-color: transparent
}

.jstree-default .jstree-anchor,
.jstree-default .jstree-animated,
.jstree-default .jstree-wholerow {
    transition: background-color .15s, box-shadow .15s
}

.jstree-default .jstree-hovered {
    background: #e7f4f9;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #ccc
}

.jstree-default .jstree-context {
    background: #e7f4f9;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #ccc
}

.jstree-default .jstree-clicked {
    background: #beebff;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #999
}

.jstree-default .jstree-no-icons .jstree-anchor>.jstree-themeicon {
    display: none
}

.jstree-default .jstree-disabled {
    background: transparent;
    color: #666666
}

.jstree-default .jstree-disabled.jstree-hovered {
    background: transparent;
    box-shadow: none
}

.jstree-default .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default .jstree-disabled>.jstree-icon {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default .jstree-search {
    font-style: italic;
    color: #8b0000;
    font-weight: bold
}

.jstree-default .jstree-no-checkboxes .jstree-checkbox {
    display: none !important
}

.jstree-default.jstree-checkbox-no-clicked .jstree-clicked {
    background: transparent;
    box-shadow: none
}

.jstree-default.jstree-checkbox-no-clicked .jstree-clicked.jstree-hovered {
    background: #e7f4f9
}

.jstree-default.jstree-checkbox-no-clicked>.jstree-wholerow-ul .jstree-wholerow-clicked {
    background: transparent
}

.jstree-default.jstree-checkbox-no-clicked>.jstree-wholerow-ul .jstree-wholerow-clicked.jstree-wholerow-hovered {
    background: #e7f4f9
}

.jstree-default>.jstree-striped {
    min-width: 100%;
    display: inline-block;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==") left top repeat
}

.jstree-default>.jstree-wholerow-ul .jstree-hovered,
.jstree-default>.jstree-wholerow-ul .jstree-clicked {
    background: transparent;
    box-shadow: none;
    border-radius: 0
}

.jstree-default .jstree-wholerow {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.jstree-default .jstree-wholerow-hovered {
    background: #e7f4f9
}

.jstree-default .jstree-wholerow-clicked {
    background: #beebff;
    background: -webkit-linear-gradient(top, #beebff 0%, #a8e4ff 100%);
    background: linear-gradient(to bottom, #beebff 0%, #a8e4ff 100%)
}

.jstree-default .jstree-node {
    min-height: 24px;
    line-height: 24px;
    margin-left: 24px;
    min-width: 24px
}

.jstree-default .jstree-anchor {
    line-height: 24px;
    height: 24px
}

.jstree-default .jstree-icon {
    width: 24px;
    height: 24px;
    line-height: 24px
}

.jstree-default .jstree-icon:empty {
    width: 24px;
    height: 24px;
    line-height: 24px
}

.jstree-default.jstree-rtl .jstree-node {
    margin-right: 24px
}

.jstree-default .jstree-wholerow {
    height: 24px
}

.jstree-default .jstree-node,
.jstree-default .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default .jstree-node {
    background-position: -292px -4px;
    background-repeat: repeat-y
}

.jstree-default .jstree-last {
    background: transparent
}

.jstree-default .jstree-open>.jstree-ocl {
    background-position: -132px -4px
}

.jstree-default .jstree-closed>.jstree-ocl {
    background-position: -100px -4px
}

.jstree-default .jstree-leaf>.jstree-ocl {
    background-position: -68px -4px
}

.jstree-default .jstree-themeicon {
    background-position: -260px -4px
}

.jstree-default>.jstree-no-dots .jstree-node,
.jstree-default>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -36px -4px
}

.jstree-default>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -4px -4px
}

.jstree-default .jstree-disabled {
    background: transparent
}

.jstree-default .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default .jstree-checkbox {
    background-position: -164px -4px
}

.jstree-default .jstree-checkbox:hover {
    background-position: -164px -36px
}

.jstree-default.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default .jstree-checked>.jstree-checkbox {
    background-position: -228px -4px
}

.jstree-default.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default .jstree-checked>.jstree-checkbox:hover {
    background-position: -228px -36px
}

.jstree-default .jstree-anchor>.jstree-undetermined {
    background-position: -196px -4px
}

.jstree-default .jstree-anchor>.jstree-undetermined:hover {
    background-position: -196px -36px
}

.jstree-default .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default>.jstree-striped {
    background-size: auto 48px
}

.jstree-default.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -132px -36px
}

.jstree-default.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -100px -36px
}

.jstree-default.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -68px -36px
}

.jstree-default.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -36px -36px
}

.jstree-default.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -4px -36px
}

.jstree-default .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default .jstree-file {
    background: url(../images/32px.png) -100px -68px no-repeat
}

.jstree-default .jstree-folder {
    background: url(../images/32px.png) -260px -4px no-repeat
}

.jstree-default>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default {
    line-height: 24px;
    padding: 0 4px
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default i {
    background: transparent;
    width: 24px;
    height: 24px;
    line-height: 24px
}

#jstree-dnd.jstree-default .jstree-ok {
    background-position: -4px -68px
}

#jstree-dnd.jstree-default .jstree-er {
    background-position: -36px -68px
}

.jstree-default .jstree-ellipsis {
    overflow: hidden
}

.jstree-default .jstree-ellipsis .jstree-anchor {
    width: calc(71%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==")
}

.jstree-default.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-small .jstree-node {
    min-height: 18px;
    line-height: 18px;
    margin-left: 18px;
    min-width: 18px
}

.jstree-default-small .jstree-anchor {
    line-height: 18px;
    height: 18px
}

.jstree-default-small .jstree-icon {
    width: 18px;
    height: 18px;
    line-height: 18px
}

.jstree-default-small .jstree-icon:empty {
    width: 18px;
    height: 18px;
    line-height: 18px
}

.jstree-default-small.jstree-rtl .jstree-node {
    margin-right: 18px
}

.jstree-default-small .jstree-wholerow {
    height: 18px
}

.jstree-default-small .jstree-node,
.jstree-default-small .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default-small .jstree-node {
    background-position: -295px -7px;
    background-repeat: repeat-y
}

.jstree-default-small .jstree-last {
    background: transparent
}

.jstree-default-small .jstree-open>.jstree-ocl {
    background-position: -135px -7px
}

.jstree-default-small .jstree-closed>.jstree-ocl {
    background-position: -103px -7px
}

.jstree-default-small .jstree-leaf>.jstree-ocl {
    background-position: -71px -7px
}

.jstree-default-small .jstree-themeicon {
    background-position: -263px -7px
}

.jstree-default-small>.jstree-no-dots .jstree-node,
.jstree-default-small>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-small>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -39px -7px
}

.jstree-default-small>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -7px -7px
}

.jstree-default-small .jstree-disabled {
    background: transparent
}

.jstree-default-small .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default-small .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default-small .jstree-checkbox {
    background-position: -167px -7px
}

.jstree-default-small .jstree-checkbox:hover {
    background-position: -167px -39px
}

.jstree-default-small.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default-small .jstree-checked>.jstree-checkbox {
    background-position: -231px -7px
}

.jstree-default-small.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default-small .jstree-checked>.jstree-checkbox:hover {
    background-position: -231px -39px
}

.jstree-default-small .jstree-anchor>.jstree-undetermined {
    background-position: -199px -7px
}

.jstree-default-small .jstree-anchor>.jstree-undetermined:hover {
    background-position: -199px -39px
}

.jstree-default-small .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-small>.jstree-striped {
    background-size: auto 36px
}

.jstree-default-small.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default-small.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-small.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -135px -39px
}

.jstree-default-small.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -103px -39px
}

.jstree-default-small.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -71px -39px
}

.jstree-default-small.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default-small.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-small.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -39px -39px
}

.jstree-default-small.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -7px -39px
}

.jstree-default-small .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default-small>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default-small .jstree-file {
    background: url(../images/32px.png) -103px -71px no-repeat
}

.jstree-default-small .jstree-folder {
    background: url(../images/32px.png) -263px -7px no-repeat
}

.jstree-default-small>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default-small {
    line-height: 18px;
    padding: 0 4px
}

#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default-small i {
    background: transparent;
    width: 18px;
    height: 18px;
    line-height: 18px
}

#jstree-dnd.jstree-default-small .jstree-ok {
    background-position: -7px -71px
}

#jstree-dnd.jstree-default-small .jstree-er {
    background-position: -39px -71px
}

.jstree-default-small .jstree-ellipsis {
    overflow: hidden
}

.jstree-default-small .jstree-ellipsis .jstree-anchor {
    width: calc(77%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default-small.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==")
}

.jstree-default-small.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-large .jstree-node {
    min-height: 32px;
    line-height: 32px;
    margin-left: 32px;
    min-width: 32px
}

.jstree-default-large .jstree-anchor {
    line-height: 32px;
    height: 32px
}

.jstree-default-large .jstree-icon {
    width: 32px;
    height: 32px;
    line-height: 32px
}

.jstree-default-large .jstree-icon:empty {
    width: 32px;
    height: 32px;
    line-height: 32px
}

.jstree-default-large.jstree-rtl .jstree-node {
    margin-right: 32px
}

.jstree-default-large .jstree-wholerow {
    height: 32px
}

.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default-large .jstree-node {
    background-position: -288px 0px;
    background-repeat: repeat-y
}

.jstree-default-large .jstree-last {
    background: transparent
}

.jstree-default-large .jstree-open>.jstree-ocl {
    background-position: -128px 0px
}

.jstree-default-large .jstree-closed>.jstree-ocl {
    background-position: -96px 0px
}

.jstree-default-large .jstree-leaf>.jstree-ocl {
    background-position: -64px 0px
}

.jstree-default-large .jstree-themeicon {
    background-position: -256px 0px
}

.jstree-default-large>.jstree-no-dots .jstree-node,
.jstree-default-large>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-large>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -32px 0px
}

.jstree-default-large>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: 0px 0px
}

.jstree-default-large .jstree-disabled {
    background: transparent
}

.jstree-default-large .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default-large .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default-large .jstree-checkbox {
    background-position: -160px 0px
}

.jstree-default-large .jstree-checkbox:hover {
    background-position: -160px -32px
}

.jstree-default-large.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default-large .jstree-checked>.jstree-checkbox {
    background-position: -224px 0px
}

.jstree-default-large.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default-large .jstree-checked>.jstree-checkbox:hover {
    background-position: -224px -32px
}

.jstree-default-large .jstree-anchor>.jstree-undetermined {
    background-position: -192px 0px
}

.jstree-default-large .jstree-anchor>.jstree-undetermined:hover {
    background-position: -192px -32px
}

.jstree-default-large .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-large>.jstree-striped {
    background-size: auto 64px
}

.jstree-default-large.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default-large.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-large.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -128px -32px
}

.jstree-default-large.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -96px -32px
}

.jstree-default-large.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -64px -32px
}

.jstree-default-large.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default-large.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-large.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -32px -32px
}

.jstree-default-large.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: 0px -32px
}

.jstree-default-large .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default-large>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default-large .jstree-file {
    background: url(../images/32px.png) -96px -64px no-repeat
}

.jstree-default-large .jstree-folder {
    background: url(../images/32px.png) -256px 0px no-repeat
}

.jstree-default-large>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default-large {
    line-height: 32px;
    padding: 0 4px
}

#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default-large i {
    background: transparent;
    width: 32px;
    height: 32px;
    line-height: 32px
}

#jstree-dnd.jstree-default-large .jstree-ok {
    background-position: 0px -64px
}

#jstree-dnd.jstree-default-large .jstree-er {
    background-position: -32px -64px
}

.jstree-default-large .jstree-ellipsis {
    overflow: hidden
}

.jstree-default-large .jstree-ellipsis .jstree-anchor {
    width: calc(63%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default-large.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==")
}

.jstree-default-large.jstree-rtl .jstree-last {
    background: transparent
}

@media (max-width:768px) {
    #jstree-dnd.jstree-dnd-responsive {
        line-height: 40px;
        font-weight: bold;
        font-size: 1.1em;
        text-shadow: 1px 1px #fff
    }

    #jstree-dnd.jstree-dnd-responsive>i {
        background: transparent;
        width: 40px;
        height: 40px
    }

    #jstree-dnd.jstree-dnd-responsive>.jstree-ok {
        background-image: url(../images/40px.png);
        background-position: 0 -200px;
        background-size: 120px 240px
    }

    #jstree-dnd.jstree-dnd-responsive>.jstree-er {
        background-image: url(../images/40px.png);
        background-position: -40px -200px;
        background-size: 120px 240px
    }

    #jstree-marker.jstree-dnd-responsive {
        border-left-width: 10px;
        border-top-width: 10px;
        border-bottom-width: 10px;
        margin-top: -10px
    }
}

@media (max-width:768px) {
    .jstree-default-responsive .jstree-icon {
        background-image: url(../images/40px.png)
    }

    .jstree-default-responsive .jstree-node,
    .jstree-default-responsive .jstree-leaf>.jstree-ocl {
        background: transparent
    }

    .jstree-default-responsive .jstree-node {
        min-height: 40px;
        line-height: 40px;
        margin-left: 40px;
        min-width: 40px;
        white-space: nowrap
    }

    .jstree-default-responsive .jstree-anchor {
        line-height: 40px;
        height: 40px
    }

    .jstree-default-responsive .jstree-icon,
    .jstree-default-responsive .jstree-icon:empty {
        width: 40px;
        height: 40px;
        line-height: 40px
    }

    .jstree-default-responsive>.jstree-container-ul>.jstree-node {
        margin-left: 0
    }

    .jstree-default-responsive.jstree-rtl .jstree-node {
        margin-left: 0;
        margin-right: 40px;
        background: transparent
    }

    .jstree-default-responsive.jstree-rtl .jstree-container-ul>.jstree-node {
        margin-right: 0
    }

    .jstree-default-responsive .jstree-ocl,
    .jstree-default-responsive .jstree-themeicon,
    .jstree-default-responsive .jstree-checkbox {
        background-size: 120px 240px
    }

    .jstree-default-responsive .jstree-leaf>.jstree-ocl,
    .jstree-default-responsive.jstree-rtl .jstree-leaf>.jstree-ocl {
        background: transparent
    }

    .jstree-default-responsive .jstree-open>.jstree-ocl {
        background-position: 0 0 !important
    }

    .jstree-default-responsive .jstree-closed>.jstree-ocl {
        background-position: 0 -40px !important
    }

    .jstree-default-responsive.jstree-rtl .jstree-closed>.jstree-ocl {
        background-position: -40px 0 !important
    }

    .jstree-default-responsive .jstree-themeicon {
        background-position: -40px -40px
    }

    .jstree-default-responsive .jstree-checkbox,
    .jstree-default-responsive .jstree-checkbox:hover {
        background-position: -40px -80px
    }

    .jstree-default-responsive.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
    .jstree-default-responsive.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
    .jstree-default-responsive .jstree-checked>.jstree-checkbox,
    .jstree-default-responsive .jstree-checked>.jstree-checkbox:hover {
        background-position: 0 -80px
    }

    .jstree-default-responsive .jstree-anchor>.jstree-undetermined,
    .jstree-default-responsive .jstree-anchor>.jstree-undetermined:hover {
        background-position: 0 -120px
    }

    .jstree-default-responsive .jstree-anchor {
        font-weight: bold;
        font-size: 1.1em;
        text-shadow: 1px 1px #fff
    }

    .jstree-default-responsive>.jstree-striped {
        background: transparent
    }

    .jstree-default-responsive .jstree-wholerow {
        border-top: 1px solid rgba(255, 255, 255, 0.7);
        border-bottom: 1px solid rgba(64, 64, 64, 0.2);
        background: #ebebeb;
        height: 40px
    }

    .jstree-default-responsive .jstree-wholerow-hovered {
        background: #e7f4f9
    }

    .jstree-default-responsive .jstree-wholerow-clicked {
        background: #beebff
    }

    .jstree-default-responsive .jstree-children .jstree-last>.jstree-wholerow {
        box-shadow: inset 0 -6px 3px -5px #666
    }

    .jstree-default-responsive .jstree-children .jstree-open>.jstree-wholerow {
        box-shadow: inset 0 6px 3px -5px #666;
        border-top: 0
    }

    .jstree-default-responsive .jstree-children .jstree-open+.jstree-open {
        box-shadow: none
    }

    .jstree-default-responsive .jstree-node,
    .jstree-default-responsive .jstree-icon,
    .jstree-default-responsive .jstree-node>.jstree-ocl,
    .jstree-default-responsive .jstree-themeicon,
    .jstree-default-responsive .jstree-checkbox {
        background-image: url(../images/40px.png);
        background-size: 120px 240px
    }

    .jstree-default-responsive .jstree-node {
        background-position: -80px 0;
        background-repeat: repeat-y
    }

    .jstree-default-responsive .jstree-last {
        background: transparent
    }

    .jstree-default-responsive .jstree-leaf>.jstree-ocl {
        background-position: -40px -120px
    }

    .jstree-default-responsive .jstree-last>.jstree-ocl {
        background-position: -40px -160px
    }

    .jstree-default-responsive .jstree-themeicon-custom {
        background-color: transparent;
        background-image: none;
        background-position: 0 0
    }

    .jstree-default-responsive .jstree-file {
        background: url(../images/40px.png) 0 -160px no-repeat;
        background-size: 120px 240px
    }

    .jstree-default-responsive .jstree-folder {
        background: url(../images/40px.png) -40px -40px no-repeat;
        background-size: 120px 240px
    }

    .jstree-default-responsive>.jstree-container-ul>.jstree-node {
        margin-left: 0;
        margin-right: 0
    }
}

.jstree-node,
.jstree-children,
.jstree-container-ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none
}

.jstree-node {
    white-space: nowrap
}

.jstree-anchor {
    display: inline-block;
    color: black;
    white-space: nowrap;
    padding: 0 4px 0 1px;
    margin: 0;
    vertical-align: top
}

.jstree-anchor:focus {
    outline: 0
}

.jstree-anchor,
.jstree-anchor:link,
.jstree-anchor:visited,
.jstree-anchor:hover,
.jstree-anchor:active {
    text-decoration: none;
    color: inherit
}

.jstree-icon {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    text-align: center
}

.jstree-icon:empty {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    text-align: center
}

.jstree-ocl {
    cursor: pointer
}

.jstree-leaf>.jstree-ocl {
    cursor: default
}

.jstree .jstree-open>.jstree-children {
    display: block
}

.jstree .jstree-closed>.jstree-children,
.jstree .jstree-leaf>.jstree-children {
    display: none
}

.jstree-anchor>.jstree-themeicon {
    margin-right: 2px
}

.jstree-no-icons .jstree-themeicon,
.jstree-anchor>.jstree-themeicon-hidden {
    display: none
}

.jstree-hidden,
.jstree-node.jstree-hidden {
    display: none
}

.jstree-rtl .jstree-anchor {
    padding: 0 1px 0 4px
}

.jstree-rtl .jstree-anchor>.jstree-themeicon {
    margin-left: 2px;
    margin-right: 0
}

.jstree-rtl .jstree-node {
    margin-left: 0
}

.jstree-rtl .jstree-container-ul>.jstree-node {
    margin-right: 0
}

.jstree-wholerow-ul {
    position: relative;
    display: inline-block;
    min-width: 100%
}

.jstree-wholerow-ul .jstree-leaf>.jstree-ocl {
    cursor: pointer
}

.jstree-wholerow-ul .jstree-anchor,
.jstree-wholerow-ul .jstree-icon {
    position: relative
}

.jstree-wholerow-ul .jstree-wholerow {
    width: 100%;
    cursor: pointer;
    position: absolute;
    left: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.jstree-contextmenu .jstree-anchor {
    -webkit-user-select: none;
    -webkit-touch-callout: none
}

.vakata-context {
    display: none
}

.vakata-context,
.vakata-context ul {
    margin: 0;
    padding: 2px;
    position: absolute;
    background: #f5f5f5;
    border: 1px solid #979797;
    box-shadow: 2px 2px 2px #999
}

.vakata-context ul {
    list-style: none;
    left: 100%;
    margin-top: -2.7em;
    margin-left: -4px
}

.vakata-context .vakata-context-right ul {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -4px
}

.vakata-context li {
    list-style: none
}

.vakata-context li>a {
    display: block;
    padding: 0 2em 0 2em;
    text-decoration: none;
    width: auto;
    color: black;
    white-space: nowrap;
    line-height: 2.4em;
    text-shadow: 1px 1px 0 #fff;
    border-radius: 1px
}

.vakata-context li>a:hover {
    position: relative;
    background-color: #e8eff7;
    box-shadow: 0 0 2px #0a6aa1
}

.vakata-context li>a.vakata-context-parent {
    background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==");
    background-position: right center;
    background-repeat: no-repeat
}

.vakata-context li>a:focus {
    outline: 0
}

.vakata-context .vakata-context-hover>a {
    position: relative;
    background-color: #e8eff7;
    box-shadow: 0 0 2px #0a6aa1
}

.vakata-context .vakata-context-separator>a,
.vakata-context .vakata-context-separator>a:hover {
    background: white;
    border: 0;
    border-top: 1px solid #e2e3e3;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    padding: 0;
    margin: 0 0 0 2.4em;
    border-left: 1px solid #e0e0e0;
    text-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
    border-radius: 0
}

.vakata-context .vakata-contextmenu-disabled a,
.vakata-context .vakata-contextmenu-disabled a:hover {
    color: silver;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0
}

.vakata-context .vakata-contextmenu-disabled>a>i {
    filter: grayscale(100%)
}

.vakata-context li>a>i {
    text-decoration: none;
    display: inline-block;
    width: 2.4em;
    height: 2.4em;
    background: transparent;
    margin: 0 0 0 -2em;
    vertical-align: top;
    text-align: center;
    line-height: 2.4em
}

.vakata-context li>a>i:empty {
    width: 2.4em;
    line-height: 2.4em
}

.vakata-context li>a .vakata-contextmenu-sep {
    display: inline-block;
    width: 1px;
    height: 2.4em;
    background: white;
    margin: 0 .5em 0 0;
    border-left: 1px solid #e2e3e3
}

.vakata-context .vakata-contextmenu-shortcut {
    font-size: 0.8em;
    color: silver;
    opacity: 0.5;
    display: none
}

.vakata-context-rtl ul {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -4px
}

.vakata-context-rtl li>a.vakata-context-parent {
    background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7");
    background-position: left center;
    background-repeat: no-repeat
}

.vakata-context-rtl .vakata-context-separator>a {
    margin: 0 2.4em 0 0;
    border-left: 0;
    border-right: 1px solid #e2e3e3
}

.vakata-context-rtl .vakata-context-left ul {
    right: auto;
    left: 100%;
    margin-left: -4px;
    margin-right: auto
}

.vakata-context-rtl li>a>i {
    margin: 0 -2em 0 0
}

.vakata-context-rtl li>a .vakata-contextmenu-sep {
    margin: 0 0 0 .5em;
    border-left-color: white;
    background: #e2e3e3
}

#jstree-marker {
    position: absolute;
    top: 0;
    left: 0;
    margin: -5px 0 0 0;
    padding: 0;
    border-right: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0
}

#jstree-dnd {
    line-height: 16px;
    margin: 0;
    padding: 4px
}

#jstree-dnd .jstree-icon,
#jstree-dnd .jstree-copy {
    display: inline-block;
    text-decoration: none;
    margin: 0 2px 0 0;
    padding: 0;
    width: 16px;
    height: 16px
}

#jstree-dnd .jstree-ok {
    background: green
}

#jstree-dnd .jstree-er {
    background: red
}

#jstree-dnd .jstree-copy {
    margin: 0 2px 0 2px
}

.jstree-default-dark .jstree-node,
.jstree-default-dark .jstree-icon {
    background-repeat: no-repeat;
    background-color: transparent
}

.jstree-default-dark .jstree-anchor,
.jstree-default-dark .jstree-animated,
.jstree-default-dark .jstree-wholerow {
    transition: background-color .15s, box-shadow .15s
}

.jstree-default-dark .jstree-hovered {
    background: #555;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #555
}

.jstree-default-dark .jstree-context {
    background: #555;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #555
}

.jstree-default-dark .jstree-clicked {
    background: #5fa2db;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #666
}

.jstree-default-dark .jstree-no-icons .jstree-anchor>.jstree-themeicon {
    display: none
}

.jstree-default-dark .jstree-disabled {
    background: transparent;
    color: #666666
}

.jstree-default-dark .jstree-disabled.jstree-hovered {
    background: transparent;
    box-shadow: none
}

.jstree-default-dark .jstree-disabled.jstree-clicked {
    background: #333333
}

.jstree-default-dark .jstree-disabled>.jstree-icon {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-dark .jstree-search {
    font-style: italic;
    color: #ffffff;
    font-weight: bold
}

.jstree-default-dark .jstree-no-checkboxes .jstree-checkbox {
    display: none !important
}

.jstree-default-dark.jstree-checkbox-no-clicked .jstree-clicked {
    background: transparent;
    box-shadow: none
}

.jstree-default-dark.jstree-checkbox-no-clicked .jstree-clicked.jstree-hovered {
    background: #555
}

.jstree-default-dark.jstree-checkbox-no-clicked>.jstree-wholerow-ul .jstree-wholerow-clicked {
    background: transparent
}

.jstree-default-dark.jstree-checkbox-no-clicked>.jstree-wholerow-ul .jstree-wholerow-clicked.jstree-wholerow-hovered {
    background: #555
}

.jstree-default-dark>.jstree-striped {
    min-width: 100%;
    display: inline-block;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==") left top repeat
}

.jstree-default-dark>.jstree-wholerow-ul .jstree-hovered,
.jstree-default-dark>.jstree-wholerow-ul .jstree-clicked {
    background: transparent;
    box-shadow: none;
    border-radius: 0
}

.jstree-default-dark .jstree-wholerow {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.jstree-default-dark .jstree-wholerow-hovered {
    background: #555
}

.jstree-default-dark .jstree-wholerow-clicked {
    background: #5fa2db;
    background: -webkit-linear-gradient(top, #5fa2db 0%, #5fa2db 100%);
    background: linear-gradient(to bottom, #5fa2db 0%, #5fa2db 100%)
}

.jstree-default-dark .jstree-node {
    min-height: 24px;
    line-height: 24px;
    margin-left: 24px;
    min-width: 24px
}

.jstree-default-dark .jstree-anchor {
    line-height: 24px;
    height: 24px
}

.jstree-default-dark .jstree-icon {
    width: 24px;
    height: 24px;
    line-height: 24px
}

.jstree-default-dark .jstree-icon:empty {
    width: 24px;
    height: 24px;
    line-height: 24px
}

.jstree-default-dark.jstree-rtl .jstree-node {
    margin-right: 24px
}

.jstree-default-dark .jstree-wholerow {
    height: 24px
}

.jstree-default-dark .jstree-node,
.jstree-default-dark .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default-dark .jstree-node {
    background-position: -292px -4px;
    background-repeat: repeat-y
}

.jstree-default-dark .jstree-last {
    background: transparent
}

.jstree-default-dark .jstree-open>.jstree-ocl {
    background-position: -132px -4px
}

.jstree-default-dark .jstree-closed>.jstree-ocl {
    background-position: -100px -4px
}

.jstree-default-dark .jstree-leaf>.jstree-ocl {
    background-position: -68px -4px
}

.jstree-default-dark .jstree-themeicon {
    background-position: -260px -4px
}

.jstree-default-dark>.jstree-no-dots .jstree-node,
.jstree-default-dark>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -36px -4px
}

.jstree-default-dark>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -4px -4px
}

.jstree-default-dark .jstree-disabled {
    background: transparent
}

.jstree-default-dark .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default-dark .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default-dark .jstree-checkbox {
    background-position: -164px -4px
}

.jstree-default-dark .jstree-checkbox:hover {
    background-position: -164px -36px
}

.jstree-default-dark.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default-dark .jstree-checked>.jstree-checkbox {
    background-position: -228px -4px
}

.jstree-default-dark.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default-dark .jstree-checked>.jstree-checkbox:hover {
    background-position: -228px -36px
}

.jstree-default-dark .jstree-anchor>.jstree-undetermined {
    background-position: -196px -4px
}

.jstree-default-dark .jstree-anchor>.jstree-undetermined:hover {
    background-position: -196px -36px
}

.jstree-default-dark .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-dark>.jstree-striped {
    background-size: auto 48px
}

.jstree-default-dark.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default-dark.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -132px -36px
}

.jstree-default-dark.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -100px -36px
}

.jstree-default-dark.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -68px -36px
}

.jstree-default-dark.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default-dark.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -36px -36px
}

.jstree-default-dark.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -4px -36px
}

.jstree-default-dark .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default-dark>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default-dark .jstree-file {
    background: url(../images/32px.png) -100px -68px no-repeat
}

.jstree-default-dark .jstree-folder {
    background: url(../images/32px.png) -260px -4px no-repeat
}

.jstree-default-dark>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default-dark {
    line-height: 24px;
    padding: 0 4px
}

#jstree-dnd.jstree-default-dark .jstree-ok,
#jstree-dnd.jstree-default-dark .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default-dark i {
    background: transparent;
    width: 24px;
    height: 24px;
    line-height: 24px
}

#jstree-dnd.jstree-default-dark .jstree-ok {
    background-position: -4px -68px
}

#jstree-dnd.jstree-default-dark .jstree-er {
    background-position: -36px -68px
}

.jstree-default-dark .jstree-ellipsis {
    overflow: hidden
}

.jstree-default-dark .jstree-ellipsis .jstree-anchor {
    width: calc(71%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default-dark.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==")
}

.jstree-default-dark.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-small .jstree-node {
    min-height: 18px;
    line-height: 18px;
    margin-left: 18px;
    min-width: 18px
}

.jstree-default-dark-small .jstree-anchor {
    line-height: 18px;
    height: 18px
}

.jstree-default-dark-small .jstree-icon {
    width: 18px;
    height: 18px;
    line-height: 18px
}

.jstree-default-dark-small .jstree-icon:empty {
    width: 18px;
    height: 18px;
    line-height: 18px
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
    margin-right: 18px
}

.jstree-default-dark-small .jstree-wholerow {
    height: 18px
}

.jstree-default-dark-small .jstree-node,
.jstree-default-dark-small .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default-dark-small .jstree-node {
    background-position: -295px -7px;
    background-repeat: repeat-y
}

.jstree-default-dark-small .jstree-last {
    background: transparent
}

.jstree-default-dark-small .jstree-open>.jstree-ocl {
    background-position: -135px -7px
}

.jstree-default-dark-small .jstree-closed>.jstree-ocl {
    background-position: -103px -7px
}

.jstree-default-dark-small .jstree-leaf>.jstree-ocl {
    background-position: -71px -7px
}

.jstree-default-dark-small .jstree-themeicon {
    background-position: -263px -7px
}

.jstree-default-dark-small>.jstree-no-dots .jstree-node,
.jstree-default-dark-small>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark-small>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -39px -7px
}

.jstree-default-dark-small>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -7px -7px
}

.jstree-default-dark-small .jstree-disabled {
    background: transparent
}

.jstree-default-dark-small .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default-dark-small .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default-dark-small .jstree-checkbox {
    background-position: -167px -7px
}

.jstree-default-dark-small .jstree-checkbox:hover {
    background-position: -167px -39px
}

.jstree-default-dark-small.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default-dark-small .jstree-checked>.jstree-checkbox {
    background-position: -231px -7px
}

.jstree-default-dark-small.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default-dark-small .jstree-checked>.jstree-checkbox:hover {
    background-position: -231px -39px
}

.jstree-default-dark-small .jstree-anchor>.jstree-undetermined {
    background-position: -199px -7px
}

.jstree-default-dark-small .jstree-anchor>.jstree-undetermined:hover {
    background-position: -199px -39px
}

.jstree-default-dark-small .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-dark-small>.jstree-striped {
    background-size: auto 36px
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default-dark-small.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-small.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -135px -39px
}

.jstree-default-dark-small.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -103px -39px
}

.jstree-default-dark-small.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -71px -39px
}

.jstree-default-dark-small.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default-dark-small.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark-small.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -39px -39px
}

.jstree-default-dark-small.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -7px -39px
}

.jstree-default-dark-small .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default-dark-small>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default-dark-small .jstree-file {
    background: url(../images/32px.png) -103px -71px no-repeat
}

.jstree-default-dark-small .jstree-folder {
    background: url(../images/32px.png) -263px -7px no-repeat
}

.jstree-default-dark-small>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default-dark-small {
    line-height: 18px;
    padding: 0 4px
}

#jstree-dnd.jstree-default-dark-small .jstree-ok,
#jstree-dnd.jstree-default-dark-small .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default-dark-small i {
    background: transparent;
    width: 18px;
    height: 18px;
    line-height: 18px
}

#jstree-dnd.jstree-default-dark-small .jstree-ok {
    background-position: -7px -71px
}

#jstree-dnd.jstree-default-dark-small .jstree-er {
    background-position: -39px -71px
}

.jstree-default-dark-small .jstree-ellipsis {
    overflow: hidden
}

.jstree-default-dark-small .jstree-ellipsis .jstree-anchor {
    width: calc(77%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==")
}

.jstree-default-dark-small.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-large .jstree-node {
    min-height: 32px;
    line-height: 32px;
    margin-left: 32px;
    min-width: 32px
}

.jstree-default-dark-large .jstree-anchor {
    line-height: 32px;
    height: 32px
}

.jstree-default-dark-large .jstree-icon {
    width: 32px;
    height: 32px;
    line-height: 32px
}

.jstree-default-dark-large .jstree-icon:empty {
    width: 32px;
    height: 32px;
    line-height: 32px
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
    margin-right: 32px
}

.jstree-default-dark-large .jstree-wholerow {
    height: 32px
}

.jstree-default-dark-large .jstree-node,
.jstree-default-dark-large .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default-dark-large .jstree-node {
    background-position: -288px 0px;
    background-repeat: repeat-y
}

.jstree-default-dark-large .jstree-last {
    background: transparent
}

.jstree-default-dark-large .jstree-open>.jstree-ocl {
    background-position: -128px 0px
}

.jstree-default-dark-large .jstree-closed>.jstree-ocl {
    background-position: -96px 0px
}

.jstree-default-dark-large .jstree-leaf>.jstree-ocl {
    background-position: -64px 0px
}

.jstree-default-dark-large .jstree-themeicon {
    background-position: -256px 0px
}

.jstree-default-dark-large>.jstree-no-dots .jstree-node,
.jstree-default-dark-large>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark-large>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -32px 0px
}

.jstree-default-dark-large>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: 0px 0px
}

.jstree-default-dark-large .jstree-disabled {
    background: transparent
}

.jstree-default-dark-large .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default-dark-large .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default-dark-large .jstree-checkbox {
    background-position: -160px 0px
}

.jstree-default-dark-large .jstree-checkbox:hover {
    background-position: -160px -32px
}

.jstree-default-dark-large.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default-dark-large .jstree-checked>.jstree-checkbox {
    background-position: -224px 0px
}

.jstree-default-dark-large.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default-dark-large .jstree-checked>.jstree-checkbox:hover {
    background-position: -224px -32px
}

.jstree-default-dark-large .jstree-anchor>.jstree-undetermined {
    background-position: -192px 0px
}

.jstree-default-dark-large .jstree-anchor>.jstree-undetermined:hover {
    background-position: -192px -32px
}

.jstree-default-dark-large .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-dark-large>.jstree-striped {
    background-size: auto 64px
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default-dark-large.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-large.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -128px -32px
}

.jstree-default-dark-large.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -96px -32px
}

.jstree-default-dark-large.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -64px -32px
}

.jstree-default-dark-large.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default-dark-large.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark-large.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -32px -32px
}

.jstree-default-dark-large.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: 0px -32px
}

.jstree-default-dark-large .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default-dark-large>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default-dark-large .jstree-file {
    background: url(../images/32px.png) -96px -64px no-repeat
}

.jstree-default-dark-large .jstree-folder {
    background: url(../images/32px.png) -256px 0px no-repeat
}

.jstree-default-dark-large>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default-dark-large {
    line-height: 32px;
    padding: 0 4px
}

#jstree-dnd.jstree-default-dark-large .jstree-ok,
#jstree-dnd.jstree-default-dark-large .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default-dark-large i {
    background: transparent;
    width: 32px;
    height: 32px;
    line-height: 32px
}

#jstree-dnd.jstree-default-dark-large .jstree-ok {
    background-position: 0px -64px
}

#jstree-dnd.jstree-default-dark-large .jstree-er {
    background-position: -32px -64px
}

.jstree-default-dark-large .jstree-ellipsis {
    overflow: hidden
}

.jstree-default-dark-large .jstree-ellipsis .jstree-anchor {
    width: calc(63%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==")
}

.jstree-default-dark-large.jstree-rtl .jstree-last {
    background: transparent
}

@media (max-width:768px) {
    #jstree-dnd.jstree-dnd-responsive {
        line-height: 40px;
        font-weight: bold;
        font-size: 1.1em;
        text-shadow: 1px 1px #fff
    }

    #jstree-dnd.jstree-dnd-responsive>i {
        background: transparent;
        width: 40px;
        height: 40px
    }

    #jstree-dnd.jstree-dnd-responsive>.jstree-ok {
        background-image: url(../images/40px.png);
        background-position: 0 -200px;
        background-size: 120px 240px
    }

    #jstree-dnd.jstree-dnd-responsive>.jstree-er {
        background-image: url(../images/40px.png);
        background-position: -40px -200px;
        background-size: 120px 240px
    }

    #jstree-marker.jstree-dnd-responsive {
        border-left-width: 10px;
        border-top-width: 10px;
        border-bottom-width: 10px;
        margin-top: -10px
    }
}

@media (max-width:768px) {
    .jstree-default-dark-responsive .jstree-icon {
        background-image: url(../images/40px.png)
    }

    .jstree-default-dark-responsive .jstree-node,
    .jstree-default-dark-responsive .jstree-leaf>.jstree-ocl {
        background: transparent
    }

    .jstree-default-dark-responsive .jstree-node {
        min-height: 40px;
        line-height: 40px;
        margin-left: 40px;
        min-width: 40px;
        white-space: nowrap
    }

    .jstree-default-dark-responsive .jstree-anchor {
        line-height: 40px;
        height: 40px
    }

    .jstree-default-dark-responsive .jstree-icon,
    .jstree-default-dark-responsive .jstree-icon:empty {
        width: 40px;
        height: 40px;
        line-height: 40px
    }

    .jstree-default-dark-responsive>.jstree-container-ul>.jstree-node {
        margin-left: 0
    }

    .jstree-default-dark-responsive.jstree-rtl .jstree-node {
        margin-left: 0;
        margin-right: 40px;
        background: transparent
    }

    .jstree-default-dark-responsive.jstree-rtl .jstree-container-ul>.jstree-node {
        margin-right: 0
    }

    .jstree-default-dark-responsive .jstree-ocl,
    .jstree-default-dark-responsive .jstree-themeicon,
    .jstree-default-dark-responsive .jstree-checkbox {
        background-size: 120px 240px
    }

    .jstree-default-dark-responsive .jstree-leaf>.jstree-ocl,
    .jstree-default-dark-responsive.jstree-rtl .jstree-leaf>.jstree-ocl {
        background: transparent
    }

    .jstree-default-dark-responsive .jstree-open>.jstree-ocl {
        background-position: 0 0 !important
    }

    .jstree-default-dark-responsive .jstree-closed>.jstree-ocl {
        background-position: 0 -40px !important
    }

    .jstree-default-dark-responsive.jstree-rtl .jstree-closed>.jstree-ocl {
        background-position: -40px 0 !important
    }

    .jstree-default-dark-responsive .jstree-themeicon {
        background-position: -40px -40px
    }

    .jstree-default-dark-responsive .jstree-checkbox,
    .jstree-default-dark-responsive .jstree-checkbox:hover {
        background-position: -40px -80px
    }

    .jstree-default-dark-responsive.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
    .jstree-default-dark-responsive.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
    .jstree-default-dark-responsive .jstree-checked>.jstree-checkbox,
    .jstree-default-dark-responsive .jstree-checked>.jstree-checkbox:hover {
        background-position: 0 -80px
    }

    .jstree-default-dark-responsive .jstree-anchor>.jstree-undetermined,
    .jstree-default-dark-responsive .jstree-anchor>.jstree-undetermined:hover {
        background-position: 0 -120px
    }

    .jstree-default-dark-responsive .jstree-anchor {
        font-weight: bold;
        font-size: 1.1em;
        text-shadow: 1px 1px #fff
    }

    .jstree-default-dark-responsive>.jstree-striped {
        background: transparent
    }

    .jstree-default-dark-responsive .jstree-wholerow {
        border-top: 1px solid #666;
        border-bottom: 1px solid #000;
        background: #333333;
        height: 40px
    }

    .jstree-default-dark-responsive .jstree-wholerow-hovered {
        background: #555
    }

    .jstree-default-dark-responsive .jstree-wholerow-clicked {
        background: #5fa2db
    }

    .jstree-default-dark-responsive .jstree-children .jstree-last>.jstree-wholerow {
        box-shadow: inset 0 -6px 3px -5px #111
    }

    .jstree-default-dark-responsive .jstree-children .jstree-open>.jstree-wholerow {
        box-shadow: inset 0 6px 3px -5px #111;
        border-top: 0
    }

    .jstree-default-dark-responsive .jstree-children .jstree-open+.jstree-open {
        box-shadow: none
    }

    .jstree-default-dark-responsive .jstree-node,
    .jstree-default-dark-responsive .jstree-icon,
    .jstree-default-dark-responsive .jstree-node>.jstree-ocl,
    .jstree-default-dark-responsive .jstree-themeicon,
    .jstree-default-dark-responsive .jstree-checkbox {
        background-image: url(../images/40px.png);
        background-size: 120px 240px
    }

    .jstree-default-dark-responsive .jstree-node {
        background-position: -80px 0;
        background-repeat: repeat-y
    }

    .jstree-default-dark-responsive .jstree-last {
        background: transparent
    }

    .jstree-default-dark-responsive .jstree-leaf>.jstree-ocl {
        background-position: -40px -120px
    }

    .jstree-default-dark-responsive .jstree-last>.jstree-ocl {
        background-position: -40px -160px
    }

    .jstree-default-dark-responsive .jstree-themeicon-custom {
        background-color: transparent;
        background-image: none;
        background-position: 0 0
    }

    .jstree-default-dark-responsive .jstree-file {
        background: url(../images/40px.png) 0 -160px no-repeat;
        background-size: 120px 240px
    }

    .jstree-default-dark-responsive .jstree-folder {
        background: url(../images/40px.png) -40px -40px no-repeat;
        background-size: 120px 240px
    }

    .jstree-default-dark-responsive>.jstree-container-ul>.jstree-node {
        margin-left: 0;
        margin-right: 0
    }
}

.jstree-default-dark {
    background: #333
}

.jstree-default-dark .jstree-anchor {
    color: #999;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5)
}

.jstree-default-dark .jstree-clicked,
.jstree-default-dark .jstree-checked {
    color: white
}

.jstree-default-dark .jstree-hovered {
    color: white
}

#jstree-marker.jstree-default-dark {
    border-left-color: #999;
    background: transparent
}

.jstree-default-dark .jstree-anchor>.jstree-icon {
    opacity: 0.75
}

.jstree-default-dark .jstree-clicked>.jstree-icon,
.jstree-default-dark .jstree-hovered>.jstree-icon,
.jstree-default-dark .jstree-checked>.jstree-icon {
    opacity: 1
}

.jstree-default-dark.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAACZmZl+9SADAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==")
}

.jstree-default-dark.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAACZmZl+9SADAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==")
}

.jstree-default-dark-small.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAACZmZl+9SADAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==")
}

.jstree-default-dark-large.jstree-rtl .jstree-last {
    background: transparent
}

@media only screen and (min-width:768px) and (max-width:calc(1440px - 1px)) {

    .product-item:nth-child(3n+0):hover .product-image-container,
    .product-item:nth-child(3n+0):focus .product-image-container,
    .product-item:nth-child(3n+0):focus-within .product-image-container {
        border-radius: 0 5px 5px 0
    }

    .product-item:nth-child(3n+0) .product-price-list-container {
        left: calc(-1 * (97% - 20px - 2px));
        border-radius: 5px 0 0 5px;
        right: auto
    }
}

@media all and (min-width:768px),
print {

    .abs-product-options-list-desktop dt,
    .block-giftregistry-shared .item-options dt {
        clear: left;
        float: left;
        margin: 0 10px 5px 0
    }

    .block-giftregistry-shared .item-options dt:after {
        content: ': '
    }

    .abs-product-options-list-desktop dd,
    .block-giftregistry-shared .item-options dd {
        display: inline-block;
        float: left;
        margin: 0 0 5px
    }

    .abs-button-desktop {
        width: auto
    }

    .abs-blocks-2columns,
    .abs-discount-block-desktop .block,
    .storecredit .block,
    .account .column.main .block:not(.widget) .block-content .box,
    .form-address-edit>.fieldset,
    .form-edit-account .fieldset,
    .paypal-review-discount .block,
    .cart-discount .block {
        width: 48%
    }

    .abs-discount-block-desktop .block:nth-child(1),
    .storecredit .block:nth-child(1),
    .account .column.main .block:not(.widget) .block-content .box:nth-child(1),
    .form-address-edit>.fieldset:nth-child(1),
    .form-edit-account .fieldset:nth-child(1),
    .paypal-review-discount .block:nth-child(1),
    .cart-discount .block:nth-child(1) {
        clear: left;
        float: left
    }

    .abs-discount-block-desktop .block:nth-child(2),
    .storecredit .block:nth-child(2),
    .account .column.main .block:not(.widget) .block-content .box:nth-child(2),
    .form-address-edit>.fieldset:nth-child(2),
    .form-edit-account .fieldset:nth-child(2),
    .paypal-review-discount .block:nth-child(2),
    .cart-discount .block:nth-child(2) {
        float: right
    }

    .abs-discount-block-desktop .block:nth-child(2)+*,
    .storecredit .block:nth-child(2)+*,
    .account .column.main .block:not(.widget) .block-content .box:nth-child(2)+*,
    .form-address-edit>.fieldset:nth-child(2)+*,
    .form-edit-account .fieldset:nth-child(2)+*,
    .paypal-review-discount .block:nth-child(2)+*,
    .cart-discount .block:nth-child(2)+* {
        clear: both
    }

    .abs-margin-for-blocks-and-widgets-desktop,
    .customer-review .product-details {
        margin-bottom: 50px
    }

    .abs-reset-left-margin-desktop,
    .column.main .paypal-review .actions-toolbar,
    .column.main .block-giftregistry-shared-items .actions-toolbar {
        margin-left: 0
    }

    .abs-action-remove-desktop,
    .abs-add-fields-desktop .fieldset .additional .action.remove,
    .form-giftregistry-share .fieldset .additional .action.remove,
    .form-giftregistry-edit .fieldset .additional .action.remove,
    .form-create-return .fieldset .additional .action.remove,
    .form.send.friend .fieldset .additional .action.remove {
        margin-left: 90%
    }

    .abs-add-fields-desktop .fieldset .field .control,
    .form-giftregistry-share .fieldset .field .control,
    .form-giftregistry-edit .fieldset .field .control,
    .form-create-return .fieldset .field .control,
    .form.send.friend .fieldset .field .control {
        width: auto
    }

    .abs-margin-for-forms-desktop {
        margin-left: 25.8%
    }

    .abs-visually-hidden-desktop,
    .modes-label,
    .block-search .label,
    .block-collapsible-nav .title {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .abs-add-clearfix-desktop:before,
    .abs-add-clearfix-desktop:after,
    .paypal-review .block-content:before,
    .paypal-review .block-content:after,
    .paypal-review-discount:before,
    .paypal-review-discount:after,
    .order-review-form:before,
    .order-review-form:after,
    .block-cart-failed .block-content:before,
    .block-cart-failed .block-content:after,
    .cart-container:before,
    .cart-container:after,
    .block-giftregistry-shared .item-options:before,
    .block-giftregistry-shared .item-options:after,
    .data.table .gift-wrapping .nested:before,
    .data.table .gift-wrapping .nested:after,
    .data.table .gift-wrapping .content:before,
    .data.table .gift-wrapping .content:after,
    .block-wishlist-management:before,
    .block-wishlist-management:after,
    .magento-rma-guest-returns .column.main .block.block-order-details-view:before,
    .magento-rma-guest-returns .column.main .block.block-order-details-view:after,
    .account .page-title-wrapper:before,
    .account .page-title-wrapper:after,
    .account .column.main .block:not(.widget) .block-content:before,
    .account .column.main .block:not(.widget) .block-content:after,
    .block-addresses-list .items.addresses:before,
    .block-addresses-list .items.addresses:after,
    .order-links:before,
    .order-links:after,
    .account .column.main .block.block-order-details-view:before,
    .account .column.main .block.block-order-details-view:after,
    [class^='sales-guest-'] .column.main .block.block-order-details-view:before,
    [class^='sales-guest-'] .column.main .block.block-order-details-view:after,
    .sales-guest-view .column.main .block.block-order-details-view:before,
    .sales-guest-view .column.main .block.block-order-details-view:after,
    .page-header .header.panel:before,
    .page-header .header.panel:after,
    .header.content:before,
    .header.content:after {
        content: '';
        display: table
    }

    .abs-add-clearfix-desktop:after,
    .paypal-review .block-content:after,
    .paypal-review-discount:after,
    .order-review-form:after,
    .block-cart-failed .block-content:after,
    .cart-container:after,
    .block-giftregistry-shared .item-options:after,
    .data.table .gift-wrapping .nested:after,
    .data.table .gift-wrapping .content:after,
    .block-wishlist-management:after,
    .magento-rma-guest-returns .column.main .block.block-order-details-view:after,
    .account .page-title-wrapper:after,
    .account .column.main .block:not(.widget) .block-content:after,
    .block-addresses-list .items.addresses:after,
    .order-links:after,
    .account .column.main .block.block-order-details-view:after,
    [class^='sales-guest-'] .column.main .block.block-order-details-view:after,
    .sales-guest-view .column.main .block.block-order-details-view:after,
    .page-header .header.panel:after,
    .header.content:after {
        clear: both
    }

    .abs-add-box-sizing-desktop,
    .abs-shopping-cart-items-desktop,
    .column.main,
    .sidebar-main,
    .sidebar-additional,
    .bundle-options-container .block-bundle-summary,
    .block.crosssell,
    .magento-rma-guest-returns .column.main .block.block-order-details-view .block-content:not(.widget) .box,
    .account .column.main .block.block-order-details-view .block-content:not(.widget) .box,
    [class^='sales-guest-'] .column.main .block.block-order-details-view .block-content:not(.widget) .box,
    .sales-guest-view .column.main .block.block-order-details-view .block-content:not(.widget) .box,
    .block-cart-failed,
    .cart-container .cart-gift-item {
        box-sizing: border-box
    }

    .abs-add-box-sizing-desktop-m {
        box-sizing: border-box
    }

    .abs-revert-field-type-desktop .fieldset>.field,
    .abs-revert-field-type-desktop .fieldset .fields>.field {
        margin: 0 0 20px
    }

    .abs-revert-field-type-desktop .fieldset>.field:not(.choice)>.label,
    .abs-revert-field-type-desktop .fieldset .fields>.field:not(.choice)>.label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0
    }

    .abs-revert-field-type-desktop .fieldset>.field:not(.choice)>.control,
    .abs-revert-field-type-desktop .fieldset .fields>.field:not(.choice)>.control {
        float: none;
        width: auto
    }

    .abs-revert-field-type-desktop .fieldset>.field>.label,
    .abs-revert-field-type-desktop .fieldset .fields>.field>.label {
        margin: 0 0 8px;
        display: inline-block
    }

    .abs-revert-field-type-desktop .fieldset>.field.choice:before,
    .abs-revert-field-type-desktop .fieldset .fields>.field.choice:before,
    .abs-revert-field-type-desktop .fieldset>.field.no-label:before,
    .abs-revert-field-type-desktop .fieldset .fields>.field.no-label:before {
        display: none
    }

    .abs-form-field-column-2 .fieldset .field,
    .form-giftregistry-share .fieldset .field,
    .form-giftregistry-edit .fieldset .field,
    .form-create-return .fieldset .field,
    .form.send.friend .fieldset .field {
        padding: 0 12px 0 0;
        box-sizing: border-box;
        display: inline-block;
        width: 50%;
        vertical-align: top
    }

    .abs-form-field-column-2 .fieldset .field+.fieldset,
    .form-giftregistry-share .fieldset .field+.fieldset,
    .form-giftregistry-edit .fieldset .field+.fieldset,
    .form-create-return .fieldset .field+.fieldset,
    .form.send.friend .fieldset .field+.fieldset {
        clear: both
    }

    .form-giftregistry-share .fieldset .field:nth-last-child(1),
    .form-giftregistry-share .fieldset .field:nth-last-child(2),
    .form-giftregistry-edit .fieldset .field:nth-last-child(1),
    .form-giftregistry-edit .fieldset .field:nth-last-child(2),
    .form-create-return .fieldset .field:nth-last-child(1),
    .form-create-return .fieldset .field:nth-last-child(2),
    .form.send.friend .fieldset .field:nth-last-child(1),
    .form.send.friend .fieldset .field:nth-last-child(2) {
        margin-bottom: 0
    }

    .abs-form-field-column-2 .fieldset .field .field,
    .form-giftregistry-share .fieldset .field .field,
    .form-giftregistry-edit .fieldset .field .field,
    .form-create-return .fieldset .field .field,
    .form.send.friend .fieldset .field .field {
        padding: 0;
        width: 100%
    }

    .abs-form-field-revert-column-1 {
        width: 100%
    }

    .abs-forms-general-desktop,
    .form-giftcard-redeem,
    .form-giftregistry-create {
        max-width: 500px
    }

    .abs-forms-general-desktop .legend,
    .form-giftcard-redeem .legend,
    .form-giftregistry-create .legend {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .abs-forms-general-desktop .legend+br,
    .form-giftcard-redeem .legend+br,
    .form-giftregistry-create .legend+br {
        display: none
    }

    .abs-revert-side-paddings,
    .checkout-cart-index .page-title-wrapper,
    .cart-empty {
        padding-left: 0;
        padding-right: 0
    }

    .abs-account-block-font-size,
    .block-balance-giftcard .block-content,
    .block-reviews-dashboard .product-name,
    .block-addresses-list address,
    .box-billing-address .box-content,
    .box-shipping-address .box-content,
    .box-address-billing .box-content,
    .box-address-shipping .box-content,
    .box-information .box-content {
        font-size: 1.6rem
    }

    .abs-account-table-margin-desktop,
    .table-wrapper.balance-history,
    .table-wrapper.reward-history {
        margin-top: -25px
    }

    .abs-action-print {
        display: inline-block;
        text-decoration: none
    }

    .abs-action-print:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        line-height: 16px;
        color: inherit;
        content: '\e624';
        font-family: 'Font Awesome 5 Pro';
        margin: 0 4px 0 0;
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .abs-no-display-desktop,
    .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
        display: none
    }

    .abs-status-desktop,
    .return-status,
    .order-status {
        margin-top: 0;
        float: right
    }

    .abs-title-orders-desktop .page-main .page-title-wrapper .order-date,
    .magento-rma-returns-returns .page-main .page-title-wrapper .order-date,
    .magento-rma-returns-view .page-main .page-title-wrapper .order-date,
    .account .page-main .page-title-wrapper .order-date,
    [class^='sales-guest-'] .page-main .page-title-wrapper .order-date,
    .sales-guest-view .page-main .page-title-wrapper .order-date {
        margin: -14px 0 18px
    }

    .abs-table-bordered-desktop {
        border: none
    }

    .abs-table-bordered-desktop>thead>tr>th,
    .abs-table-bordered-desktop>tbody>tr>th,
    .abs-table-bordered-desktop>tfoot>tr>th,
    .abs-table-bordered-desktop>thead>tr>td,
    .abs-table-bordered-desktop>tbody>tr>td,
    .abs-table-bordered-desktop>tfoot>tr>td {
        border: none
    }

    .abs-table-bordered-desktop>thead>tr>th,
    .abs-table-bordered-desktop>thead>tr>td {
        border-bottom: 1px solid #ccc
    }

    .abs-pager-toolbar,
    .toolbar-giftregistry-results,
    .toolbar-wishlist-results,
    .account .toolbar {
        position: relative
    }

    .abs-pager-toolbar .toolbar-amount,
    .abs-pager-toolbar .limiter,
    .toolbar-giftregistry-results .toolbar-amount,
    .toolbar-giftregistry-results .limiter,
    .toolbar-wishlist-results .toolbar-amount,
    .toolbar-wishlist-results .limiter,
    .account .toolbar .toolbar-amount,
    .account .toolbar .limiter {
        position: relative;
        z-index: 1
    }

    .abs-pager-toolbar .limiter,
    .toolbar-giftregistry-results .limiter,
    .toolbar-wishlist-results .limiter,
    .account .toolbar .limiter {
        display: inline-block;
        float: right
    }

    .abs-pager-toolbar .toolbar-amount,
    .toolbar-giftregistry-results .toolbar-amount,
    .toolbar-wishlist-results .toolbar-amount,
    .account .toolbar .toolbar-amount {
        line-height: 30px;
        padding: 0
    }

    .abs-shopping-cart-items-desktop,
    .block-cart-failed,
    .cart-container .cart-gift-item {
        width: 75%;
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
        padding-right: 4%;
        position: relative
    }

    .abs-discount-block-desktop .block>.title,
    .paypal-review-discount .block>.title,
    .cart-discount .block>.title {
        border: 0;
        padding: 0 0 10px
    }

    .abs-discount-block-desktop .block>.title strong,
    .paypal-review-discount .block>.title strong,
    .cart-discount .block>.title strong {
        font-size: 1.6rem
    }

    .abs-discount-block-desktop .block .content,
    .paypal-review-discount .block .content,
    .cart-discount .block .content {
        padding: 0 0 20px
    }

    .abs-discount-block-desktop .actions-toolbar .secondary,
    .paypal-review-discount .actions-toolbar .secondary,
    .cart-discount .actions-toolbar .secondary {
        bottom: -30px;
        left: 0;
        position: absolute
    }

    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .header.content,
    .footer.content,
    .page-wrapper>.widget,
    .page-wrapper>.page-bottom,
    .block.category.event,
    .top-container,
    .page-main {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1920px;
        padding-left: 20px;
        padding-right: 20px;
        width: auto
    }

    .page-main {
        width: 100%
    }

    .columns {
        display: block
    }

    .column.main {
        min-height: 300px
    }

    .page-layout-1column .column.main {
        width: 100%;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2
    }

    .page-layout-3columns .column.main {
        width: 58.33333333%;
        display: inline-block;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2
    }

    .page-layout-2columns-left .column.main {
        width: 79.16666667%;
        float: right;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2
    }

    .page-layout-2columns-right .column.main {
        width: 79.16666667%;
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1
    }

    .sidebar-main {
        padding-right: 2%
    }

    .page-layout-3columns .sidebar-main {
        width: 20.83333333%;
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1
    }

    .page-layout-2columns-left .sidebar-main {
        width: 20.83333333%;
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1
    }

    .page-layout-2columns-right .sidebar-main {
        width: 20.83333333%;
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1
    }

    .page-layout-2columns-right .sidebar-main {
        padding-left: 2%;
        padding-right: 0
    }

    .sidebar-additional {
        clear: right;
        padding-left: 2%
    }

    .page-layout-3columns .sidebar-additional {
        width: 20.83333333%;
        float: right;
        -ms-flex-order: 3;
        -webkit-order: 3;
        order: 3
    }

    .page-layout-2columns-left .sidebar-additional {
        width: 20.83333333%;
        float: right;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2
    }

    .page-layout-2columns-right .sidebar-additional {
        width: 20.83333333%;
        float: right;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2
    }

    .page-layout-2columns-left .sidebar-additional {
        clear: left;
        float: left;
        padding-left: 0;
        padding-right: 2%
    }

    .panel.header {
        padding: 10px 20px
    }

    .table>tbody>tr>th,
    .table>tbody>tr>td {
        border-top: 1px solid #ccc
    }

    .table>tbody+tbody {
        border-top: 1px solid #ccc
    }

    .nav-toggle {
        display: none
    }

    .nav-sections {
        -webkit-flex-shrink: 0;
        flex-shrink: 0;
        -webkit-flex-basis: auto;
        flex-basis: auto;
        margin-bottom: 25px
    }

    .nav-sections-item-title {
        display: none
    }

    .nav-sections-item-content {
        display: block !important
    }

    .nav-sections-item-content>* {
        display: none
    }

    .nav-sections-item-content>.navigation {
        display: block
    }

    .navigation {
        background: #f0f0f0;
        font-weight: 700;
        height: inherit;
        left: auto;
        overflow: inherit;
        padding: 0;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 3
    }

    .navigation:empty {
        display: none
    }

    .navigation ul {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        position: relative
    }

    .navigation li.level0 {
        border-top: none
    }

    .navigation li.level1 {
        position: relative
    }

    .navigation .level0 {
        margin: 0 10px 0 0;
        display: inline-block;
        position: relative
    }

    .navigation .level0:last-child {
        margin-right: 0;
        padding-right: 0
    }

    .navigation .level0:hover:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        width: 10px;
        height: calc(100% + 3px);
        z-index: 1
    }

    .navigation .level0>.level-top {
        color: #575757;
        line-height: 47px;
        padding: 0 12px;
        text-decoration: none;
        box-sizing: border-box;
        position: relative;
        display: inline-block
    }

    .navigation .level0>.level-top:hover,
    .navigation .level0>.level-top.ui-state-focus {
        color: #333;
        text-decoration: none
    }

    .navigation .level0.active>.level-top,
    .navigation .level0.has-active>.level-top {
        border-color: #4a4a4a;
        border-style: solid;
        border-width: 0 0 3px;
        color: #333;
        text-decoration: none;
        display: inline-block
    }

    .navigation .level0.parent:hover>.submenu {
        overflow: visible !important
    }

    .navigation .level0.parent>.level-top {
        padding-right: 20px
    }

    .navigation .level0.parent>.level-top>.ui-menu-icon {
        position: absolute;
        right: 0;
        display: inline-block;
        text-decoration: none
    }

    .navigation .level0.parent>.level-top>.ui-menu-icon>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .navigation .level0.parent>.level-top>.ui-menu-icon:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 12px;
        line-height: 20px;
        color: inherit;
        content: '\f078';
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .navigation .level0 .submenu {
        background: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19);
        font-weight: 400;
        min-width: 230px;
        padding: 15px 0;
        display: none;
        left: 0;
        margin: 0 !important;
        position: absolute;
        z-index: 1;
        margin-top: 11px
    }

    .navigation .level0 .submenu>ul {
        margin-top: 11px
    }

    .navigation .level0 .submenu>ul:before,
    .navigation .level0 .submenu>ul:after {
        content: '';
        display: block;
        overflow: hidden;
        position: absolute
    }

    .navigation .level0 .submenu>ul:before {
        color: #fff;
        left: 20px;
        top: -20px;
        border: 10px solid transparent;
        height: 0;
        width: 0;
        border-bottom-color: #fff;
        z-index: 4
    }

    .navigation .level0 .submenu>ul:after {
        border: 11px solid transparent;
        height: 0;
        width: 0;
        border-bottom-color: #ccc;
        color: #ccc;
        left: 19px;
        top: -22px;
        z-index: 3
    }

    .navigation .level0 .submenu:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        left: 0;
        top: -4px;
        z-index: 1
    }

    .navigation .level0 .submenu a {
        display: block;
        line-height: inherit;
        color: #575757;
        padding: 8px 20px
    }

    .navigation .level0 .submenu a:hover,
    .navigation .level0 .submenu a.ui-state-focus {
        background: #e8e8e8;
        color: #333;
        text-decoration: none
    }

    .navigation .level0 .submenu .active>a {
        border-color: #4a4a4a;
        border-style: solid;
        border-width: 0 0 0 3px;
        color: #333
    }

    .navigation .level0 .submenu .submenu {
        top: 0 !important;
        left: 100% !important
    }

    .navigation .level0 .submenu .submenu-reverse {
        left: auto !important;
        right: 100%
    }

    .navigation .level0 .submenu li {
        margin: 0
    }

    .navigation .level0 .submenu li.parent>a>.ui-menu-icon {
        position: absolute;
        right: 3px;
        display: inline-block;
        text-decoration: none
    }

    .navigation .level0 .submenu li.parent>a>.ui-menu-icon>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .navigation .level0 .submenu li.parent>a>.ui-menu-icon:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 12px;
        line-height: 20px;
        color: inherit;
        content: '\f105';
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .navigation .level0.more {
        position: relative;
        display: inline-block;
        text-decoration: none
    }

    .navigation .level0.more:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 26px;
        line-height: inherit;
        color: inherit;
        content: '\e607';
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .navigation .level0.more:before {
        display: none
    }

    .navigation .level0.more:after {
        cursor: pointer;
        padding: 8px 12px;
        position: relative;
        z-index: 1
    }

    .navigation .level0.more:hover>.submenu {
        overflow: visible !important
    }

    .navigation .level0.more li {
        display: block
    }

    .panel.header .links,
    .panel.header .switcher {
        display: inline-block
    }

    .legend {
        border-bottom: 1px solid #c5c5c5
    }

    .product.data.items {
        position: relative;
        z-index: 1;
        border-bottom: 0;
        margin-left: 0;
        margin-right: 0
    }

    .product.data.items:before,
    .product.data.items:after {
        content: '';
        display: table
    }

    .product.data.items:after {
        clear: both
    }

    .product.data.items>.item.title {
        float: left;
        width: auto
    }

    .product.data.items>.item.title>.switch {
        height: 20px;
        display: block;
        position: relative;
        z-index: 2
    }

    .product.data.items>.item.content {
        margin-top: 20px;
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        width: 100%
    }

    .product.data.items>.item.content:before,
    .product.data.items>.item.content:after {
        content: '';
        display: table
    }

    .product.data.items>.item.content:after {
        clear: both
    }

    .product.data.items>.item.content.active {
        display: block
    }

    .product.data.items>.item.title {
        margin: 0 -1px 0 0
    }

    .product.data.items>.item.title>.switch {
        font-weight: 400;
        line-height: 40px;
        font-size: 1.4rem;
        color: #6d6d6d;
        text-decoration: none;
        background: #f6f6f6;
        border: 1px solid #ccc;
        border-bottom: none;
        height: 40px;
        padding: 1px 35px 1px 35px
    }

    .product.data.items>.item.title>.switch:visited {
        color: #6d6d6d;
        text-decoration: none
    }

    .product.data.items>.item.title>.switch:hover {
        color: #6d6d6d;
        text-decoration: none
    }

    .product.data.items>.item.title>.switch:active {
        color: #333;
        text-decoration: none
    }

    .product.data.items>.item.title:not(.disabled)>.switch:focus,
    .product.data.items>.item.title:not(.disabled)>.switch:hover {
        background: #fff
    }

    .product.data.items>.item.title:not(.disabled)>.switch:active,
    .product.data.items>.item.title.active>.switch,
    .product.data.items>.item.title.active>.switch:focus,
    .product.data.items>.item.title.active>.switch:hover {
        background: #fff;
        color: #333;
        text-decoration: none
    }

    .product.data.items>.item.title.active>.switch,
    .product.data.items>.item.title.active>.switch:focus,
    .product.data.items>.item.title.active>.switch:hover {
        padding-bottom: 2px
    }

    .product.data.items>.item.content {
        background: #fff;
        margin-top: 43px;
        padding: 35px 35px 35px 35px;
        border: 1px solid #ccc
    }

    .product.data.items .item.title a:after {
        display: none
    }

    .actions-toolbar {
        text-align: left
    }

    .actions-toolbar:before,
    .actions-toolbar:after {
        content: '';
        display: table
    }

    .actions-toolbar:after {
        clear: both
    }

    .actions-toolbar .primary {
        float: left
    }

    .actions-toolbar .primary,
    .actions-toolbar .secondary {
        display: inline-block
    }

    .actions-toolbar .primary a.action,
    .actions-toolbar .secondary a.action {
        display: inline-block
    }

    .actions-toolbar .primary .action {
        margin: 0 15px 0 0
    }

    .actions-toolbar .secondary a.action {
        margin-top: 6px
    }

    .actions-toolbar>.primary,
    .actions-toolbar>.secondary {
        margin-bottom: 0
    }

    .actions-toolbar>.primary .action,
    .actions-toolbar>.secondary .action {
        margin-bottom: 0;
        width: auto
    }

    .popup-content .fieldset .actions-toolbar .secondary {
        display: inline-block;
        float: none
    }

    .popup-content .fieldset .actions-toolbar .action.cancel {
        margin-top: 6px
    }

    .modal-popup.modal-slide .modal-footer {
        padding-top: 0;
        text-align: right
    }

    .brand-images .brands-letter {
        padding-right: 10px;
        width: inherit
    }

    .brand-images {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }

    .am-brands-fullwidth {
        width: 100% !important
    }

    .ui-tooltip.ui-widget {
        max-width: 450px
    }

    .filter-options-content a:hover {
        text-decoration: none
    }

    .catalog-topnav {
        display: block
    }

    .page-layout-1column .toolbar-products {
        position: inherit !important
    }

    .filter-options-content .am-filter-price {
        width: 40%
    }

    .sidebar>.block-category-list {
        margin-bottom: 14px
    }

    .amasty-catalog-topnav {
        margin-top: 3px
    }

    .amasty-catalog-topnav.catalog-topnav .filter-options .filter-options-content {
        z-index: 1001;
        min-width: 200px
    }

    .amasty-catalog-topnav .filter-options {
        margin-bottom: 20px;
        padding: 3px 0
    }

    .amasty-catalog-topnav .filter-subtitle {
        display: none
    }

    .amasty-catalog-topnav .amshopby-category-dropdown {
        min-width: 200px
    }

    .amasty-catalog-topnav .filter-options-item {
        margin-bottom: 0
    }

    .amasty-catalog-topnav .filter-options-item:first-child .filter-options-title {
        padding-left: 0
    }

    .amasty-catalog-topnav .filter-options-title {
        padding-right: 5px
    }

    .amasty-catalog-topnav .filter-options-title:after {
        position: static;
        display: inline-block;
        margin: 0 0 0 15px;
        transform: translateY(-2px)
    }

    .amasty-catalog-topnav .filter-options-item .filter-options-content .amshopby-flyout-block .items.amshopby-fly-out-view {
        margin: -11px;
        box-shadow: none
    }

    .filter-options input[type='checkbox'] {
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        margin: 0;
        padding: 0;
        width: 0;
        height: 0;
        border: 0;
        opacity: 0;
        position: absolute
    }

    .filter-options input[type='checkbox']+.amshopby-choice {
        word-wrap: break-word;
        word-break: break-word;
        position: absolute;
        top: 5px;
        left: 0;
        display: block;
        margin: 0;
        padding: 0 0 0 22px;
        min-height: 12px;
        line-height: 1.2;
        cursor: pointer
    }

    .ie11 .filter-options input[type='checkbox']+.amshopby-choice {
        word-break: break-all
    }

    .filter-options input[type='checkbox']+.amshopby-choice:before,
    .filter-options input[type='checkbox']+.amshopby-choice:after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        content: ''
    }

    .filter-options input[type='checkbox']+.amshopby-choice:before {
        border: 1px solid #e3e3e3;
        background: #fff
    }

    .filter-options input[type='checkbox']+.amshopby-choice:after {
        background: #1979c3 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxNSAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xMS42NjkzIDMuNTAwNjFjLS4yMDc4LjAwNjk1LS40MDQ4LjA5ODg1LS41NDkzLjI1NjItMS45NTcwMyAyLjA2NDE1LTMuNDM4ODMgMy43NzY1NS01LjI4MDQxIDUuNzQ3ODNMMy44MTczIDcuNzA0NzRjLS4wNzk5Mi0uMDcxNi0uMTcyNDUtLjEyNTk1LS4yNzIzMy0uMTU5OTItLjA5OTg3LS4wMzM5Ny0uMjA1MTItLjA0NjktLjMwOTc0LS4wMzgwNi0uMTA0NjIuMDA4ODUtLjIwNjU2LjAzOTI5LS4zLjA4OTYtLjA5MzQ1LjA1MDMtLjE3NjU2LjExOTQ4LS4yNDQ1OS4yMDM2LS4wNjgwNC4wODQxMS0uMTE5NjYuMTgxNTEtLjE1MTkzLjI4NjYyLS4wMzIyNy4xMDUxMS0uMDQ0NTUuMjE1ODgtLjAzNjE1LjMyNTk4LjAwODQxLjExMDExLjAzNzM0LjIxNzQuMDg1MTUuMzE1NzQuMDQ3ODEuMDk4MzMuMTEzNTUuMTg1NzkuMTkzNDguMjU3MzlMNS4zNzc3MSAxMS4yOThjLjE1MzE1LjEzNzkuMzUwMTkuMjA5OC41NTE2LjIwMTMuMjAxNDEtLjAwODYuMzkyMzEtLjA5NjkuNTM0NDQtLjI0NzNDOC42MTA3NCA4Ljk4NzU3IDEwLjE0MiA3LjE3NTQ0IDEyLjI1NiA0Ljk0NTc4Yy4xMTY1LS4xMTgzNy4xOTYtLjI3MTIuMjI4MS0uNDM4MjMuMDMyMS0uMTY3MDMuMDE1My0uMzQwMzgtLjA0ODQtLjQ5NzA5LS4wNjM2LS4xNTY3MS0uMTcwOS0uMjg5MzctLjMwNzgtLjM4MDQyLS4xMzY5LS4wOTEwNS0uMjk2OC0uMTM2MTktLjQ1ODYtLjEyOTQzeiIvPgo8L3N2Zz4K) center / 12px;
        content: none
    }

    .filter-options input[type='checkbox']+.amshopby-choice:hover:before,
    .filter-options input[type='checkbox']:checked+.amshopby-choice:before {
        border-color: #1979c3
    }

    .filter-options input[type='checkbox']:checked+.amshopby-choice:after {
        content: ''
    }

    ._keyfocus .filter-options input[type='checkbox']:active+.amshopby-choice:before,
    ._keyfocus .filter-options input[type='checkbox']:focus+.amshopby-choice:before,
    .filter-options input[type='checkbox']:active+.amshopby-choice:before,
    .filter-options input[type='checkbox']:focus+.amshopby-choice:before {
        border-color: #1979c3
    }

    .filter-options input[type='checkbox']:not(:checked).mage-error+.amshopby-choice:before {
        border-color: #f23b3b
    }

    .am_shopby_apply_filters {
        position: absolute;
        bottom: inherit;
        z-index: 212;
        display: none;
        visibility: hidden;
        padding: 0;
        width: inherit
    }

    .am_shopby_apply_filters.visible {
        bottom: inherit;
        visibility: visible
    }

    .am_shopby_apply_filters.-fixed {
        position: fixed
    }

    .am_shopby_apply_filters.-fixed .am-show-button:before {
        display: none
    }

    .am-show-button {
        position: relative;
        justify-content: space-between;
        margin: 0 0 10px;
        padding: 12px 10px;
        min-width: 180px;
        width: inherit;
        border: none;
        border-radius: 6px;
        box-shadow: 0 4px 8px rgba(190, 199, 219, 0.21), 0 6px 25px rgba(190, 199, 219, 0.28)
    }

    .am-show-button>.am-items {
        margin: 0 auto
    }

    .am-show-button:before {
        position: absolute;
        width: 10px;
        height: 10px;
        background: #fff;
        content: '';
        transform: rotate(45deg)
    }

    .am-show-button.-vertical:before {
        top: calc(50% - (10px / 2));
        right: calc(100% - (10px / 2))
    }

    .am-show-button.-horizontal:before {
        top: calc(100% - (10px / 2));
        left: calc(50% - (10px / 2))
    }

    #amasty-shopby-product-list .amshopby-overlay-block>.amshopby-loader {
        top: 30%;
        transform: translate(-50%, -30%)
    }

    .klarna-payments-method>.payment-method-title {
        padding: 11.5px 0
    }

    .payment-method-braintree .cvv .field-tooltip {
        left: 6rem
    }

    .braintree-paypal-account {
        border-color: #ccc;
        border-style: solid;
        border-width: 1px 0;
        display: inline-block;
        width: 50%
    }

    .account .table-credit-cards .col.actions {
        width: 100px
    }

    .block-category-event.block:last-child {
        margin-bottom: 30px;
        padding: 10px 0 30px
    }

    .block-category-event .block-title {
        margin: 0
    }

    .block-category-event .block-title strong {
        font-size: 2.4rem
    }

    .block-category-event .ticker li {
        display: none;
        margin: 0 50px
    }

    .block-category-event .ticker .value {
        font-size: 6rem
    }

    .block-category-event .ticker .label {
        font-size: 1.4rem;
        text-transform: none
    }

    .block-category-event .dates .date {
        font-size: 5rem
    }

    .block-category-event .dates .start {
        padding-right: 50px
    }

    .block-category-event .dates .start:after {
        font-size: 5rem;
        right: 10px
    }

    .contact-index-index .column:not(.sidebar-additional) .form.contact {
        min-width: 600px
    }

    .paypal-review .paypal-review-title {
        border-bottom: 1px solid #ccc
    }

    .paypal-review .block-content .box-order-shipping-address,
    .paypal-review .block-content .box-order-shipping-method,
    .paypal-review .block-content .box-order-shipping-method+.box-order-billing-address {
        box-sizing: border-box;
        float: left;
        width: 33%
    }

    .paypal-review .block-content .box-order-shipping-address {
        padding: 0 5%;
        width: 34%
    }

    .paypal-review .table-paypal-review-items .col.price,
    .paypal-review .table-paypal-review-items .col.qty {
        text-align: center
    }

    .paypal-review .table-paypal-review-items .col.item {
        width: 60%
    }

    .paypal-review .col.subtotal,
    .paypal-review .mark,
    .paypal-review .amount {
        text-align: right
    }

    .paypal-review-discount {
        border-top: 1px solid #ccc
    }

    .paypal-review-discount .block {
        margin-bottom: 15px
    }

    .paypal-review-discount .block.giftcard.active {
        padding-bottom: 40px
    }

    .paypal-review-discount .block .title:after {
        display: inline;
        margin-left: 10px;
        position: static
    }

    .paypal-review-discount .block .content {
        padding-bottom: 0
    }

    .order-review-form .actions-toolbar>.primary {
        float: right
    }

    .order-review-form .action.primary {
        margin-right: 0
    }

    .block-cart-failed .secondary .action {
        margin: 0
    }

    .block-cart-failed .actions.primary {
        float: right
    }

    .bundle-actions .action.primary.customize {
        width: auto
    }

    .bundle-options-container .legend.title {
        font-size: 40px
    }

    .bundle-options-container .bundle-options-wrapper,
    .bundle-options-container .product-options-wrapper {
        float: left;
        width: 57%
    }

    .bundle-options-container .block-bundle-summary {
        float: right;
        margin-top: 66px;
        padding: 10px 20px;
        position: relative;
        width: 40%
    }

    .bundle-options-container .block-bundle-summary .price-box .price-wrapper,
    .bundle-options-container .block-bundle-summary .price-box .price-wrapper>.price {
        color: #575757;
        font-size: 36px;
        font-weight: 600;
        line-height: 36px
    }

    .bundle-options-container .block-bundle-summary .price-container .weee {
        color: #575757
    }

    .bundle-options-container .block-bundle-summary .price-container .weee .price {
        font-size: 1.2rem;
        font-weight: 700
    }

    .bundle-options-container .block-bundle-summary .price-including-tax+.price-excluding-tax .price {
        font-size: 1.4rem;
        line-height: 16px
    }

    .bundle-options-container .block-bundle-summary .box-tocart .action.primary {
        margin-right: 1%;
        width: auto
    }

    .bundle-options-container .block-bundle-summary .product-addto-links {
        text-align: left
    }

    .page-layout-2columns-left .bundle-options-container .bundle-options-wrapper,
    .page-layout-2columns-left .bundle-options-container .block-bundle-summary,
    .page-layout-2columns-right .bundle-options-container .bundle-options-wrapper,
    .page-layout-2columns-right .bundle-options-container .block-bundle-summary,
    .page-layout-3columns .bundle-options-container .bundle-options-wrapper,
    .page-layout-3columns .bundle-options-container .block-bundle-summary {
        width: 48%
    }

    .checkout-cart-index .page-title-wrapper,
    .checkout-cart-index .page.messages {
        width: 70%;
        padding-right: 20px
    }

    .cart-container .form-cart {
        width: 70%;
        padding-right: 20px
    }

    .cart-container .form-cart .actions.main {
        text-align: right
    }

    .cart-container .widget {
        float: left
    }

    .checkout-cart-index .header-right {
        position: fixed;
        top: 30px;
        right: 20px;
        z-index: 10
    }    
    .checkout-cart-index .header-right .authorization-link>a,
    .checkout-cart-index .header-right .my-account-link {
        background: #e62645;
    }

    .checkout-cart-index .header-right .authorization-link>a:hover,
    .checkout-cart-index .header-right .my-account-link:hover,
    .checkout-cart-index .header-right .authorization-link>a:focus,
    .checkout-cart-index .header-right .my-account-link:focus {
        background: #e43866;
    }

    .checkout-cart-index .header-right .authorization-link>a:active,
    .checkout-cart-index .header-right .my-account-link:active {
        background: #e43866;
    }

    .cart-summary {
        position: fixed;
        background: #1d252c;
        right: 0;
        top: 0;
        width: calc((100% - 20px * 2) / 3.3333 + 20px);
        min-height: 100%;
        padding: 80px 20px 20px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }  

    .cart-summary .summary.title {
        display: none
    }

    .cart-summary .action.primary.checkout {
        padding: 20px 10px
    }

    .cart .cart-item:not(:last-child) {
        margin-bottom: 40px
    }

    .cart .parent-item-details {
        order: 1
    }

    .cart .cart-item-buttons {
        min-width: 100%;
        order: 3;
        justify-content: center;
        margin: 10px 0 10px
    }

    .cart .table-columns {
        order: 2
    }

    .cart .table-columns .table-column {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px
    }

    .cart .table-columns .table-column:after {
        min-width: 20px
    }

    .cart.table-wrapper .items {
        min-width: 100%;
        width: auto
    }

    .cart.table-wrapper tbody td {
        padding-top: 20px
    }

    .cart.table-wrapper .item .col.item {
        padding: 20px 8px 20px 0
    }

    .cart.table-wrapper .item-actions td {
        padding: 0
    }

    .cart.table-wrapper .product-item-photo {
        display: table-cell;
        max-width: 100%;
        padding-right: 20px;
        position: static;
        vertical-align: top;
        width: 1%
    }

    .cart.table-wrapper .product-item-details {
        display: table-cell;
        padding-bottom: 35px;
        vertical-align: top;
        white-space: normal;
        width: 99%
    }

    .cart-products-toolbar {
        margin: 2px 0 0
    }

    .cart-products-toolbar .toolbar-amount {
        line-height: 30px;
        margin: 0
    }

    .cart-products-toolbar .pages {
        float: right
    }

    .cart-products-toolbar .pages .item:last-child {
        margin-right: 0
    }

    .cart.table-wrapper .cart-products-toolbar+.cart thead tr th.col {
        padding-bottom: 10px;
        padding-top: 10px
    }

    .cart.table-wrapper .cart+.cart-products-toolbar {
        margin-top: 25px
    }

    .cart-discount {
        width: 75%;
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
        border: 0;
        box-sizing: border-box;
        padding-right: 4%
    }

    .cart-discount .block .title:after {
        display: inline;
        margin-left: 10px;
        position: static
    }

    .cart-discount .block.discount {
        width: auto
    }

    .block.crosssell {
        width: 75%;
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
        padding: 0 4% 0 0
    }

    .block.crosssell .products-grid .product-item {
        width: 25%
    }

    .minicart-wrapper {
        margin-right: 10px
    }

    .minicart-wrapper .block-minicart {
        width: 390px
    }

    .minicart-wrapper .action.showcart .counter-number {
        display: none
    }

    .minicart-wrapper .action.showcart .counter.qty {
        display: none
    }

    .minilist .action.delete:before,
    .minicart-wrapper .action.edit:before {
        font-size: 16px;
        line-height: inherit
    }

    .opc-main-wrapper.no-summary ol.opc li {
        margin-bottom: 40px
    }

    .opc-main-wrapper>.opc-col-wrapper {
        margin: 0 -10px;
        display: flex;
        flex-wrap: wrap
    }

    .opc-main-wrapper .summary-container,
    .opc-main-wrapper .sidebar-container {
        padding: 0 10px
    }

    .opc-main-wrapper .summary-container {
        width: 60%
    }

    .opc-main-wrapper .sidebar-container {
        width: 40%
    }

    .opc-main-wrapper .minicart-items-wrapper {
        max-height: 1000px;
        margin: 0 0 40px
    }

    .opc-wrapper {
        width: 100%
    }

    .checkout-onepage-success .print {
        display: block;
        margin: 23px 0 0
    }

    .opc-totals {
        margin-bottom: 40px
    }

    .checkout-index-index .modal-popup .form-shipping-address {
        max-width: 100%
    }

    .checkout-index-index .modal-popup .modal-footer .action-save-address {
        float: right;
        margin: 0 0 0 20px
    }

    .checkout-shipping-method .actions-toolbar .action.primary {
        float: none;
        margin: 0
    }

    .shipping-new-address-form {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px
    }

    .shipping-new-address-form>.field {
        width: 50%;
        padding: 0 10px
    }

    .opc-wrapper .shipping-address-items {
        margin: 0 -10px -10px
    }

    .opc-wrapper .shipping-address-item {
        width: calc(50% - 10px * 2)
    }

    .opc-wrapper .new-address-popup {
        text-align: right
    }

    .opc-wrapper .form-login,
    .opc-wrapper .form-shipping-address {
        max-width: 100%
    }

    .opc-wrapper .form-login {
        border-bottom: none;
        padding-bottom: 20px
    }

    .opc-block-shipping-information .shipping-information {
        margin-bottom: 40px
    }

    .opc-block-shipping-information .shipping-information .ship-to,
    .opc-block-shipping-information .shipping-information .ship-via {
        margin: 0;
        width: 100%
    }

    .opc-block-shipping-information .shipping-information .ship-to:last-child,
    .opc-block-shipping-information .shipping-information .ship-via:last-child {
        margin-bottom: 0
    }

    .opc-block-shipping-information .shipping-information .ship-to {
        margin-bottom: 20px
    }

    .opc-payment-top {
        display: flex;
        margin: 0 -10px
    }

    .opc-payment-top>div {
        margin: 0 10px
    }

    .opc-summary-wrapper .modal-header .action-close {
        display: none
    }

    .opc-block-summary .table-totals {
        max-width: 320px
    }

    .authentication-dropdown {
        background-color: #fff;
        border: 1px solid #aeaeae;
        -webkit-transform: scale(1, 0);
        -webkit-transform-origin: 0 0;
        -webkit-transition: -webkit-transform linear .1s, visibility 0s linear .1s;
        position: absolute;
        text-align: left;
        top: 100%;
        transform: scale(1, 0);
        transform-origin: 0 0;
        transition: transform linear .1s, visibility 0s linear .1s;
        visibility: hidden;
        width: 100%
    }

    .authentication-dropdown._show {
        z-index: 100;
        -webkit-transform: scale(1, 1);
        -webkit-transition: -webkit-transform linear .1s, visibility 0s linear 0s;
        transform: scale(1, 1);
        transition: transform linear .1s, visibility 0s linear 0s;
        visibility: visible
    }

    .authentication-wrapper {
        width: 33.33333333%;
        text-align: right
    }

    .block-authentication .block-title {
        font-size: 2.1rem;
        border-bottom: 0;
        margin: 0
    }

    .block-authentication .block-title h2 {
        margin: 0 0 20px
    }

    .block-authentication .actions-toolbar>.primary {
        display: inline;
        float: right;
        margin-right: 0
    }

    .block-authentication .actions-toolbar>.primary .action {
        margin-right: 0
    }

    .block-authentication .actions-toolbar>.secondary {
        float: left
    }

    .block-authentication .aw-ca-creat-wrapper {
        padding-top: 40px
    }

    .popup-authentication .modal-header {
        margin-bottom: -28px;
        z-index: 1
    }

    .popup-authentication .modal-content {
        padding: 0
    }

    .popup-authentication .modal-inner-wrap {
        min-width: 700px;
        width: 90%;
        max-width: 1024px
    }

    .popup-authentication .block-authentication {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .popup-authentication .block[class],
    .popup-authentication .form-login,
    .popup-authentication .fieldset,
    .popup-authentication .block-content {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-grow: 1;
        flex-grow: 1
    }

    .popup-authentication .block[class] {
        box-sizing: border-box;
        float: left;
        width: 50%
    }

    .popup-authentication .block[class]+.block {
        border-top: 0;
        margin: 0
    }

    .popup-authentication .block[class]+.block:before {
        left: 0;
        top: 50%
    }

    .popup-authentication .block-image {
        background-image: url('../images/auth.jpg');
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat
    }

    .popup-authentication .block-customer-login {
        padding: 40px
    }

    .checkout-payment-method .fieldset>.field-select-billing>.control {
        float: none;
        width: 100%
    }

    .checkout-payment-method .payment-method-content .fieldset>.field {
        margin: 0 0 20px
    }

    .checkout-payment-method .payment-method-content .fieldset>.field.choice:before {
        padding: 0;
        width: 0
    }

    .checkout-payment-method .payment-method-content .fieldset>.field.type .control {
        margin-left: 25.8%
    }

    .checkout-payment-method .payment-method-content .fieldset>.field.type.no-detection .control {
        margin-left: 0
    }

    .checkout-billing-address .action-update {
        float: right
    }

    .checkout-billing-address .actions-toolbar .action-cancel {
        margin: 6px 20px 0 0
    }

    .checkout-payment-method .payment-option-title {
        padding: 0 0 10px 0
    }

    .checkout-payment-method .payment-option-content .payment-option-inner+.actions-toolbar {
        margin-left: 0
    }

    .storecredit .block-balance-history {
        width: 100%
    }

    .table-balance-history .col {
        width: 25%
    }

    .page-product-downloadable .product-options-wrapper {
        float: left;
        width: 55%
    }

    .page-product-downloadable .product-options-bottom {
        float: right;
        width: 40%
    }

    .page-product-giftcard .product-info-main .price-box .price-container.price-final {
        font-size: 21px
    }

    .page-product-giftcard .product-info-main .price-box .price-container.price-final .price {
        font-size: 36px;
        line-height: 36px
    }

    .page-product-giftcard .product-info-main .product-info-stock-sku {
        padding-bottom: 0
    }

    .page-product-giftcard .product-info-main .product-reviews-summary {
        float: none
    }

    .page-product-giftcard .product-add-form {
        margin-top: -20px
    }

    .page-product-giftcard .product-add-form .field:not(.text) {
        margin: 0 0 20px;
        padding: 0 12px 0 0;
        box-sizing: border-box;
        display: inline-block;
        width: 50%;
        vertical-align: top
    }

    .page-product-giftcard .product-add-form .field:not(.text)>.label {
        margin: 0 0 8px;
        display: inline-block
    }

    .page-product-giftcard .product-add-form .field:not(.text):last-child {
        margin-bottom: 0
    }

    .page-product-giftcard .product-add-form .field:not(.text)+.fieldset {
        clear: both
    }

    .page-product-giftcard .product-add-form .field:not(.text)>.label {
        font-weight: 600
    }

    .page-product-giftcard .product-add-form .field:not(.text)>.label+br {
        display: none
    }

    .page-product-giftcard .product-add-form .field:not(.text) .choice input {
        vertical-align: top
    }

    .page-product-giftcard .product-add-form .field:not(.text) .fields.group:before,
    .page-product-giftcard .product-add-form .field:not(.text) .fields.group:after {
        content: '';
        display: table
    }

    .page-product-giftcard .product-add-form .field:not(.text) .fields.group:after {
        clear: both
    }

    .page-product-giftcard .product-add-form .field:not(.text) .fields.group .field {
        box-sizing: border-box;
        float: left
    }

    .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-2 .field {
        width: 50% !important
    }

    .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-3 .field {
        width: 33.3% !important
    }

    .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-4 .field {
        width: 25% !important
    }

    .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-5 .field {
        width: 20% !important
    }

    .page-product-giftcard .product-add-form .field:not(.text) .addon {
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        padding: 0;
        width: 100%
    }

    .page-product-giftcard .product-add-form .field:not(.text) .addon textarea,
    .page-product-giftcard .product-add-form .field:not(.text) .addon select,
    .page-product-giftcard .product-add-form .field:not(.text) .addon input {
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
        display: inline-block;
        margin: 0;
        width: auto
    }

    .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore,
    .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter {
        background: #fff;
        background-clip: padding-box;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 14px;
        height: 32px;
        line-height: 1.42857143;
        padding: 0 9px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
        -ms-flex-order: 3;
        -webkit-order: 3;
        order: 3;
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        width: auto
    }

    .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore:disabled,
    .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter:disabled {
        opacity: .5
    }

    .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore::-moz-placeholder,
    .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter::-moz-placeholder {
        color: #575757
    }

    .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore::-webkit-input-placeholder,
    .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter::-webkit-input-placeholder {
        color: #575757
    }

    .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore:-ms-input-placeholder,
    .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter:-ms-input-placeholder {
        color: #575757
    }

    .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore {
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1
    }

    .page-product-giftcard .product-add-form .field:not(.text) .additional {
        margin-top: 10px
    }

    .page-product-giftcard .product-add-form .field:not(.text).required>.label:after,
    .page-product-giftcard .product-add-form .field:not(.text)._required>.label:after {
        content: '*';
        color: #e02b27;
        font-size: 1.2rem;
        margin: 0 0 0 5px
    }

    .page-product-giftcard .product-add-form .field:not(.text) .note {
        font-size: 1.2rem;
        margin: 3px 0 0;
        padding: 0;
        display: inline-block;
        text-decoration: none
    }

    .page-product-giftcard .product-add-form .field:not(.text) .note:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 24px;
        line-height: 12px;
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .page-product-giftcard .product-add-form .field:not(.text):nth-child(odd) {
        padding-right: 25px
    }

    .page-product-giftcard .product-add-form .field:not(.text):nth-child(even) {
        padding-right: 0
    }

    .page-product-giftcard .product-add-form .product-options-wrapper .field:not(.date)>.control {
        width: auto
    }

    .gift-options {
        position: relative;
        z-index: 1
    }

    .gift-options .actions-toolbar {
        clear: both;
        padding: 0;
        position: static
    }

    .gift-options .actions-toolbar .secondary {
        float: right
    }

    .gift-options .actions-toolbar .secondary .action {
        float: right;
        margin-left: 20px;
        margin-right: 0
    }

    .gift-options .actions-toolbar .secondary .action-cancel {
        display: block;
        float: left;
        margin-top: 6px
    }

    .gift-options .actions-toolbar:nth-child(3):before {
        border-left: 1px solid #c1c1c1;
        bottom: 5rem;
        content: '';
        display: block;
        left: 50%;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 0
    }

    .gift-options-title {
        font-weight: 300;
        font-size: 1.8rem
    }

    .gift-item-block .title {
        font-size: 1.6rem;
        padding: 20px 0
    }

    .item-gift td {
        padding-left: 0;
        padding-right: 0
    }

    .cart.table-wrapper .gift-options .actions-toolbar {
        clear: both;
        padding: 0;
        position: static
    }

    .cart .action-gift {
        float: left
    }

    .cart-container .cart-gift-item {
        margin-bottom: 20px
    }

    .cart-container .cart-gift-item .gift-options,
    .cart-container .cart-gift-item .gift-summary {
        padding-bottom: 20px
    }

    .form-giftregistry-share .fieldset .field,
    .form-giftregistry-edit .fieldset .field {
        width: 45%
    }

    .form-giftregistry-edit .fieldset.shipping_address .fieldset {
        margin-top: 20px
    }

    .table-giftregistry .col.create {
        white-space: nowrap
    }

    .table-giftregistry .col.message {
        width: 40%
    }

    .table-giftregistry .col.actions {
        width: 30%
    }

    .table-giftregistry-items .col.note {
        width: 30%
    }

    .account .data.table.table-giftregistry-items>thead>tr>th,
    .account .data.table.table-giftregistry-items>tbody>tr>th,
    .account .data.table.table-giftregistry-items>tfoot>tr>th,
    .account .data.table.table-giftregistry-items>thead>tr>td,
    .account .data.table.table-giftregistry-items>tbody>tr>td,
    .account .data.table.table-giftregistry-items>tfoot>tr>td {
        border-top: 1px solid #ccc
    }

    .account .data.table.table-giftregistry-items>caption+thead>tr:first-child>th,
    .account .data.table.table-giftregistry-items>colgroup+thead>tr:first-child>th,
    .account .data.table.table-giftregistry-items>thead:first-child>tr:first-child>th,
    .account .data.table.table-giftregistry-items>caption+thead>tr:first-child>td,
    .account .data.table.table-giftregistry-items>colgroup+thead>tr:first-child>td,
    .account .data.table.table-giftregistry-items>thead:first-child>tr:first-child>td {
        border-top: 0
    }

    .account .data.table.table-giftregistry-items>tbody+tbody {
        border-top: 1px solid #ccc
    }

    .account .data.table.table-giftregistry-items th {
        border-bottom: 0
    }

    .account .data.table.table-giftregistry-items>tbody>tr:nth-child(even)>td,
    .account .data.table.table-giftregistry-items>tbody>tr:nth-child(even)>th {
        background: none
    }

    .form-giftregistry-search .fieldset {
        margin-bottom: 29px
    }

    .form-giftregistry-search .fieldset .field.name,
    .form-giftregistry-search .fieldset .field.lastname {
        padding: 0 12px 0 0;
        box-sizing: border-box;
        display: inline-block;
        width: 50%;
        vertical-align: top;
        margin-bottom: auto
    }

    .form-giftregistry-search .fieldset .field.name+.fieldset,
    .form-giftregistry-search .fieldset .field.lastname+.fieldset {
        clear: both
    }

    .form-giftregistry-search .fieldset .field.lastname {
        padding: 0
    }

    .block-giftregistry-shared-items .item {
        border-bottom: 1px solid #ccc;
        border-top: 0
    }

    .block-giftregistry-shared-items .product-item-photo {
        display: table-cell;
        max-width: 100%;
        padding: 0 20px 0 0;
        vertical-align: top;
        width: 1%
    }

    .block-giftregistry-shared-items .product-item-details {
        display: table-cell;
        vertical-align: top;
        width: 99%;
        word-break: normal
    }

    .block-giftregistry-shared-items .col.product {
        width: 48%
    }

    .block-giftregistry-shared-items .col:not(.product) {
        text-align: center
    }

    .block-giftregistry-shared-items .col.price {
        padding-top: 17px
    }

    .block-giftregistry-shared-items .input-text.qty {
        margin-top: -4px
    }

    .gift-options-cart-item .gift-wrapping,
    .cart-gift-item .gift-wrapping {
        box-sizing: border-box;
        float: left;
        padding-right: 20px;
        width: 50%
    }

    .gift-options-cart-item .gift-wrapping+.gift-message,
    .cart-gift-item .gift-wrapping+.gift-message {
        border-left: 1px solid #c1c1c1;
        box-sizing: border-box;
        float: left;
        padding-left: 4.5rem;
        width: 50%
    }

    .gift-options-cart-item .gift-summary .regular-price,
    .cart-gift-item .gift-summary .regular-price {
        white-space: nowrap
    }

    .gift-options-cart-item .gift-wrapping-name,
    .cart-gift-item .gift-wrapping-name {
        padding-right: 20px
    }

    .order-options .gift-wrapping,
    .table-order-review .gift-wrapping {
        max-width: 50%
    }

    .page-product-grouped .product-info-price {
        float: none
    }

    .page-product-grouped .minimal-price {
        margin-top: -8px
    }

    .box-tocart .action.instant-purchase {
        margin-bottom: 0;
        margin-right: 1%;
        width: 49%
    }

    .table-invitations .col {
        width: 50%
    }

    .wishlist.window.popup {
        bottom: auto;
        top: 20%;
        left: 50%;
        margin-left: -212px;
        width: 380px;
        right: auto
    }

    .block-wishlist-management {
        margin-bottom: 20px
    }

    .block-wishlist-management .wishlist-select {
        border-bottom: 1px solid #e8e8e8;
        display: table;
        margin-bottom: 15px;
        width: 100%
    }

    .block-wishlist-management .wishlist-select .wishlist-name {
        display: table-cell;
        margin-right: 10px;
        padding: 5px 10px 10px;
        vertical-align: top;
        white-space: nowrap;
        width: 5%
    }

    .block-wishlist-management .wishlist-select-items {
        display: table-cell;
        padding-right: 160px;
        vertical-align: top
    }

    .block-wishlist-management .wishlist-select-items .item {
        display: inline-block;
        margin-right: 10px;
        padding: 5px 10px 10px
    }

    .block-wishlist-management .wishlist-select-items .item:last-child {
        margin-right: 0
    }

    .block-wishlist-management .wishlist-select-items .current {
        border-bottom: 3px solid #4a4a4a;
        font-weight: 600
    }

    .block-wishlist-management .wishlist-select .wishlist-name-current {
        display: none
    }

    .block-wishlist-management .wishlist-add.item {
        position: absolute;
        right: 0;
        top: 0
    }

    .block-wishlist-management .wishlist-title strong {
        font-size: 4rem
    }

    .block-wishlist-management .wishlist-info {
        float: left
    }

    .block-wishlist-management .wishlist-toolbar {
        float: right
    }

    .block-wishlist-info-items .product-item-photo {
        margin-left: 0
    }

    .products-grid.wishlist .product-item-checkbox {
        float: left
    }

    .products-grid.wishlist .product-item-checkbox+.product-item-name {
        margin-left: 25px
    }

    .block.newsletter {
        max-width: 44%;
        width: max-content
    }

    .product-reviews-summary {
        margin-bottom: 15px
    }

    .products.wrapper.list .product-reviews-summary {
        margin: 0
    }

    .product-reviews-summary .reviews-actions {
        font-size: 14px;
        margin-top: 3px
    }

    .form-create-return .fieldset .field {
        width: 45%
    }

    .magento-rma-guest-returns .column.main .block.block-order-details-view .block-content:not(.widget) .box {
        clear: none;
        float: left;
        width: 25%
    }

    .block-returns-tracking .block-title .action {
        margin: 0 0 0 30px
    }

    .block-returns-tracking .block-title .actions-track {
        float: right;
        margin-top: 12px
    }

    .form.send.friend {
        width: 60%
    }

    .form.send.friend .fieldset .field {
        width: 45%
    }

    .form.send.friend .fieldset .field.text {
        width: 90%
    }

    .my-credit-cards .card-type img {
        display: block
    }

    .products-grid.wishlist .product-item-info:hover .product-item-inner {
        display: block
    }

    .products-grid.wishlist .product-item-tooltip {
        display: inline-block
    }

    .products-grid.wishlist .product-item-actions {
        margin: 10px 0 0
    }

    .products-grid.wishlist .product-item-actions>* {
        display: inline-block;
        margin-bottom: 7px;
        margin-top: 7px
    }

    .products-grid.wishlist .product-item .fieldset {
        display: table
    }

    .products-grid.wishlist .product-item .fieldset .field.qty,
    .products-grid.wishlist .product-item .fieldset .product-item-actions {
        display: table-cell;
        vertical-align: bottom
    }

    .products-grid.wishlist .product-item .fieldset .field.qty {
        padding-right: 10px
    }

    .products-grid.wishlist .product-item .box-tocart .actions-primary {
        margin: 0
    }

    .products-grid.wishlist .product-item .box-tocart .stock {
        margin: 20px 0 0
    }

    .products-grid.wishlist .product-item .tocart {
        width: auto
    }

    .wishlist-index-index .product-item-info {
        width: auto
    }

    .wishlist-index-index .product-item-inner {
        background: #fff;
        border: 1px solid #bbb;
        box-shadow: 3px 4px 4px 0 rgba(0, 0, 0, 0.3);
        border-top: none;
        left: 0;
        margin: 9px 0 0 -1px;
        padding: 0 9px 9px;
        position: absolute;
        right: -1px;
        z-index: 2
    }

    .wishlist-index-index .product-item-inner .comment-box {
        margin-top: -18px
    }

    .aw-create-customer .aw-block-new-company {
        min-width: 600px;
        width: 50%
    }

    .aw-ca-company-create .aw-ca__form .fieldset-wrapper {
        margin-bottom: 40px;
        padding: 20px;
        background: #fff;
        border-radius: 5px
    }

    .aw-ca-company-create .aw-ca__form .fieldset-wrapper .fieldset>.field {
        width: 50%
    }

    .aw-ca-company-create .aw-ca__form .fieldset-wrapper.consent-fieldset-wrapper {
        margin-bottom: 0;
        padding-top: 0
    }

    .aw-ca-company-create .aw-ca__form .action-basic {
        margin-top: 0
    }

    .aw-ca__form .fieldset-wrapper.consent-fieldset-wrapper {
        background: transparent
    }

    .aw-ca__form .fieldset-wrapper.consent-fieldset-wrapper .fieldset>.field {
        width: 100%
    }

    .product-items {
        margin-left: -20px;
        margin-right: -20px
    }

    .product-item {
        padding: 0 20px 20px;
        width: 33%
    }

    .product-item:hover .product-image-container,
    .product-item:focus .product-image-container,
    .product-item:focus-within .product-image-container {
        border-radius: 5px 0 0 5px
    }

    .product-item:hover .product-price-list-container,
    .product-item:focus .product-price-list-container,
    .product-item:focus-within .product-price-list-container {
        display: block
    }

    .product-item-actions .action {
        max-width: 130px
    }

    .product-item-actions .action,
    .product-item-actions .action.view {
        margin: 5px
    }

    .product-item-actions .action.tocart {
        min-width: 148px
    }

    .product-item .product-price-list-availability {
        display: none
    }

    .page-products .columns {
        padding-top: 0;
        position: relative;
        z-index: 1
    }

    .toolbar-amount {
        display: block;
        float: left;
        position: static
    }

    .products.wrapper~.toolbar .pages {
        margin-bottom: 0
    }

    .modes {
        display: inline-block;
        float: left;
        margin-right: 20px
    }

    .products.wrapper~.toolbar .modes {
        display: none
    }

    .modes-mode {
        background-color: #f0f0f0;
        box-shadow: inset 0 1px 0 0 #fff, inset 0 -1px 0 0 rgba(204, 204, 204, 0.3);
        color: #5e5e5e;
        border: 1px solid #ccc;
        border-right: 0;
        float: left;
        font-weight: 400;
        line-height: 1;
        padding: 7px 10px;
        text-align: center;
        display: inline-block;
        text-decoration: none
    }

    .modes-label+.modes-mode {
        border-radius: 3px 0 0 3px
    }

    .modes-mode:hover {
        color: #5e5e5e;
        background: #ebebeb
    }

    .modes-mode:last-child {
        border-radius: 0 3px 3px 0;
        border-right: 1px solid #ccc
    }

    .modes-mode.active {
        box-shadow: inset 0 1px 0 0 rgba(204, 204, 204, 0.8), inset 0 -1px 0 0 rgba(204, 204, 204, 0.3);
        background: #dedede;
        color: #9e9e9e
    }

    .modes-mode>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .modes-mode:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        line-height: inherit;
        color: #7d7d7d;
        content: '\e60d';
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .modes-mode:hover:before {
        color: #7d7d7d
    }

    .page-products .sorter {
        position: static
    }

    .mode-list:before {
        content: '\e60b'
    }

    .product-info-main .page-title-wrapper h1 {
        margin-bottom: 15px
    }

    .product-info-main .product-add-form {
        padding-top: 20px
    }

    .product-info-top {
        max-width: 1440px;
        flex-wrap: wrap
    }

    .product-info-top>.page-title-wrapper {
        flex-basis: 100%
    }

    .product-info-top>.product-info-main {
        padding-left: 20px
    }

    .product-info-top>.product.media {
        padding-right: 20px
    }

    .product-social-links {
        text-align: left
    }

    .product-options-bottom .price-box .price-container,
    .product-info-price .price-box .price-container {
        font-size: 21px
    }

    .product-options-bottom .price-box .price-container .price,
    .product-info-price .price-box .price-container .price {
        font-size: 36px;
        line-height: 36px
    }

    .product-options-bottom .price-box .price-including-tax+.price-excluding-tax .price,
    .product-info-price .price-box .price-including-tax+.price-excluding-tax .price {
        font-size: 1.4rem;
        line-height: 16px
    }

    .product.media .gallery-placeholder {
        max-width: 450px;
        margin: 0 auto
    }

    .page-layout-1column .product-info-main {
        width: 50%
    }

    .page-layout-1column .product.media {
        width: 50%;
        max-width: 600px
    }

    .page-layout-2columns-left .product-info-main,
    .page-layout-2columns-right .product-info-main,
    .page-layout-3columns .product-info-main {
        width: 48%
    }

    .page-layout-2columns-left .product.media,
    .page-layout-2columns-right .product.media,
    .page-layout-3columns .product.media {
        width: 50%
    }

    .product-add-form .product-options-wrapper .field .control {
        width: 80%
    }

    .sidebar .product-items .product-item-info .product-item-photo {
        float: left;
        left: auto;
        margin: 0 10px 10px 0;
        position: relative;
        top: auto
    }

    .sidebar .product-items .product-item-details {
        margin: 0
    }

    .sidebar .product-items .product-item-actions {
        clear: left
    }

    .catalog-category-view.page-layout-1column .column.main {
        min-height: inherit
    }

    .compare.wrapper {
        float: right;
        margin: 0;
        padding: 0;
        list-style: none none
    }

    .compare.wrapper .action.compare {
        line-height: 32px;
        color: #333;
        text-decoration: none
    }

    .compare.wrapper .action.compare:visited {
        color: #333;
        text-decoration: none
    }

    .compare.wrapper .action.compare:hover {
        color: #333;
        text-decoration: underline
    }

    .compare.wrapper .action.compare:active {
        color: #333;
        text-decoration: underline
    }

    .compare.wrapper .counter.qty {
        color: #7d7d7d
    }

    .compare.wrapper .counter.qty:before {
        content: '('
    }

    .compare.wrapper .counter.qty:after {
        content: ')'
    }

    .product-content-table th,
    .product-content-table td {
        padding: 20px
    }

    .product-content-table tr th {
        width: 200px
    }

    .block-search {
        padding-left: 15px;
        width: 360px
    }

    .block-search .control {
        border-top: 0;
        margin: 0;
        padding: 0
    }

    .block-search input {
        margin: 0;
        padding: 0 40px 0 35px;
        position: static;
        height: 40px;
        font-size: 16px;
        border-radius: 20px
    }

    .block-search input::-webkit-input-placeholder {
        color: #575757
    }

    .block-search input:-moz-placeholder {
        color: #575757
    }

    .block-search input::-moz-placeholder {
        color: #575757
    }

    .block-search input:-ms-input-placeholder {
        color: #575757
    }

    .block-search .search-tooltip {
        left: 10px
    }

    .block-search .search-tooltip>a {
        display: inline-block;
        text-decoration: none
    }

    .block-search .search-tooltip>a>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .block-search .search-tooltip>a:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 20px;
        line-height: 40px;
        color: #333;
        content: '\f05a';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .block-search .search-tooltip>a:hover:before {
        color: #333
    }

    .block-search .search-tooltip>a:active:before {
        color: #333
    }

    .block-search .search-tooltip>a:before {
        font-weight: bold
    }

    .block-search .action.search {
        display: inline-block;
        text-decoration: none;
        right: 15px
    }

    .block-search .action.search>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .block-search .action.search:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        line-height: 40px;
        color: #e62645;
        content: '\f002';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .block-search .action.search:hover:before {
        color: #e43866;
    }

    .block-search .action.search:active:before {
        color: #e43866;
    }

    .block-search .action.search:before {
        font-weight: bold
    }

    .search-autocomplete {
        margin-top: 0
    }

    .search-autocomplete,
    .search-autocomplete ul:not(:empty) {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px
    }

    .search-autocomplete ul li {
        padding: 10px 50px 10px 20px
    }

    .search-autocomplete ul li .amount {
        right: 20px;
        top: 10px
    }

    .search-links {
        padding-left: 10px
    }

    .login-container {
        width: 100%;
        margin: 20px auto;
        max-width: 400px;
        border: 1px solid #cecece;
    }

    .login-container-left {
        width: 30%
    }

    .login-container-right {
        width: 70%;
        padding: 40px
    }

    .login-container .block.login .actions-toolbar>.primary {
        margin-bottom: 0;
        margin-right: 30px
    }

    .login-container .block.login .actions-toolbar>.secondary {
        float: left
    }

    .form-create-account .fieldset-fullname .fields {
        display: table;
        width: 100%
    }

    .form-create-account .fieldset-fullname .fields .field {
        display: table-cell
    }

    .form-create-account .fieldset-fullname .fields .field+.field {
        padding-left: 10px
    }

    .form-create-account .fieldset-fullname .field-name-prefix,
    .form-create-account .fieldset-fullname .field-name-suffix {
        width: 50px
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account,
    .form.search.advanced,
    .form.form-orders-search {
        min-width: 600px;
        width: 50%
    }

    .account.page-layout-2columns-left .sidebar-main,
    .account.page-layout-2columns-left .sidebar-additional {
        width: 22.3%
    }

    .account.page-layout-2columns-left .column.main {
        width: 77.7%
    }

    .account.page-layout-2columns-left .sidebar-main .block {
        margin-bottom: 0
    }

    .account .column.main .block:not(.widget) {
        margin-bottom: 40px
    }

    .account .data.table {
        margin-bottom: 0
    }

    .account .data.table .col.actions {
        white-space: nowrap
    }

    .block-addresses-list .items.addresses {
        font-size: 0
    }

    .block-addresses-list .items.addresses>.item {
        display: inline-block;
        font-size: 14px;
        margin-bottom: 20px;
        vertical-align: top;
        width: 48%
    }

    .block-addresses-list .items.addresses>.item:nth-last-child(1),
    .block-addresses-list .items.addresses>.item:nth-last-child(2) {
        margin-bottom: 0
    }

    .block-addresses-list .items.addresses>.item:nth-child(even) {
        margin-left: 4%
    }

    .page-main .block {
        margin-bottom: 20px
    }

    .form-edit-account .fieldset .fieldset {
        margin-bottom: 20px;
        width: 100%
    }

    .control.captcha-image .captcha-img {
        margin: 0 10px 10px 0
    }

    .order-products-toolbar .pager,
    .customer-addresses-toolbar .pager {
        border-top: 1px solid #ccc;
        padding: 10px 0
    }

    .filter.block {
        margin-bottom: 40px
    }

    .filter-title {
        display: none
    }

    .filter-content .item {
        margin: 10px 0
    }

    .filter-actions {
        margin-bottom: 30px
    }

    .filter.active .filter-options,
    .filter-options {
        background: #f2f2f2;
        clear: both;
        display: block;
        overflow: initial;
        position: static
    }

    .filter-subtitle {
        display: block;
        position: static
    }

    .page-layout-1column .toolbar-products {
        position: absolute;
        top: 0;
        width: 100%
    }

    .page-layout-1column .products~.toolbar-products {
        position: static
    }

    .page-layout-1column.page-with-filter .column.main {
        padding-top: 45px;
        position: relative;
        z-index: 1
    }

    .page-layout-1column .filter.block {
        border-top: 1px solid #ccc
    }

    .page-layout-1column .filter-content {
        margin-top: 10px
    }

    .page-layout-1column .filter-subtitle {
        display: none
    }

    .page-layout-1column .filter-options-item {
        border: 0;
        display: inline-block;
        margin-right: 25px;
        position: relative
    }

    .page-layout-1column .filter-options-item.active {
        z-index: 2
    }

    .page-layout-1column .filter-options-item.active .filter-options-content {
        visibility: visible
    }

    .page-layout-1column .filter-options-item.active:hover {
        z-index: 3
    }

    .page-layout-1column .filter-options-item.active:after,
    .page-layout-1column .filter-options-item.active:before {
        border: 8px solid transparent;
        height: 0;
        width: 0;
        border-bottom-color: #000;
        bottom: -1px;
        content: '';
        display: block;
        left: 5px;
        position: absolute;
        z-index: 3
    }

    .page-layout-1column .filter-options-item.active:after {
        border-bottom-color: #fff;
        margin-top: 2px;
        z-index: 4
    }

    .page-layout-1column .filter-options-title {
        padding: 0 20px 0 0
    }

    .page-layout-1column .filter-options-title:after {
        right: 2px;
        top: 3px;
        z-index: 3
    }

    .page-layout-1column .filter-options-content {
        background: #fff;
        -webkit-box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);
        -moz-box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);
        -ms-box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);
        box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);
        border: 1px solid #ccc;
        padding: 5px 0;
        position: absolute;
        top: 100%;
        visibility: hidden;
        width: 180px;
        z-index: 2
    }

    .page-layout-1column .filter-options-content .item {
        margin: 0;
        padding: 5px
    }

    .page-layout-1column .filter-options-content .item a {
        margin-left: 0
    }

    .page-layout-1column .filter-options-content .item:hover {
        background-color: #e8e8e8
    }

    .page-layout-1column .filter-current {
        display: inline;
        line-height: 35px
    }

    .page-layout-1column .filter-current-subtitle {
        color: #7d7d7d;
        display: inline;
        font-size: 14px;
        font-weight: normal;
        padding: 0
    }

    .page-layout-1column .filter-current-subtitle:after {
        content: ':'
    }

    .page-layout-1column .filter-current .item,
    .page-layout-1column .filter-current .items {
        display: inline
    }

    .page-layout-1column .filter-current .item {
        margin-right: 25px;
        white-space: nowrap
    }

    .page-layout-1column .filter-current .action.remove {
        line-height: normal
    }

    .page-layout-1column .filter-actions {
        display: inline;
        white-space: nowrap
    }

    .page-layout-1column .filter-actions~.filter-options {
        margin-top: 25px
    }

    .order-links {
        margin: 0 0 1px 0
    }

    .order-links .item {
        float: left;
        margin: 0 -1px -1px 0
    }

    .order-links .item a {
        padding: 1px 20px
    }

    .order-links .item strong {
        border-bottom: 0;
        margin-bottom: -1px;
        padding: 1px 20px 2px 20px
    }

    .order-actions-toolbar .action.print {
        display: block;
        float: right
    }

    .account .column.main .block.block-order-details-view .block-content:not(.widget) .box,
    [class^='sales-guest-'] .column.main .block.block-order-details-view .block-content:not(.widget) .box,
    .sales-guest-view .column.main .block.block-order-details-view .block-content:not(.widget) .box {
        clear: none;
        float: left;
        width: 25%
    }

    .block-order-details-comments {
        margin: 0 0 60px
    }

    .block-order-details-comments .comment-date {
        clear: left;
        float: left;
        margin-right: 50px;
        max-width: 90px
    }

    .block-order-details-comments .comment-content {
        overflow: hidden
    }

    .order-details-items {
        margin-top: -1px;
        padding: 25px
    }

    .order-details-items .col.price {
        text-align: center
    }

    .order-details-items .col.subtotal {
        text-align: right
    }

    .order-details-items tbody td {
        padding-bottom: 20px;
        padding-top: 20px
    }

    .order-details-items tfoot .amount,
    .order-details-items tfoot .mark {
        text-align: right
    }

    .order-details-items.ordered .order-title {
        display: none
    }

    .order-pager-wrapper .order-pager-wrapper-top {
        padding-left: 0;
        padding-right: 0
    }

    .order-pager-wrapper.order-pager-wrapper-top {
        display: none
    }

    .order-pager-wrapper .toolbar-amount {
        position: relative
    }

    .order-pager-wrapper .pages {
        float: right
    }

    .table-order-items tbody .col.label,
    .table-order-items tbody .col.value {
        padding-left: 0
    }

    .table-order-items.invoice .col.qty,
    .table-order-items.shipment .col.qty {
        text-align: center
    }

    .table-order-items.creditmemo .col.qty,
    .table-order-items.creditmemo .col.discount,
    .table-order-items.creditmemo .col.subtotal {
        text-align: center
    }

    .table-order-items.creditmemo .col.total {
        text-align: right
    }

    html,
    body {
        height: 100%
    }

    .navigation ul {
        padding: 0 8px
    }

    .page-header {
        border: 0;
        margin-bottom: 0
    }

    .page-header .panel.wrapper {
        border-bottom: 1px solid #e8e8e8;
        background-color: #6e716e
    }

    .page-header .header.panel {
        padding-bottom: 10px;
        padding-top: 10px
    }

    .page-header .switcher {
        float: right;
        margin-left: 15px;
        margin-right: -6px;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1
    }

    .page-main>.page-title-wrapper .page-title {
        display: inline-block
    }

    .page-main>.page-title-wrapper .page-title+.action {
        float: right;
        margin-top: 20px
    }

    .customer-welcome .action.switch {
        background-image: none;
        background: none;
        -moz-box-sizing: content-box;
        border: 0;
        box-shadow: none;
        line-height: inherit;
        margin: 0;
        padding: 0;
        text-decoration: none;
        text-shadow: none;
        font-weight: 400;
        color: #fff
    }

    .customer-welcome .action.switch:focus,
    .customer-welcome .action.switch:hover {
        background: none;
        border: none
    }

    .customer-welcome .action.switch:active {
        background: none;
        border: none
    }

    .customer-welcome .action.switch.disabled,
    .customer-welcome .action.switch[disabled],
    fieldset[disabled] .customer-welcome .action.switch {
        pointer-events: none;
        opacity: .5
    }

    .customer-welcome.active .action.switch:after {
        content: '\f077'
    }

    .customer-welcome.active .customer-menu {
        display: block
    }

    .customer-welcome .greet {
        display: none
    }

    .header.panel>.header.links {
        margin: 0;
        padding: 0;
        list-style: none none;
        float: right;
        margin-left: auto
    }

    .header.panel>.header.links>li {
        display: inline-block;
        vertical-align: top
    }

    .header.panel>.header.links>li {
        margin: 0 0 0 15px
    }

    .header.panel>.header.links>li.welcome,
    .header.panel>.header.links>li>a {
        display: inline-block;
        line-height: 1.4
    }

    .header.panel>.header.links>li.welcome a {
        color: #fff;
        padding-left: 5px
    }

    .header.panel>.header.links>.authorization-link:after {
        content: attr(data-label);
        display: inline-block;
        margin: 0 -5px 0 5px
    }

    .header.panel>.header.links>.authorization-link:last-child:after {
        display: none
    }

    .header.panel>.header.links>.customer-welcome+.authorization-link {
        display: none
    }

    .header.content {
        padding: 20px 20px 20px;
        align-items: flex-start
    }

    .header.content .header-right {
        order: 3
    }

    .header.content .header-search {
        order: 2
    }

    .logo {
        width: 82px;
      }

    .logo img {
        max-height: inherit
    }

    .authorization-link>a {
        text-decoration: none;
        background-image: none;
        background: #e62645;
        border: 0;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-weight: 600;
        padding: 10px 15px;
        font-size: 1.4rem;
        line-height: 1.6rem;
        box-sizing: border-box;
        vertical-align: middle;
        border-radius: 5px
    }

    .authorization-link>a:hover,
    .authorization-link>a:active,
    .authorization-link>a:focus {
        text-decoration: none
    }

    .authorization-link>a:visited {
        color: #fff
    }

    .authorization-link>a:focus,
    .authorization-link>a:hover {
        background: #e43866;
        border: 0;
        color: #fff
    }

    .authorization-link>a:active {
        background: #e43866;
        border: 0;
        color: #fff
    }

    .authorization-link>a.disabled,
    .authorization-link>a[disabled],
    fieldset[disabled] .authorization-link>a {
        opacity: .5;
        cursor: default;
        pointer-events: none
    }

    .authorization-link>a:visited {
        color: #fff
    }

    .my-account-link {
        text-decoration: none;
        background-image: none;
        background: #e62645;
        border: 0;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-weight: 600;
        padding: 10px 15px;
        font-size: 1.4rem;
        line-height: 1.6rem;
        box-sizing: border-box;
        vertical-align: middle;
        border-radius: 5px
    }

    .my-account-link:hover,
    .my-account-link:active,
    .my-account-link:focus {
        text-decoration: none
    }

    .my-account-link:visited {
        color: #fff
    }

    .my-account-link:focus,
    .my-account-link:hover {
        background: #e43866;
        border: 0;
        color: #fff
    }

    .my-account-link:active {
        background: #e43866;
        border: 0;
        color: #fff
    }

    .my-account-link.disabled,
    .my-account-link[disabled],
    fieldset[disabled] .my-account-link {
        opacity: .5;
        cursor: default;
        pointer-events: none
    }

    .my-account-link:visited {
        color: #fff
    }

    .page-wrapper {
        margin: 0;
        position: relative;
        transition: margin .3s ease-out 0s
    }

    .page-wrapper>.breadcrumbs,
    .page-wrapper>.top-container,
    .page-wrapper>.widget {
        box-sizing: border-box;
        width: 100%
    }

    .page-footer .switcher .options ul.dropdown {
        bottom: -10px;
        left: 100%;
        margin: 0 0 0 20px;
        top: auto
    }

    .page-footer .switcher .options ul.dropdown:before,
    .page-footer .switcher .options ul.dropdown:after {
        bottom: 13px;
        left: auto;
        right: 100%;
        top: auto
    }

    .page-footer .switcher .options ul.dropdown:before {
        border-color: transparent #fff transparent transparent
    }

    .page-footer .switcher .options ul.dropdown:after {
        border-color: transparent #bbb transparent transparent;
        margin: 0 0 -1px -1px
    }

    .footer-row {
        border-top: 1px solid #ccc;
        justify-content: space-between;
        align-items: center
    }

    .footer-column {
        padding: 0 10px
    }

    .footer-top {
        padding-left: env(safe-area-inset-left)
    }

    .footer-top-content {
        position: relative;
        margin-left: 40px;
        padding-left: 0
    }

    .footer-top-content:before,
    .footer-top-content:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid
    }

    .footer-top-content:before {
        border-width: 0 0 41px 41px;
        border-color: transparent transparent #ccc transparent;
        left: -41px;
        top: -1px
    }

    .footer-top-content:after {
        border-width: 0 0 40px 40px;
        border-color: transparent transparent #f2f2f2 transparent;
        left: -40px
    }

    .footer-top-content>.footer-column:first-child>.footer-switcher>li:first-child>span,
    .footer-top-content>.footer-column:first-child>.footer-switcher>li:first-child>a {
        padding-left: 0
    }

    .footer-logo {
        padding: 0 20px
    }

    .footer-links>li:not(:last-child) a:after,
    .footer-links>li:not(:last-child) span:after {
        content: "|";
        position: absolute;
        right: 0;
        width: 2px;
        top: 5px;
        font-weight: normal
    }

    .cms-no-route .page-main {
        text-align: center
    }

    .cms-no-route .page-title-wrapper:before {
        content: "";
        width: 150px;
        height: 150px;
        background-image: url('../images/warning.svg');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        margin: 0 auto 20px
    }

    .cms-no-route .primary.action {
        margin-top: 20px
    }

    .block.widget .products-grid .product-item {
        width: 33.33333333%
    }

    .sidebar .block.widget .products-grid .product-item {
        margin-left: 0;
        width: 100%
    }

    .sidebar .block.widget .products-grid .product-item .actions-secondary {
        display: block;
        padding: 10px 0
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        margin-left: 2%;
        width: calc((100% - 6%)/4)
    }

    .page-layout-1column .block.widget .products-grid .product-item:nth-child(3n + 1) {
        margin-left: 2%
    }

    .page-layout-1column .block.widget .products-grid .product-item:nth-child(4n + 1) {
        margin-left: 0
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 50%
    }

    .sidebar .block.widget .pager .pages-item-next {
        padding: 0
    }

    .sidebar .block.widget .pager .pages-item-next .action {
        margin: 0
    }

    body.no-scroll-desktop {
        overflow: hidden;
        -webkit-overflow-scrolling: touch
    }

    .cms-index-index .page.messages,
    .sketcher-index-index .page.messages {
        padding-left: 20px;
        padding-right: 20px
    }

    .homepage-logo {
        width: 200px
    }

    .homepage-search .product-search {
        width: 600px
    }

    .homepage-search .product-search-input-wrapper {
        margin: 30px 0 20px
    }

    .homepage-search .search-tooltip {
        left: 12px
    }

    .homepage-search .search-tooltip>a {
        display: inline-block;
        text-decoration: none
    }

    .homepage-search .search-tooltip>a>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .homepage-search .search-tooltip>a:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
        line-height: 35px;
        color: #fff;
        content: '\f279';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .homepage-search .search-tooltip>a:hover:before {
        color: #333
    }

    .homepage-search .search-tooltip>a:active:before {
        color: #333
    }

    .homepage-search input {
        height: 48px;
        padding: 0 44px;
        border-radius: 24px
    }

    .homepage-search .search-autocomplete,
    .homepage-search .search-autocomplete ul:not(:empty) {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px
    }

    .homepage-search .action.search {
        display: inline-block;
        text-decoration: none;
        right: 15px
    }

    .homepage-search .action.search>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .homepage-search .action.search:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        line-height: 48px;
        color: #e62645;
        content: '\f002';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .homepage-search .action.search:hover:before {
        color: #e43866;
    }

    .homepage-search .action.search:active:before {
        color: #e43866;
    }

    .homepage-search-buttons .home-search-button,
    .homepage-search-buttons .home-search-button-primary {
        padding: 10px 15px
    }

    .sketcher-search-container {
        height: 480px
    }

    .sketcher-buttons {
        padding: 20px 0
    }

    .sketcher-buttons .sketcher-button {
        position: relative
    }

    .sketcher-buttons .sketcher-button .tooltip-content {
        background: #fff;
        border-radius: 5px;
        max-width: 360px;
        min-width: 210px;
        padding: 12px 16px;
        z-index: 100;
        display: none;
        position: absolute;
        text-align: left;
        color: #333;
        line-height: 1.4;
        border: 1px solid #bbb;
        margin-bottom: 5px;
        bottom: 100%;
        left: 0
    }

    .sketcher-buttons .sketcher-button .tooltip-content:after,
    .sketcher-buttons .sketcher-button .tooltip-content:before {
        border: solid transparent;
        content: '';
        height: 0;
        position: absolute;
        width: 0
    }

    .sketcher-buttons .sketcher-button .tooltip-content:after {
        border-width: 5px;
        border-color: transparent
    }

    .sketcher-buttons .sketcher-button .tooltip-content:before {
        border-width: 6px;
        border-color: transparent
    }

    .sketcher-buttons .sketcher-button .tooltip-content:after,
    .sketcher-buttons .sketcher-button .tooltip-content:before {
        top: 100%
    }

    .sketcher-buttons .sketcher-button .tooltip-content:after {
        border-top-color: #fff;
        left: 15px;
        margin-left: -5px
    }

    .sketcher-buttons .sketcher-button .tooltip-content:before {
        border-top-color: #bbb;
        left: 15px;
        margin-left: -6px
    }

    .sketcher-buttons .sketcher-button .tooltip-toggle {
        cursor: help
    }

    .sketcher-buttons .sketcher-button .tooltip-toggle:hover+.tooltip-content,
    .sketcher-buttons .sketcher-button .tooltip-toggle:focus+.tooltip-content,
    .sketcher-buttons .sketcher-button:hover .tooltip-content {
        display: block
    }

    .sketcher-buttons .sketcher-button .tooltip-content {
        min-width: auto;
        left: calc(50% - 210px / 2);
        width: 210px
    }

    .sketcher-buttons .sketcher-button .tooltip-content:before,
    .sketcher-buttons .sketcher-button .tooltip-content:after {
        left: 50%
    }

    .conceptSearch-wrapper {
        border-radius: 5px;
        padding: 10px;
        max-width: 1280px;
        margin: 0 auto
    }

    .conceptSearch-wrapper:not(.conceptSearch-wrapper--loading) {
        border: 1px solid #ccc
    }

    .conceptSearch-table thead th {
        padding: 0 10px 10px;
        font-size: 1.6rem
    }

    .conceptSearch-table th:first-child,
    .conceptSearch-table td:first-child {
        padding-left: 0
    }

    .conceptSearch-table th:last-child,
    .conceptSearch-table td:last-child {
        padding-right: 0
    }

    .conceptSearch-table tbody td {
        vertical-align: top;
        padding: 10px;
        font-size: 1.5rem
    }

    .conceptSearch-table tbody td:first-child {
        font-size: 1.8rem
    }

    .conceptSearch-paging-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px
    }

    .dataTables_length {
        text-align: center
    }

    .dataTables_length label {
        padding-right: 0.36em
    }

    .dataTables_paginate {
        text-align: center;
        margin-right: -10px
    }

    .conceptSearch-buttons {
        padding: 10px 0
    }

    .conceptSearch-selected:not(:empty) {
        border-top: 1px solid #ccc;
        margin-top: 10px;
        margin-bottom: -10px
    }

    .conceptSearch-selected ul {
        padding-bottom: 5px;
        margin: 0 -5px
    }

    .conceptSearch-selected ul li {
        padding: 5px 10px 5px 5px
    }

    .conceptSearch-results-mini {
        margin: 0;
        padding: 0 9px;
        position: static;
        height: 40px;
        font-size: 16px;
        border-radius: 20px
    }

    .conceptSearch-results-mini i {
        line-height: 38px;
        font-size: 20px;
        margin-right: 6px
    }

    .conceptSearch-sidebar {
        margin-right: 20px;
        flex-basis: 250px;
        min-width: 250px
    }

    .conceptSearch-heads .conceptSearch-head>span {
        min-width: 250px
    }

    .conceptSearch-heads .conceptSearch-head-name {
        min-width: 75px
    }

    .conceptSearch-heads .conceptSearch-head img {
        width: 50px;
        height: 50px
    }

    .conceptSearch-heads .conceptSearch-head-count {
        min-width: 95px
    }

    .conceptSearch-heads .conceptSearch-head-image-popout {
        display: block
    }

    .conceptSearch-results-selected {
        position: sticky;
        top: 20px
    }

    .conceptSearch-results-selected-toggle {
        display: none
    }

    .conceptSearch-results-selected ul {
        max-height: 400px
    }

    .conceptSearch-results-selected-stats {
        margin-top: 3rem
    }

    .conceptSearch-main {
        max-width: calc(100% - 270px)
    }

    .conceptSearch-grid {
        margin-left: -10px;
        margin-right: -10px
    }

    .conceptSearch-grid-molecule {
        flex-basis: 33.33%;
        max-width: 300px;
        padding: 0 10px 20px
    }

    .conceptSearch-grid-molecule:hover .conceptSearch-grid-molecule-image {
        border-radius: 5px 0 0 5px
    }

    .conceptSearch-grid-molecule:hover .conceptSearch-grid-molecule-info-container {
        display: block
    }

    .conceptSearch-grid-molecule-name {
        font-size: 1.8rem;
        margin-bottom: 5px
    }

    .conceptSearch-list-wrapper {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        margin: 0 auto
    }

    .conceptSearch-molecule-modal-image {
        width: 40%
    }

    .conceptSearch-molecule-modal-image img {
        max-height: 200px
    }

    .conceptSearch-molecule-modal-options {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between
    }

    .conceptSearch-compare {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right)
    }

    .conceptSearch-compare-header {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 10px
    }

    .conceptSearch-compare-table th,
    .conceptSearch-compare-table-radar {
        position: sticky;
        left: 0
    }

    .conceptSearch-compare-molecule-remove {
        display: none
    }

    .footer-row {
        padding-right: 70px
    }

    .number-input {
        display: inline-flex;
        justify-content: flex-end
    }

    .number-input-prepend,
    .number-input-append {
        background-color: transparent;
        cursor: pointer;
        overflow: visible;
        text-transform: none;
        padding: .5rem .75rem
    }

    .number-input-prepend {
        background-color: #e0e1e2;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        color: #333
    }

    .number-input-prepend:hover,
    .number-input-prepend:focus {
        background-color: #cbcdce
    }

    .number-input-prepend:active {
        background-color: #bec0c2
    }

    .number-input-prepend:focus,
    .number-input-prepend:active {
        box-shadow: 0 0 0 .125rem #f2f2f2
    }

    .number-input-append {
        background-color: #e62645;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        color: #fff
    }

    .number-input-append:hover,
    .number-input-append:focus {
        background-color: #e43866;
    }

    .number-input-append:active {
        background-color: #e43866;
    }

    .number-input-append:focus,
    .number-input-append:active {
        box-shadow: 0 0 0 .125rem #eb6919
    }

    .number-input-field[type=number] {
        -moz-appearance: textfield;
        background-color: transparent;
        border-color: #e0e1e2;
        border-style: solid;
        border-width: 1px 0;
        padding: .5rem .75rem;
        text-align: center;
        width: 25%;
        min-width: 4rem;
        border-radius: 0
    }

    .number-input-field[type=number]::-webkit-inner-spin-button,
    .number-input-field[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0
    }
}

@media all and (min-width:1024px),
print {
    .ambrands-search-wrapper {
        float: right;
        width: 20%
    }

    .brand-images .brands-letter {
        padding-right: 35px;
        width: inherit
    }

    .brand-images .brands-letter .brand-item {
        margin: 0 10px 20px
    }

    .amslider .slide {
        margin: 0 20px 20px 0;
        max-width: 32%;
        -webkit-flex-basis: auto;
        flex-basis: auto
    }

    .ambrands-list-popup {
        position: absolute;
        width: 600px;
        height: 450px;
        background: #fff;
        box-shadow: 0 0 1px 1px #ccc;
        opacity: 0;
        transition: all .5s ease;
        pointer-events: none
    }

    .ambrands-menu-item:hover .ambrands-list-popup,
    .ammenu-item:hover .ambrands-list-popup {
        display: block;
        opacity: 1;
        pointer-events: auto
    }

    .ambrands-list-container {
        height: 100%;
        font-size: 0
    }

    .ambrands-popup-filter {
        display: inline-block;
        box-sizing: border-box;
        width: 27%;
        height: 100%;
        padding: 20px;
        font-size: 0;
        vertical-align: top;
        text-align: center;
        background: #f0f0f0
    }

    .ambrands-popup-filter .ambrands-letter {
        display: inline-block;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        font-size: 14px;
        line-height: 40px;
        text-align: center;
        color: #333
    }

    .ambrands-popup-filter .ambrands-letter:hover {
        color: #333;
        text-decoration: none;
        background: #ccc
    }

    .ambrands-popup-filter .ambrands-letter.-disabled {
        background-color: #f1f1f1;
        opacity: .6;
        pointer-events: none
    }

    .ambrands-popup-filter .ambrands-letter.-active {
        color: #fff;
        background: #f58d0f
    }

    .ambrands-popup-filter .ambrands-letter.-letter-all {
        width: 100%;
        margin-bottom: 15px
    }

    .ambrands-popup-items {
        display: inline-block;
        box-sizing: border-box;
        width: 73%;
        height: 100%;
        padding: 20px;
        overflow: auto;
        font-size: 14px
    }

    .ambrands-popup-items .brand-images .brands-letter {
        width: 100%;
        padding: 0
    }

    .ambrands-popup-items .brand-item {
        text-align: center
    }

    .ambrands-popup-items .brand-item .label:before {
        content: none
    }

    .ambrands-popup-items .brand-item .count {
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%)
    }

    .ambrands-popup-items .brand-images .brands-letter .brand-item {
        margin: 0 20px 20px 0
    }

    .ambrands-popup-items .letter {
        margin-bottom: 15px
    }

    .filter-options-content .am-filter-price {
        width: 43%
    }

    .amslider-container.am-swiper-slider {
        width: 85%
    }

    .checkout-cart-index .page-main {
        max-width: 1280px
    }

    .cart .cart-item {
        justify-content: space-between
    }

    .cart .parent-item-details {
        order: 1
    }

    .cart .cart-item-buttons {
        order: 2;
        min-width: auto;
        justify-content: flex-end;
        margin-top: 10px;
        align-items: flex-start
    }

    .cart .table-columns {
        order: 3
    }

    .cart-summary {
        padding: 115px 40px 40px
    }

    .cart-summary .action.primary.checkout {
        padding: 20px
    }

    .minicart-wrapper .action.showcart .counter.qty {
        display: inline
    }

    .checkout-index-index .modal-popup .modal-inner-wrap {
        margin-left: -400px;
        width: 800px;
        left: 50%
    }

    .opc-wrapper .shipping-address-item {
        width: calc(33.333% - 10px * 2)
    }

    .wishlist-index-index .products-grid .product-items {
        margin: 0
    }

    .wishlist-index-index .products-grid .product-item {
        margin-bottom: 20px;
        margin-left: calc((100% - 4 * 24.439%) / 3);
        padding: 0;
        width: 24.439%
    }

    .wishlist-index-index .products-grid .product-item:nth-child(4n + 1) {
        margin-left: 0
    }

    .product-item-actions .actions-primary {
        display: flex;
        flex-wrap: wrap;
        justify-content: center
    }

    .product-item-actions .action {
        max-width: 130px
    }

    .product-item-actions .action,
    .product-item-actions .action.view {
        margin: 0 5px
    }

    .product-item .product-price-list-availability {
        display: block
    }

    .page-layout-1column .product-info-main {
        width: 40%
    }

    .page-layout-1column .product.media {
        width: 57%
    }

    .box-tocart .paypal:first-of-type {
        margin-top: 13px
    }

    .block-search {
        width: 560px
    }

    .search-links {
        padding-left: 20px
    }

    .login-container {
        width: 90%
    }

    .login-container-left,
    .login-container-right {
        width: 100%
    }

    .order-links .item a {
        padding: 1px 35px
    }

    .order-links .item strong {
        padding: 1px 35px 2px 35px
    }

    .message.global.cookie .actions .action {
        padding: 10px 20px
    }

    .block.widget .products-grid .product-item {
        width: 20%
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        margin-left: 2%;
        width: calc((100% - 8%)/5)
    }

    .page-layout-1column .block.widget .products-grid .product-item:nth-child(4n + 1) {
        margin-left: 2%
    }

    .page-layout-1column .block.widget .products-grid .product-item:nth-child(5n + 1) {
        margin-left: 0
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 25%
    }

    .block.widget .products-grid .product-items {
        margin: 0
    }

    .block.widget .products-grid .product-item {
        margin-left: calc((100% - 4 * 24.439%) / 3);
        padding: 0;
        width: 24.439%
    }

    .block.widget .products-grid .product-item:nth-child(4n + 1) {
        margin-left: 0
    }

    .homepage-logo {
        width: 260px
    }

    .homepage-search .product-search {
        width: 700px
    }

    .homepage-search .search-tooltip {
        left: 18px
    }

    .homepage-search input {
        height: 60px;
        padding: 0 22px;
        border-radius: 30px
    }

    .homepage-search .search-autocomplete,
    .homepage-search .search-autocomplete ul:not(:empty) {
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px
    }

    .homepage-search .action.search {
        right: 20px
    }

    .homepage-search .action.search:before {
        line-height: 60px
    }

    .homepage-search-buttons .home-search-button,
    .homepage-search-buttons .home-search-button-primary {
        padding: 10px 20px
    }

    .sketcher-search-container {
        height: 500px
    }

    .conceptSearch-grid {
        margin-left: -10px;
        margin-right: -10px
    }

    .conceptSearch-grid-molecule {
        flex-basis: 25%;
        padding: 0 10px 20px
    }
}

@media all and (min-width:1440px),
print {
    .brands-filters .letter {
        margin: 0;
        border-radius: 0;
        border-right: none
    }

    .brands-filters .letter-all {
        margin-right: 20px
    }

    .brands-filters .letter:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px
    }

    .brands-filters .letter:last-child {
        border-right: 1px solid #ccc;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px
    }

    .amslider .wrapper {
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center
    }

    .product-item {
        width: 25%
    }

    .product-item:nth-child(4n+0):hover .product-image-container,
    .product-item:nth-child(4n+0):focus .product-image-container,
    .product-item:nth-child(4n+0):focus-within .product-image-container {
        border-radius: 0 5px 5px 0
    }

    .product-item:nth-child(4n+0) .product-price-list-container {
        left: calc(-1 * (97% - 20px - 2px));
        border-radius: 5px 0 0 5px;
        right: auto
    }

    .sidebar .product-items .product-item-info .product-item-photo {
        float: none;
        left: 0;
        margin: 0;
        position: absolute;
        top: 0
    }

    .sidebar .product-items .product-item-details {
        margin-left: 85px
    }

    .block-search {
        width: 600px
    }

    .message.global.cookie .actions .action {
        padding: 10px 20px
    }

    .conceptSearch-wrapper {
        padding: 20px
    }

    .conceptSearch-table tbody td {
        padding: 10px;
        font-size: 1.6rem
    }

    .conceptSearch-table tbody tr:last-child td {
        padding-bottom: 20px
    }

    .conceptSearch-paging-container {
        padding-top: 20px
    }

    .conceptSearch-buttons {
        padding: 20px 0
    }

    .conceptSearch-selected:not(:empty) {
        padding-top: 10px;
        margin-top: 20px;
        margin-bottom: -10px
    }

    .conceptSearch-selected ul li {
        padding: 10px 15px 10px 10px
    }

    .conceptSearch-sidebar {
        margin-right: 20px;
        flex-basis: 275px;
        min-width: 275px
    }

    .conceptSearch-main {
        max-width: calc(100% - 295px)
    }

    .conceptSearch-grid {
        margin-left: -10px;
        margin-right: -10px
    }

    .conceptSearch-grid-molecule {
        flex-basis: 20%;
        padding: 0 10px 20px
    }

    .conceptSearch-grid-molecule-info-availability {
        display: initial
    }

    .conceptSearch-list-wrapper {
        padding: 20px
    }

    .conceptSearch-grid-molecule:nth-child(5n+0):hover .conceptSearch-grid-molecule-image,
    .conceptSearch-grid-molecule:nth-child(5n+0):focus .conceptSearch-grid-molecule-image,
    .conceptSearch-grid-molecule:nth-child(5n+0):focus-within .conceptSearch-grid-molecule-image {
        border-radius: 0 5px 5px 0
    }

    .conceptSearch-grid-molecule:nth-child(5n+0) .conceptSearch-grid-molecule-info-container {
        left: calc(-1 * (97% - 10px - 2px));
        border-radius: 5px 0 0 5px;
        right: auto
    }
}

@media (prefers-reduced-motion:reduce) {
    * {
        animation: none !important;
        transition: none !important
    }
}

@media only screen and (min-width:768px) and (max-width:calc(1024px - 1px)) {

    .conceptSearch-grid-molecule:nth-child(3n+0):hover .conceptSearch-grid-molecule-image,
    .conceptSearch-grid-molecule:nth-child(3n+0):focus .conceptSearch-grid-molecule-image,
    .conceptSearch-grid-molecule:nth-child(3n+0):focus-within .conceptSearch-grid-molecule-image {
        border-radius: 0 5px 5px 0
    }

    .conceptSearch-grid-molecule:nth-child(3n+0) .conceptSearch-grid-molecule-info-container {
        left: calc(-1 * (97% - 10px - 2px));
        border-radius: 5px 0 0 5px;
        right: auto
    }
}

@media only screen and (min-width:1024px) and (max-width:calc(1440px - 1px)) {

    .conceptSearch-grid-molecule:nth-child(4n+0):hover .conceptSearch-grid-molecule-image,
    .conceptSearch-grid-molecule:nth-child(4n+0):focus .conceptSearch-grid-molecule-image,
    .conceptSearch-grid-molecule:nth-child(4n+0):focus-within .conceptSearch-grid-molecule-image {
        border-radius: 0 5px 5px 0
    }

    .conceptSearch-grid-molecule:nth-child(4n+0) .conceptSearch-grid-molecule-info-container {
        left: calc(-1 * (97% - 10px - 2px));
        border-radius: 5px 0 0 5px;
        right: auto
    }
}

.am-slider:not(.-loaded) {
    visibility: visible !important;
    opacity: 1 !important
}
/**Filter CSS**/
.faq { 
    background-color: #fff;
    border-radius: 25px;
    padding: 50px 25px;
    margin: 150px 5% 5% 5%;
    min-width: 320px;
    max-width: 320px;
}

.faq__heading { 
    color: var(--blue-dark);
    font-size: 3.2rem;
    font-weight: 700;
    text-align: center; 
}

.faq__detail {
    border-bottom: 1px solid hsl(240, 5%, 91%);    
    padding: 20px;
}

.faq__summary:hover,
.faq__summary:active { 
    color: var(--orange);
    cursor: pointer; 
}

.faq__summary {
    list-style: none;
  }

.faq__summary::-webkit-details-marker {
    display: none;
  }

.faq__summary { 
    display: block;
    padding: 0;
    position: relative;
    text-align: right;
    font-weight: bold;
}

    
.faq__summary:after {
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    transition: ease 0.2s;
    color: #e62645;
    content: '\f067';
  }
  
details[open] .faq__summary:after {
    display: inline-block;
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    transform: rotate(45deg);
    transition: ease 0.2s;    
}  
details[open] .faq__summary {
    margin-bottom:14px;
}
.concept_group_item .custom-checkbox > * {
    display: inline-block;
}
.faq__question {
    left: 0;
    position: absolute;
    text-align: left;
    top: 0;
    width: 90%;
}

.faq__question {
    display: inline-block;
    text-align: left;
    width: 90%;
}

.faq__summary {
    color: var(--blue-dark);
    font-size: 1.4rem; 
    line-height:14px;
}

.faq__summary:focus {
    color: var(--orange); 
    outline: none;
}

.faq__text {
    color: var(--blue-mid);
    font-size: var(--base-font-size);
    line-height: 1.6;
    margin: 10px 0 0 0;
}

.hidden-lg { display: block; }
    
.visible-lg { display: none; }
.concept_group_item .custom-checkbox .checkbox {
    margin-right: 10px;
    margin-bottom: -2px;
    display:inline-block;
}
.concept_group_item .custom-checkbox:hover p {
    text-decoration:underline;
}
.concept_group_item .custom-checkbox:hover {
    cursor:pointer;
}

@media (min-width: 900px) {
    .faq {
        align-items: center;
        display: flex;
        justify-content: space-between;
        min-width: 900px;
        padding: 0;
        margin: 0;
    }

    .faq__logo__holder { 
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
    }

    .faq__holder {
        border: 1px solid #cecece;
        border-radius:5px;
    }

  

    .faq__heading {
        text-align: left;
    }

    .hidden-lg { display: none; }

    .visible-lg { 
        display: block; 
        position: absolute;
        margin-top: 120px;
        left: -80px;    
    }

    .faq__text {
        margin: 0 0 10px 0;
    }

    .faq__detail {
        margin: 10px 0 0 0 0;
        padding: 0;
        padding: 14px;
    }
}
#custom-quote-form {
    width: 100%;
    padding-right: 0px;
  }
  #checkout-doug input {
    margin-bottom: 10px;
  }
  #checkout-doug input:last-of-type {
    margin-bottom: 26px;
  }
  #checkout-doug label {
    font-size: 13px;
  }

  .checkout-cart-index .page-main > .page-title-wrapper .page-title {
    display: flex;
    justify-content: space-between;
  }

/*slider CSS*/
.range-inputs > input {
    max-width: 40px;
    text-align:center;
  }
  .range-inputs {
    display: flex;
    justify-content: space-between;
  }
  .filter-options-content {
    margin-bottom: -20px;
    padding-top: 1px;
  }
  #gsearch {
    margin-bottom: 7px;
  }
  .header.links > li:last-of-type {
    margin-left: 10px;
  }
  .header.links {
    display: flex;
  }
  .section.newupdatedcontent h1{
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    color: #1e252b;
    }
    .section.newupdatedcontent {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
    .nucsubheader{
        margin-top:-10px;
    }
    .section.newupdatedcontent h1{
        font-weight: 700;
        color: #1e252b;
        }

    .section.newupdatedcontent ol > h2 {
        margin-left: -40px;
    }
    body.fluo .route-home #maincontent {
        background: #1d252d;
    }
    body.fluo .route-home input#product_search_input {
        border: 3px #ed215d solid;
        border-radius: 10px;
    }
    body.fluo .route-home .homepage-search-buttons > a {
        background: #f05423;
        color: #fff;
    }
    body.fluo .route-home .homepage-search-buttons > a:hover {
        background: #ed215d;
    }
    body.fluo .route-home footer.page-footer, body.fluo .route-home .footer-row, body.fluo .route-home .footer-top-content::after, body.fluo .route-home .footer-top-content::before {
        background: #1d252d!important;
        margin-top: 0;
        color: #fff!important;
        border: none!important;
    }
    body.fluo .route-home  ul.footer-switcher.footer-links > li > span {
        display: none;
    }
    body.fluo .route-home .footer-links>li a, body.fluo .route-home .footer-links>li span, body.fluo .route-home .footer-logo > p {
        color: #fff!important;
    }
    body.fluo .route-home img.fluorochem-logo {
        filter: brightness(0) invert(1);
    }
    select.location-selector {
        cursor: pointer;
    }
    .fluo-back-btn {
        display:none!important;
    }
    body.fluo .route-home .fluo-back-btn {
        display:block!important;
    }
    body.fluo .route-home .fluo-back-btn {
        display: block !important;
        background: #e62645;
        color: #fff;
    }
    body.fluo .route-home .fluo-back-btn:hover {
    background: #e43866;
    }
